import { format, formatISO, parseISO } from "date-fns";

const formatYearMonth = (isoDate: string | Date): string => {
  if (typeof isoDate === "string") return format(parseISO(isoDate), "MM/yyyy");
  return format(isoDate, "MM/yyyy");
};

const formatToDateOnly = (isoDate: string): string => {
  return format(parseISO(isoDate), "yyyy-MM-dd");
};

const formatToTimeOnly = (isoDate: string): string => {
  return format(parseISO(isoDate), "HH:mm");
};

const formatToDateTime = (isoDate: string): string => {
  return format(parseISO(isoDate), "yyyy-MM-dd HH:mm");
};

const formatToIsoDate = (selectedDate: Date): string => {
  return formatISO(selectedDate, { representation: "date" });
};

export {
  formatToDateOnly,
  formatToDateTime,
  formatToIsoDate,
  formatYearMonth,
  formatToTimeOnly,
};
