import React from "react";
import styled from "styled-components";
import { TableCell } from "@mui/material";

interface TableCellStringProps {
  value: string;
  extra?: JSX.Element;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const TableCellString: (props: TableCellStringProps) => JSX.Element = ({
  value,
  extra,
}) => (
  <TableCell>
    <Container>
      {value}
      {extra}
    </Container>
  </TableCell>
);

export default TableCellString;
