import React from "react";
import styled from "styled-components";
import format from "date-fns/format";
import { TableCell } from "@mui/material";

import { LastUseValue } from "./datatable-types";

interface TableCellProps {
  value: LastUseValue;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const DataList = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TableCellLastUse: (props: TableCellProps) => JSX.Element = ({
  value,
}) => {
  const lastUsedOn = !Number.isNaN(value.date.getTime())
    ? format(value.date, "y-M-d hh:mm:ss")
    : "";

  return (
    <TableCell>
      <Container>
        <DataList>
          <div>{lastUsedOn}</div>
        </DataList>
      </Container>
    </TableCell>
  );
};

export default TableCellLastUse;
