import React from "react";
import styled from "styled-components";

const Wrap = styled.div`
  margin-top: auto;
  margin-bottom: auto;
`;

const ErrorText = styled.h1`
  text-align: center;
  padding: 0 30px;
`;

const ErrorSmallText = styled.h3`
  text-align: center;
  padding: 0 30px;
`;

const ErrorPage403: () => JSX.Element = () => {
  return (
    <Wrap>
      <ErrorText>Action forbidden!</ErrorText>
      <ErrorSmallText>
        Seems that current user does not have required permissions to do that.
      </ErrorSmallText>
    </Wrap>
  );
};

export default ErrorPage403;
