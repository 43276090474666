import React, { useState } from "react";
import styled from "styled-components";
import {
  AssessmentSubmissionParameter,
  MeasurementAssessment,
  Note,
  StoredMeasurementHistoryItem,
} from "lib-core";
import Button from "@mui/material/Button";

import { useMutation } from "@tanstack/react-query";
import { NoteParameters } from "./history-types";
import DetailsViewNotes from "./DetailsViewNotes";
import DetailsViewMeasurement from "./DetailsViewMeasurement";
import DetailsViewSpectra from "./DetailsViewSpectra";
import DownloadPdfButton from "./DownloadPdfButton";
import { useSpectraImage } from "./history-hook";
import DetailsViewNormalNote from "./DetailsViewNormalNote";
import DetailsViewAssessmentNote from "./DetailsViewAssessmentNote";

const Container = styled.div`
  display: grid;
  grid-template:
    "measurement spectra notes" 1fr
    "footer footer footer" min-content / 3fr 4fr 3fr;
`;

const Footer = styled.div`
  grid-area: footer;
  display: flex;
  justify-content: space-between;
  padding: 24px 30px;
  border-top: ${({ theme }) => `1px solid ${theme.palette.grey[200]}`};
`;

const ButtonWrapper = styled.div`
  display: flex;
  gap: 1rem;
`;

interface HistoryDetailsContentProps {
  closeModal: () => void;
  saveSubmissionParam: (param: AssessmentSubmissionParameter) => Promise<void>;
  content: StoredMeasurementHistoryItem;
  storeNote: (params: NoteParameters) => Promise<void>;
  isFetching: boolean;
  refreshContentKey: number;
}

const HistoryDetailsContent: (
  props: HistoryDetailsContentProps
) => JSX.Element = ({
  closeModal,
  content,
  saveSubmissionParam,
  storeNote,
  isFetching,
  refreshContentKey,
}) => {
  const [selectedAssessment, setSelectedAssessment] = useState(
    content.metadata.assessment ?? MeasurementAssessment.PENDING
  );

  const { mutate: submit, isLoading: isSubmittingAssessment } = useMutation({
    mutationFn: (body: AssessmentSubmissionParameter) =>
      saveSubmissionParam(body),
    mutationKey: ["saveSubmission"],
  });

  const [assessmentNote, setAssessmentNote] = useState("");

  const spectraData = useSpectraImage(content.id);

  const isSubmitButtonDisabled = !assessmentNote;

  const saveAssessment = () => {
    const body = {
      note: {
        message: assessmentNote,
      },
      metadata: {
        assessment: selectedAssessment,
      },
      ver: content.ver,
    };
    submit(body);
  };

  const saveNote = async (message: string) => {
    const params = {
      measurementId: content.id,
      note: message,
      userId: content.measurerUsername,
    };
    return storeNote(params);
  };

  // If the measurement is already assessed, it cannot be changed.
  const canAssessmentBeChanged =
    !content.metadata.assessment ||
    content.metadata.assessment === MeasurementAssessment.PENDING;

  const hasAssessmentChanged =
    selectedAssessment !== MeasurementAssessment.PENDING;

  const shouldDisplayAssessmentNote =
    canAssessmentBeChanged && hasAssessmentChanged;

  return (
    <Container>
      <DetailsViewMeasurement
        content={content}
        isFetching={isFetching}
        selectedAssessment={selectedAssessment}
        setSelectedAssessment={setSelectedAssessment}
      />
      <DetailsViewSpectra content={content} spectraData={spectraData} />
      <DetailsViewNotes
        notes={content.notes as Note[]}
        renderNoteInput={(header) => {
          if (shouldDisplayAssessmentNote) {
            return (
              <DetailsViewAssessmentNote
                assessment={selectedAssessment}
                header={header}
                updateNote={setAssessmentNote}
              />
            );
          }
          return (
            <DetailsViewNormalNote
              key={refreshContentKey}
              header={header}
              saveNote={saveNote}
            />
          );
        }}
        assessmentNoteId={content.metadata.assessmentNoteId}
        assessment={content.metadata.assessment}
      />
      <Footer>
        <DownloadPdfButton
          disabled={isFetching}
          content={content}
          spectra={spectraData.spectra}
        />
        <ButtonWrapper>
          <Button onClick={closeModal} variant="outlined">
            Cancel
          </Button>
          {canAssessmentBeChanged && hasAssessmentChanged && (
            <Button
              disabled={
                isSubmitButtonDisabled || isSubmittingAssessment || isFetching
              }
              onClick={saveAssessment}
              variant="contained"
            >
              {isSubmittingAssessment ? "Saving..." : "Save Assessment"}
            </Button>
          )}
        </ButtonWrapper>
      </Footer>
    </Container>
  );
};

export default HistoryDetailsContent;
