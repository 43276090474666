import axios from "../../AxiosInstance";
import { HttpConfig } from "../../types/types-api";
import { UserInfo } from "../../types/types-user";
import Constants from "../Constants";
import CurrentUserService from "../CurrentUserService";
import LogService from "../LogService";
import Urls from "../Urls";

type AuthorizationResult = {
  accessToken: string;
  refreshToken: string;
  userRoles: string[];
};

type AuthorizationPayload = {
  authorizationCode: string;
  clientId: string;
  redirectUri: string;
};

type AxiosAuthorizationResult = {
  data: AuthorizationResult;
};

type IdentityResult = {
  errorMessage: string | undefined;
  user: UserInfo | undefined;
};

type TokenRefreshResult = {
  accessToken: string;
  userRoles: string[];
};

/**
 * @param payload Required data
 * @throws Request failed with status code 401 if authorization code is invalid
 * @returns {Promise<boolean> | void} True if no errors occur
 */
const authorize = async (
  payload: AuthorizationPayload
): Promise<AuthorizationResult> => {
  const httpConfig: HttpConfig = {
    method: "post",
    url: Urls.tokenExchangeAuthCode(),
    data: payload,
  };

  const httpResult = await axios(httpConfig);

  const authorisationResult = httpResult as AxiosAuthorizationResult;
  const { accessToken, refreshToken, userRoles } = authorisationResult.data;

  return {
    accessToken,
    refreshToken,
    userRoles,
  };
};

/**
 * @throws Request failed with status code 401 if access token is invalid
 * @returns {Promise<UserInfo>} User's identity
 */
const identify = async (): Promise<UserInfo> => {
  const httpConfig: HttpConfig = {
    method: "get",
    url: Urls.tokenWhoami(),
  };

  const httpResult = await axios(httpConfig);

  if (!httpResult) {
    throw new Error("Cannot access access-token/user");
  }

  return httpResult.data as UserInfo;
};

const isLoggedIn = async (): Promise<IdentityResult> => {
  const userIdentity = await identify();
  LogService.log({ userIdentity });

  if (userIdentity) {
    CurrentUserService.setCurrentUser(userIdentity);
    return { errorMessage: undefined, user: userIdentity };
  }

  return userIdentity;
};

/**
 * @throws Request failed with status code 401 if refresh token is invalid
 * @returns {Promise<TokenRefreshResult>} Access token and user roles
 */
const acquireAccessToken = async (
  clientId: string,
  refreshToken: string
): Promise<TokenRefreshResult> => {
  const httpConfig: HttpConfig = {
    data: {
      clientId,
      refreshToken,
    },
    method: "post",
    url: Urls.tokenRefresh(),
  };

  const httpResult = await axios(httpConfig);
  const { accessToken } = httpResult.data as TokenRefreshResult;

  localStorage.setItem(Constants.accessToken, accessToken);

  return httpResult.data as TokenRefreshResult;
};

const updateUserRoles = async (): Promise<void> => {
  const httpConfig: HttpConfig = {
    method: "post",
    url: Urls.updateUserRoles(),
  };

  await axios(httpConfig);
};

const AccessControlService = {
  authorize,
  identify,
  isLoggedIn,
  acquireAccessToken,
  updateUserRoles,
};

export default AccessControlService;
