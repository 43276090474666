import { AxiosResponse } from "axios";
import axios from "../AxiosInstance";
import { HttpConfig } from "../types/types-api";
import { UserWithTokenExpireTime, UserInfo } from "../types/types-user";
import Constants from "./Constants";
import Urls from "./Urls";

const getCurrentUser = (): UserInfo | undefined => {
  const userInfoString = localStorage.getItem(Constants.userInfo);

  if (!userInfoString) {
    return undefined;
  }

  return JSON.parse(userInfoString);
};

const setCurrentUser = (userInfo: UserInfo): void => {
  localStorage.setItem(Constants.userInfo, JSON.stringify(userInfo));
};

const getCurrentUserWithTokenExpireTime =
  async (): Promise<UserWithTokenExpireTime> => {
    const httpConfig: HttpConfig<unknown> = {
      method: "get",
      url: Urls.tokenWhoamiWithExpireTime(),
    };

    const httpResult: AxiosResponse<UserWithTokenExpireTime> = await axios(
      httpConfig
    );
    return httpResult.data;
  };
getCurrentUserWithTokenExpireTime.query = "access-control/user-expire-time";

const CurrentUserService = {
  getCurrentUser,
  setCurrentUser,
  getCurrentUserWithTokenExpireTime,
};

export default CurrentUserService;
