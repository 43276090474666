import { useState } from "react";
import useNotification from "../components/notifications/hook";
import { NotificationTypes } from "../components/notifications/notification-types";
import { Error } from "../types/types-api";

type HandleErrorParameters = {
  error: unknown;
  customHandling?: (errorMsgs: string[]) => void;
  options?: { silent?: boolean; errorMessage: string };
};

type ErrorHandler = {
  errors: string[];
  handleErrors(opts: HandleErrorParameters): void;
};

/* 
Hook for handling errors. 
Input errors returned from API and show banner message. Alternatively handle silent and 
provide messages from state for further handling
*/
const useError = (): ErrorHandler => {
  const [errorMsgs, setErrorMsgs] = useState<string[]>([]);

  const notification = useNotification();

  // Show error message(default NotificationTypes.Banner) with details
  const showError = (
    msg: string | undefined,
    details: string[],
    showDetails = true,
    type: NotificationTypes = NotificationTypes.Banner
  ) => {
    const defaultMsg = `Error happened. ${
      showDetails ? `Detail(s): ${details.join(", ")}` : ""
    }`;

    const customMsg = `${msg}${
      showDetails ? `Detail(s): ${details.join(", ")}` : ""
    }`;

    notification.createNotification({
      type,
      message: msg ? customMsg : defaultMsg,
    });
  };

  // Main handler of errors. Check if error is array of errors or just string and store messages
  const handleErrors = (parameters: HandleErrorParameters) => {
    const { error, customHandling, options } = parameters;
    const { silent, errorMessage } = options || {};
    const msgs: string[] = [];
    const { detail } = error as unknown as Error;

    // If array
    if (Array.isArray(detail)) {
      detail.forEach((errorItem) =>
        msgs.push((errorItem as unknown as { msg: string }).msg)
      );
    }
    if (typeof detail === "string") {
      msgs.push(detail);
    }

    // Error is only string, i.e "Internal server error"
    if (!detail && typeof error === "string") {
      msgs.push(error);
    }

    setErrorMsgs(msgs);

    // If there is custom handling for error messages
    if (customHandling) {
      customHandling(msgs);
    }

    if (customHandling && options?.errorMessage) {
      customHandling([options.errorMessage]);
    }

    // There is no custom handling and silent is not enabled NotificationTypes.Banner error
    if (!customHandling && !silent) {
      showError(errorMessage, msgs);
    }
  };

  return { errors: errorMsgs, handleErrors };
};

export default useError;
