import React from "react";
import styled from "styled-components";

interface ActionContainerProps {
  children?: JSX.Element[] | JSX.Element;
  withPointer?: boolean;
}

interface ContainerProps {
  withPointer: boolean;
}

const Container = styled.div<ContainerProps>`
  min-width: 430px;
  overflow-x: auto;
  display: grid;
  background: ${({ theme }) => theme.palette.brandGray.light};
  padding: 20px;
  position: relative;

  &::after {
    content: "";
    position: absolute;
    border: 10px solid;
    border-color: ${({ theme }) => theme.palette.brandGray.light} transparent
      transparent;
    top: -18px;
    left: 25px;
    transform: rotate(180deg);
    visibility: ${({ withPointer }) => (withPointer ? "visible" : "hidden")};
  }
`;

const ActionContainer: (props: ActionContainerProps) => JSX.Element = ({
  children,
  withPointer = false,
}) => {
  return <Container withPointer={withPointer}>{children}</Container>;
};

export default ActionContainer;
