import { AxiosResponse } from "axios";
import axios from "../../AxiosInstance";
import Urls from "../Urls";
import { HttpConfig } from "../../types/types-api";
import View from "../../types/contracts/View";

const listViews = async (): Promise<View[]> => {
  const httpConfig: HttpConfig<unknown> = {
    method: "get",
    url: Urls.getAllViews(),
  };

  const httpResult = await axios(httpConfig);
  return httpResult.data;
};

const getView = async (viewId: number): Promise<View> => {
  const httpConfig: HttpConfig<unknown> = {
    method: "get",
    url: Urls.getViewById(viewId),
  };

  const httpResult: AxiosResponse<View> = await axios(httpConfig);
  return httpResult.data;
};

const ViewService = {
  listViews,
  getView,
};

export default ViewService;
