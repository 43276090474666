enum MeasureMode {
  InPP = "InPP",
  OutPP = "OutPP",
}

interface MeasurementResult {
  value: number[];
  darkValue: number[] | null;
}

export { MeasureMode, MeasurementResult };
