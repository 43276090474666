import { AxiosError } from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { Link, Route, useLocation } from "react-router-dom";
import { Button } from "@mui/material";

import axios from "../AxiosInstance";
import ErrorMessage from "../components/ErrorMessage";
import CurrentUserService from "../services/CurrentUserService";
import PermissionService from "../services/PermissionService";
import Urls from "../services/Urls";
import { accessToken, refreshToken, savePreviousPath } from "./helpers";

interface SecureRouteProps {
  exact?: boolean;
  children?: React.ReactNode;
  component?: React.ComponentType;
  path: string;
  notLoggedInScreen?: JSX.Element;
  isAccessDenied: boolean;
  redirectToLogout: () => Promise<void>;
  isConcurrentLimitReached: boolean;
}

const AUTHORIZATION_PATH = process.env.REACT_APP_AUTHORIZATION_PATH;

const hasTokens = () => accessToken() !== "" && refreshToken() !== "";

const ErrorView = ({
  onLogoutClick,
  message,
}: {
  onLogoutClick: () => void;
  message: string;
}) => {
  return (
    <ErrorMessage message={message}>
      <Link to="/logout" onClick={onLogoutClick}>
        <Button variant="outlined">Logout</Button>
      </Link>
    </ErrorMessage>
  );
};

const SecureRoute: (props: SecureRouteProps) => JSX.Element = ({
  children,
  component,
  exact,
  path,
  notLoggedInScreen,
  isAccessDenied,
  redirectToLogout,
  isConcurrentLimitReached,
}): JSX.Element => {
  const [failure, setFailure] = useState("");
  const [loggedIn, setLoggedIn] = useState(false);

  // Location
  const location = useLocation();

  const hasErrors = failure.length > 0;

  const redirectToLogoutWrapper = useCallback(async () => {
    await redirectToLogout();
    setTimeout(() => {
      setFailure("");
    }, 500);
  }, [redirectToLogout]);

  useEffect(() => {
    const fetchData = async () => {
      if (hasTokens()) {
        try {
          const result = await axios.get(Urls.tokenWhoami(true));

          if (result) {
            CurrentUserService.setCurrentUser(result.data);
            PermissionService.storeToStorage(result.data.permissions);
            setLoggedIn(true);
          } else {
            setLoggedIn(false);
          }
        } catch (error) {
          if ((error as AxiosError).response) {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            const { status, data } = (error as AxiosError).response!;

            switch (status) {
              case 401:
                // Auth error.
                savePreviousPath(location.pathname ?? "/home");
                setFailure(
                  `Authorization error, please try again later. If the problem persists,
    contact support for further assistance. (${
      data?.detail ?? (error as AxiosError).message
    } (${status})`
                );
                break;
              case 403:
                // Permission errors.
                setFailure(
                  `The maximum number of concurrent logging sessions has been exceeded for this user. As a result, this login session is no longer valid. Please log out by clicking the button below and then log in again. (${
                    data?.detail ?? (error as AxiosError).message
                  } (${status})`
                );
                break;

              default:
                setFailure(
                  `Authorization error, please try again later. If the problem persists,
    contact support for further assistance. (${
      data?.detail ?? (error as AxiosError).message
    } (${status})`
                );
                break;
            }
          } else {
            setFailure(
              `Authorization error, please try again later. If the problem persists,
    contact support for further assistance. (${(error as AxiosError).message})`
            );
          }
        }
      } else {
        savePreviousPath(location.pathname ?? "/home");
      }
    };

    if (CurrentUserService.getCurrentUser()) {
      setLoggedIn(true);
    }

    if (!hasErrors) {
      fetchData();
    }
  }, [hasErrors, loggedIn]); // eslint-disable-line react-hooks/exhaustive-deps

  if (isConcurrentLimitReached) {
    return (
      <ErrorView
        message="The maximum number of concurrent login sessions has been exceeded for this user. As a result, this login session is no longer valid. Please log out by clicking the button below and then log in again."
        onLogoutClick={() => {
          redirectToLogoutWrapper();
        }}
      />
    );
  }

  if (isAccessDenied) {
    return (
      <ErrorView
        message="Access denied. Please log out by clicking the button below and then log in again."
        onLogoutClick={() => {
          redirectToLogoutWrapper();
        }}
      />
    );
  }

  if (hasErrors) {
    return (
      <ErrorMessage message={failure}>
        <Button variant="outlined" onClick={redirectToLogoutWrapper}>
          Log out
        </Button>
      </ErrorMessage>
    );
  }

  if (!hasTokens() && path !== AUTHORIZATION_PATH) {
    return <>{notLoggedInScreen}</>;
  }

  if (component) {
    return <Route exact={exact} path={path} component={component} />;
  }

  return (
    <Route exact={exact} path={path}>
      {children}
    </Route>
  );
};

export default SecureRoute;
