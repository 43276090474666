import { z } from "zod";
import {
  CrudOperation,
  ResourceService,
  ResourceType,
  WildcardOptions,
} from "../types/types-permission";

export const OperationsSchema = z
  .nativeEnum(WildcardOptions)
  .or(z.nativeEnum(CrudOperation));

export const TypesSchema = z
  .nativeEnum(WildcardOptions)
  .or(z.nativeEnum(ResourceType));

export const ServicesSchema = z
  .nativeEnum(WildcardOptions)
  .or(z.nativeEnum(ResourceService));

export const ResourceSchema = z.object({
  objectId: z.nativeEnum(WildcardOptions),
  service: ServicesSchema,
  type: TypesSchema,
});

export const PermissionSchema = z.object({
  createdOn: z.string(),
  operation: OperationsSchema,
  permissionId: z.number(),
  resource: ResourceSchema,
  roleId: z.number(),
  superAdmin: z.boolean(),
});
