import { BarcodeScanner } from "@capacitor-community/barcode-scanner";
import { CodeScanResult } from "../../types/types-product";

const didUserGrantPermission = async (): Promise<boolean> => {
  const statusRequest = await BarcodeScanner.checkPermission({ force: true });

  // TODO handle different states explictly e.g. asked, denied, unknown...
  if (statusRequest.granted) {
    return true;
  }

  return false;
};

const scan = async (
  scanCallback: (result: CodeScanResult) => void
): Promise<void> => {
  const permissionGranted = await didUserGrantPermission();

  if (!permissionGranted) {
    // TODO handle the case were the permission is not granted and remove alert
    // disabling the rule here, just because the alert is a temporary placeholder mechanic
    // eslint-disable-next-line no-alert
    alert("Permission not granted");
    return;
  }

  try {
    document.body.classList.add("barcode");
    const scanResult = await BarcodeScanner.startScan();

    if (scanResult.hasContent) {
      scanCallback(scanResult as CodeScanResult);
    }
  } catch (e) {
    // TODO handle error
  } finally {
    document.body.classList.remove("barcode");
  }
};

const stopScan = (): void => {
  // TODO make sure to call stopScan in case the user navigates away or similar
  BarcodeScanner.stopScan();
};

const BarCodeScannerServiceMobile = {
  scan,
  stopScan,
};

export default BarCodeScannerServiceMobile;
