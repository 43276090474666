import React, { SVGProps } from "react";

interface BatteryIconProps extends SVGProps<SVGSVGElement> {
  percentage?: number;
}

const clamp = (num: number, min: number, max: number) =>
  Math.min(Math.max(num, min), max);

const BatteryIcon: (props: BatteryIconProps) => JSX.Element = ({
  percentage = 0,
  width = "1em",
  height = "1em",
  fill = "currentColor",
  ...props
}) => {
  const maxWidth = 352;
  const maxHeight = 128;
  const x0 = 96;
  const y0 = 192;
  const x1 = x0 + maxWidth * (clamp(percentage, 0, 100) / 100);
  const y1 = y0 + maxHeight;

  const framePath =
    "M464 96c44.2 0 80 35.8 80 80v16c17.7 0 32 14.3 32 32v64c0 17.7-14.3 32-32 32v16" +
    "c0 44.2-35.8 80-80 80H80c-44.18 0-80-35.8-80-80V176c0-44.2 35.82-80 80-80h384z" +
    "M64 336c0 8.8 7.16 16 16 16h384c8.8 0 16-7.2 16-16V176c0-8.8-7.2-16-16-16H80" +
    "c-8.84 0-16 7.2-16 16v160z";

  const barPath = percentage === 0 ? "" : `M${x0} ${y0} H${x1} V${y1} H${x0} z`;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 576 512"
      width={width}
      height={height}
      fill={fill}
      stroke="none"
      {...props}
    >
      <path d={`${framePath} ${barPath}`} />
    </svg>
  );
};

export default BatteryIcon;
