import React, { useState } from "react";
import styled from "styled-components";
import {
  Select as MuiSelect,
  SelectChangeEvent,
  TableCell,
} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";

interface TableCellSelectProps {
  value: string;
  options: Array<{ value: string | number; label: string }>;
  onChange: (event: SelectChangeEvent) => void;
  extra?: JSX.Element;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 175px;
`;

// Typecast requried to resolve issue with onChange types
const Select: typeof MuiSelect = styled(MuiSelect)`
  > div {
    padding: 6px 12px;
  }
`;

export const TableCellString: (props: TableCellSelectProps) => JSX.Element = ({
  value,
  options,
  onChange,
  extra,
}) => {
  const [selectedValue, setSelectedValue] = useState(value);
  return (
    <TableCell>
      <Container>
        <Select
          onChange={(event: SelectChangeEvent<string>) => {
            setSelectedValue(event.target.value);
            onChange(event);
          }}
          value={selectedValue}
        >
          {options.map((option) => (
            <MenuItem key={option.label} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
        <span>{extra}</span>
      </Container>
    </TableCell>
  );
};

export default TableCellString;
