import { bytesToEncodedString } from "./bluetoothle";

export const fifoUUID = "2456E1B9-26E2-8F83-E744-F34F01E9D703"; // fifo's characteristic
export const creditsUUID = "2456E1B9-26E2-8F83-E744-F34F01E9D704"; // credits's characteristic
export const serviceUUID = "2456E1B9-26E2-8F83-E744-F34F01E9D701";

// These constants are documented in this specification:
// https://spectralengines.atlassian.net/wiki/download/attachments/888296/DTS-200012_BLE_API_DESCRIPTION_NIRONE_DEVICE_customers.docx?api=v2

const OK_NOT_OK_RESPONSE_VALUES = {
  OK: 0x00,
  NOT_OK: 0x01,
};

export const READ_SERIAL_NUMBER_REQUEST = 0x0100;
export const READ_SERIAL_NUMBER_RESPONSE = 0x0200;

export const SET_PARAMETERS_REQUEST = 0x0101;
export const SET_PARAMETERS_RESPONSE = 0x0201;
export const SET_PARAMETERS_RESPONSE_VALUES = OK_NOT_OK_RESPONSE_VALUES;

export const START_MEASURE_REQUEST = 0x0102;
export const START_MEASURE_RESPONSE = 0x0202;
export const START_MEASURE_RESPONSE_VALUES = {
  NOT_OK: 0x00,
  STARTED: 0x01,
  READY: 0x02,
};

export const READ_MEASURE_RESULT_REQUEST = 0x0103;
export const READ_MEASURE_RESULT_RESPONSE = 0x0203;

export const READ_DEVICE_INFO_REQUEST = 0x0104;
export const READ_DEVICE_INFO_RESPONSE = 0x0204;

export const LED_CONTROL_REQUEST = 0x0105;
export const LED_CONTROL_RESPONSE = 0x0205;
export const LED_CONTROL_RESPONSE_VALUES = OK_NOT_OK_RESPONSE_VALUES;

// caution: not part of spec
export const READ_DEVICE_READY_STATUS_REQUEST = 0x0106;
export const READ_DEVICE_READY_STATUS_RESPONSE = 0x0206;
export const READ_DEVICE_READY_STATUS_RESPONSE_VALUES =
  OK_NOT_OK_RESPONSE_VALUES;

export const SET_INACTIVITY_TIMER_REQUEST = 0x0107;
export const SET_INACTIVITY_TIMER_RESPONSE = 0x0207;
export const SET_INACTIVITY_TIMER_MIN_VALUE = 0;
export const SET_INACTIVITY_TIMER_MAX_VALUE = 999;

export const READ_PARAMETER_HASH_REQUEST = 0x0108;
export const READ_PARAMETER_HASH_RESPONSE = 0x0208;

export const SCAN_BUTTON = 0x01;
export const POWER_BUTTON = 0x02;
export const CANCEL_CREDIT = 0xff;

export const START_BYTE = 0xab;
export const STOP_BYTE = 0xbb;
export const PAYLOAD_START_POS = 7;
export const START_HEADER_LENGTH = PAYLOAD_START_POS;
// Note: MSG_HEADER_LEN includes the optional STOP_BYTE
export const MSG_HEADER_LEN = 8;
// The last message contains 2 header bytes, a) index b) STOP_BYTE
export const INDICATION_BYTE = 3;
export const NON_LAST_MSG_HEADER_LEN = 1;
export const LAST_MSG_HEADER_LEN = 2;
export const MSGID_CRC16_LEN = 4;
export const PAYLOAD_LENGTH_POS = 1;
export const MESSAGE_ID_POS = 3;
export const CRC_POS = 5;
export const FIRST_MSG_RESULT_DATA_LEN = 12;
export const RESULT_DATA_LEN = 19;
// The payload length includes 4 header bytes (message ID and CRC16).
export const PAYLOAD_HEADER_BYTES = 4;
export const CREDITS_16_SIGNAL = bytesToEncodedString([0x10]); // 0x means the number is hexadecimal, or base 16. 0x10 is 16.
export const CLOSE_FLOW_CONTROL = bytesToEncodedString([0xff]); // = decimal number 255 or -1 in decimal from signed 2's complement:

export const SCANNER_MOVEMENT_DETECTED = 771; // 0x0303
