import { useCallback, useState } from "react";
import {
  ProductService,
  Model,
  useError,
  ModelApprovalData,
  ProductVariantSnapshot,
  useHttpErrorReader,
  ModelLogsDownloadRequest,
} from "lib-core";
import { AxiosError } from "axios";
import { downloadFile } from "../shared/file-helpers";

type ProductVariantTrainingData = {
  cancelModelTrainingRun(viewId: number): Promise<void>;
  approveModel(modelApprovalData: ModelApprovalData): Promise<void>;
  rejectModel(modelApprovalData: ModelApprovalData): Promise<void>;
  decommissionModel(modelApprovalData: ModelApprovalData): Promise<void>;
  fetchPlatforms(): Promise<void>;
  loadingModels: boolean;
  fetchTrainingModels(variantId: number): void;
  models: Model[];
  changeLoading: boolean;
  error: unknown | undefined;
  getProductVariantSnapshots(variantId: number): void;
  downloadModelTrainingLogs(request: ModelLogsDownloadRequest): Promise<void>;
  snapshots: ProductVariantSnapshot[];
  platforms: string[];
};

const useProductVariantTraining = (): ProductVariantTrainingData => {
  const [loadingModels, setLoadingModels] = useState<boolean>(false);
  const [models, setModels] = useState<Model[]>([]);
  const [changeLoading, setChangeLoading] = useState<boolean>(false);
  const [error, setError] = useState<unknown>();
  const { handleErrors } = useError();
  const errorReader = useHttpErrorReader();
  const [snapshots, setSnapshots] = useState<ProductVariantSnapshot[]>([]);
  const [platforms, setPlatforms] = useState<string[]>([]);

  const cancelModelTrainingRun = async (viewId: number) => {
    setChangeLoading(true);
    setError(undefined);
    try {
      await ProductService.cancelModelTrainingRun(viewId);
      setChangeLoading(false);
    } catch (err) {
      const error = err as AxiosError;

      if (error.response) {
        handleErrors({
          error,
          customHandling: errorReader(error.response),
        });
      }
      setError(err);
    }
    setChangeLoading(false);
    setError(false);
  };

  const getProductVariantSnapshots = async (productVariantId: number) => {
    setChangeLoading(true);
    setError(undefined);
    try {
      const result = await ProductService.getProductVariantSnapshots(
        productVariantId
      );
      setSnapshots(result);
      setChangeLoading(false);
    } catch (err) {
      const { response: errorResponse } = err as AxiosError;

      if (errorResponse) {
        const { data: error } = errorResponse;
        handleErrors({
          error,
          customHandling: errorReader(errorResponse),
        });
      }
      setError(err);
    }
    setChangeLoading(false);
    setError(false);
  };

  const approveModel = async (modelApprovalData: ModelApprovalData) => {
    setChangeLoading(true);
    setError(undefined);
    try {
      await ProductService.approveModel(modelApprovalData);

      setChangeLoading(false);
    } catch (err) {
      const { response: errorResponse } = err as AxiosError;

      if (errorResponse) {
        const { data: error } = errorResponse;
        handleErrors({
          error,
          customHandling: errorReader(errorResponse),
        });
      }
      setError(err);
    }
    setChangeLoading(false);
    setError(false);
  };

  const rejectModel = async (modelApprovalData: ModelApprovalData) => {
    setChangeLoading(true);
    setError(undefined);
    try {
      await ProductService.rejectModel(modelApprovalData);

      setChangeLoading(false);
    } catch (err) {
      const { response: errorResponse } = err as AxiosError;

      if (errorResponse) {
        const { data: error } = errorResponse;
        handleErrors({
          error,
          customHandling: errorReader(errorResponse),
        });
      }
      setError(err);
    }
    setChangeLoading(false);
    setError(false);
  };

  const decommissionModel = async (modelApprovalData: ModelApprovalData) => {
    setChangeLoading(true);
    setError(undefined);
    try {
      await ProductService.decommissionModel(modelApprovalData);

      setChangeLoading(false);
    } catch (err) {
      const { response: errorResponse } = err as AxiosError;

      if (errorResponse) {
        const { data: error } = errorResponse;
        handleErrors({
          error,
          customHandling: errorReader(errorResponse),
        });
      }
      setError(err);
    }
    setChangeLoading(false);
    setError(false);
  };

  const fetchTrainingModels = async (variantId: number) => {
    setLoadingModels(true);
    setError(undefined);
    try {
      const response = await ProductService.getProductVariantTrainings(
        variantId
      );

      setModels(response.rows);
      setLoadingModels(false);
    } catch (err) {
      const { response: errorResponse } = err as AxiosError;

      if (errorResponse) {
        const { data: error } = errorResponse;
        handleErrors({
          error,
          customHandling: errorReader(errorResponse),
        });
      }
      setError(err);
    }
    setLoadingModels(false);
  };

  const fetchPlatforms = async () => {
    setError(undefined);
    try {
      const response = await ProductService.getModelPlatforms();

      setPlatforms(response);
    } catch (err) {
      const { response: errorResponse } = err as AxiosError;

      if (errorResponse) {
        const { data: error } = errorResponse;
        handleErrors({
          error,
          customHandling: errorReader(errorResponse),
        });
      }
      setError(err);
    }
    setLoadingModels(false);
  };

  const downloadModelTrainingLogs = useCallback(
    async (request: ModelLogsDownloadRequest) => {
      const logContent = await ProductService.downloadModelTrainingLogs(
        request
      );
      const url = `data:text/plain;charset=utf-8,${encodeURI(logContent)}`;
      // It is not possible to follow the 302 redirect s3 url to get the file name,
      // hence we need to manually construct the file name in the FE
      const fileName = `model_training_logs_${request.modelName}_${request.runId}.log`;
      downloadFile(fileName, url);
    },
    []
  );

  return {
    cancelModelTrainingRun,
    fetchTrainingModels,
    models,
    loadingModels,
    changeLoading,
    error,
    approveModel,
    getProductVariantSnapshots,
    downloadModelTrainingLogs,
    snapshots,
    rejectModel,
    decommissionModel,
    fetchPlatforms,
    platforms,
  };
};

export default useProductVariantTraining;
