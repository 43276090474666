import React, { useState } from "react";
import styled from "styled-components";
import { default as MuiTextField } from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { PermissionKeys, usePermissionChecking } from "lib-core";
import { Typography } from "@mui/material";
import { useMutation } from "@tanstack/react-query";

interface DetailsViewNormalNoteProps {
  saveNote: (message: string) => Promise<void>;
  header: string;
}

const TextField = styled(MuiTextField)`
  width: 100%;
`;

const Headline = styled(Typography)`
  margin-bottom: 14px;
`;

const { writeMeasurementNote } = PermissionKeys;

const DetailsViewNormalNote: (
  props: DetailsViewNormalNoteProps
) => JSX.Element = ({ saveNote, header }) => {
  const [message, setMessage] = useState("");
  const requirePermission = usePermissionChecking();

  const disableItNote = () => {
    return !requirePermission([writeMeasurementNote]);
  };

  const { mutate, isLoading } = useMutation({
    mutationFn: () => saveNote(message),
    onSuccess() {
      setMessage("");
    },
  });

  const saveMessage = () => mutate();

  const disableItButton = () => {
    return message.length < 1 || isLoading;
  };

  const updateMessage = (ev: React.ChangeEvent<HTMLInputElement>) => {
    setMessage(ev.target.value);
  };

  return (
    <>
      <Headline variant="h3">{header}</Headline>
      <TextField
        disabled={disableItNote()}
        id="outlined-multiline-static"
        multiline
        onChange={updateMessage}
        rows={3}
        placeholder="What's on your mind?"
      />
      <Button
        disabled={disableItButton()}
        fullWidth={false}
        onClick={saveMessage}
        variant="outlined"
      >
        Add Note
      </Button>
    </>
  );
};

export default DetailsViewNormalNote;
