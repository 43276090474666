import React, { useEffect, useState } from "react";
import { Typography, styled } from "@mui/material";

import { BluetoothDevice } from "../types/types-bluetooth";
import SimpleBatteryIndicator from "./SimpleBatteryIndicator";

interface SelectedDeviceProps {
  isCalibrated: boolean | undefined;
  selectedDevice: BluetoothDevice | undefined;
  className?: string;
}

const Container = styled("div")``;
const Row = styled(Typography)``;

const SelectedDevice: (props: SelectedDeviceProps) => JSX.Element = ({
  isCalibrated,
  selectedDevice,
  className = "",
}) => {
  const [canConnect, setCanConnect] = useState(true);
  const [problems, setProblems] = useState<string>("");

  useEffect(() => {
    const messages = [];
    if (!isCalibrated) messages.push("uncalibrated");
    if (!canConnect) messages.push("no connection");
    setProblems(messages.join(", "));
  }, [isCalibrated, canConnect]);

  if (!selectedDevice) {
    return <></>;
  }

  const { serialNumber } = selectedDevice;

  return (
    <Container className={`SelectedDevice ${className}`}>
      <Row variant="body2" className="SelectedDevice-label-row">
        <span className="SelectedDevice-label">Active sensor</span>
        {problems && (
          <>
            {" "}
            <span className="SelectedDevice-problems">({problems})</span>
          </>
        )}
      </Row>
      <Row className="SelectedDevice-serial-number-row">
        <span className="SelectedDevice-serial-number">{serialNumber}</span>{" "}
        <SimpleBatteryIndicator
          onError={() => {
            setCanConnect(false);
          }}
        />
      </Row>
    </Container>
  );
};

export default SelectedDevice;
