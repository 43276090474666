import React from "react";
import { Typography } from "@mui/material";
import styled from "styled-components";

const Container = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 38px;
  margin-bottom: 8px;
`;

const Title = styled(Typography)`
  transition: color 0.15s;

  &.loading {
    color: ${({ theme }) => theme.palette.brandGray.dark};
  }
`;

const LibrarySubheader: (props: {
  loading?: boolean;
  loadingTitle?: string;
  title: string;
  buttons?: JSX.Element;
}) => JSX.Element = ({
  loading = false,
  loadingTitle = "Loading...",
  title,
  buttons = null,
}) => {
  return (
    <Container>
      <Title variant="h4" className={loading ? "loading" : ""}>
        {loading ? loadingTitle : title}
      </Title>
      {buttons}
    </Container>
  );
};

export default LibrarySubheader;
