import { Filter, FilterTypes, KeyValueParams } from "./datafilter-types";

const encode = encodeURIComponent;

type AutocompleteFilterProps = {
  name: string;
  placeholder: string;
  options: string[];
  value?: string;
};

export const checkIfStringEmpty = (
  str: string | undefined | null
): string | null => (str && str.length > 0 ? str : null);

export const createAutocompleteFilter = ({
  name,
  placeholder,
  options,
  value,
}: AutocompleteFilterProps): Filter => {
  return {
    name,
    placeholder,
    type: FilterTypes.Autocomplete,
    value: checkIfStringEmpty(value),
    options,
  };
};

export const createFilter = (
  name: string,
  placeholder: string,
  type?: FilterTypes,
  value?: string | null,
  filter?: (items: unknown, value: string) => unknown
): Filter => {
  return {
    name,
    placeholder,
    type: type || FilterTypes.String,
    value: value || "",
    filter,
  };
};

export const createKeyValueParams = (filterList: Filter[]): KeyValueParams => {
  return filterList.map((filter: Filter) => {
    const { name, value } = filter;

    return { key: name, value };
  }) as KeyValueParams;
};

export const mapFiltersToQueryString = (filters: KeyValueParams): string => {
  return filters
    .filter(({ value }) => value.length > 0)
    .map(({ key, value }) => {
      return `${encode(key as string)}=${encode(value as string)}`;
    })
    .join("&");
};
