import React from "react";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  height: 100vh;
  width: 100vw;
  align-items: center;
  justify-content: center;
`;

const MessageBox = styled.div`
  display: flex;
  width: 350px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.palette.common.white};
  border: 1px solid ${({ theme }) => theme.palette.grey[300]};
  border-radius: 8px;
`;

const LoggedOut: () => JSX.Element = () => (
  <Container>
    <MessageBox>
      <h3>Logout Successful</h3>
    </MessageBox>
  </Container>
);

export default LoggedOut;
