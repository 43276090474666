import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { StoredMeasurementHistoryItem } from "lib-core";
import Carousel from "react-material-ui-carousel";
import Modal from "@mui/material/Modal";

import DetailsViewTabPanel from "./DetailsViewTabPanel";
import { useMeasurementImages } from "./history-hook";

interface DetailsViewPhotosProps {
  activeTab: number;
  content: StoredMeasurementHistoryItem;
}

const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
`;

const Photo = styled.img`
  width: 50%;
  height: 75%;
  object-fit: cover;
`;

const LargePhoto = styled.img`
  max-width: 75%;
  max-height: 75%;
`;

const PhotoWrap = styled.div`
  min-height: 400px;
  max-height: 400px;
  justify-content: center;
  display: flex;
`;

const DetailsViewPhotos: (props: DetailsViewPhotosProps) => JSX.Element = ({
  activeTab,
  content,
}) => {
  const [largePhoto, setLargePhoto] = useState<string>("");
  const storedItems = useMeasurementImages(content);

  useEffect(() => {
    return () => {
      setLargePhoto("");
    };
  }, [largePhoto]);

  return (
    <>
      <Modal open={Boolean(largePhoto)}>
        <Container>
          <LargePhoto
            src={largePhoto}
            alt="Large image"
            onClick={() => setLargePhoto("")}
          />
        </Container>
      </Modal>
      <DetailsViewTabPanel activeTab={activeTab} index={1}>
        <Carousel>
          {storedItems.map((image) => {
            const { base64, key } = image;
            return (
              <PhotoWrap key={key}>
                <Photo
                  src={base64}
                  alt="Review image"
                  onClick={() => setLargePhoto(base64)}
                />
              </PhotoWrap>
            );
          })}
        </Carousel>
      </DetailsViewTabPanel>
    </>
  );
};

export default DetailsViewPhotos;
