import axiosOriginal, { AxiosAdapter, AxiosInstance } from "axios";

const clientName = process.env.REACT_APP_CLIENT_NAME || "";

const axios: AxiosInstance = axiosOriginal.create({
  baseURL: process.env.REACT_APP_SERVER_URL,
});

// Audit trail header
axios.interceptors.request.use((req) => {
  req.headers["X-Client-Application"] = clientName;
  return req;
});

export const toggleCustomAxiosAdapter = (adapter: AxiosAdapter): void => {
  axios.defaults.adapter = adapter;
};

export default axios;
