import React from "react";
import styled from "styled-components";
import { TableCell as MuiTableCell } from "@mui/material";

import { format, isWithinInterval, subDays } from "date-fns";
import { ExpiredValue } from "./datatable-types";

interface TableCellExpiredCheckProps {
  value: ExpiredValue;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100%;
  height: 100%;
  width: 100%;
`;

const InnerBox = styled.div`
  align-items: space-between;
`;

const DashBox = styled.span`
  color: ${({ theme }) => theme.palette.error.main};
`;

const Success = styled.span`
  color: ${({ theme }) => theme.palette.success.main};
`;

const Error = styled.span`
  color: ${({ theme }) => theme.palette.error.main};
`;

const Warning = styled.span`
  color: ${({ theme }) => theme.palette.warning.main};
`;

const TableCell = styled(MuiTableCell)`
  vertical-align: top !important;
`;

type TableCellContainerProps = {
  type: string;
  children: React.ReactNode;
};

export const CellText: (props: TableCellContainerProps) => JSX.Element = ({
  type,
  children,
}) => {
  switch (type) {
    case "success":
      return <Success>{children}</Success>;
    case "error":
      return <Error>{children}</Error>;
    case "warning":
      return <Warning>{children}</Warning>;
    default:
      return <span>{children}</span>;
  }
};

export const TableCellExpiredCheck: (
  props: TableCellExpiredCheckProps
) => JSX.Element = ({ value }) => {
  let limit = value.isValid ? "OK" : "Overdue";
  let type = value.isValid ? "success" : "error";

  const date = format(new Date(value.date), "y-MM-dd");
  // If today is between expiryDate-value.limit+1 and expiryDate

  const expiryDate = new Date(value.date);
  const oneWeekBeforeExpiryDate = subDays(expiryDate, value.limit + 1);
  const today = new Date();

  const overLimit = isWithinInterval(today, {
    start: oneWeekBeforeExpiryDate,
    end: expiryDate,
  });

  const calibrationStatus = (
    limit: string,
    statusMessage: string | undefined,
    type: string
  ) => {
    switch (statusMessage) {
      case "TOO_MANY_FAILED_CALIBRATIONS":
        return (
          <>
            <br />
            <DashBox> &mdash; </DashBox>
          </>
        );
      default:
        if (!value.date) {
          return null;
        }

        return (
          <>
            <CellText type={type}>{limit}</CellText>
            <br />
            <CellText type={type}>{date}</CellText>
          </>
        );
    }
  };

  if (value.isValid && overLimit) {
    limit = "Almost due";
    type = "warning";
  }

  const tooManyFails = value.statusMessage === "TOO_MANY_FAILED_CALIBRATIONS";

  if (tooManyFails) {
    limit = "FAILED";
    type = "error";
  }

  return (
    <TableCell>
      <Container>
        <InnerBox>
          {calibrationStatus(limit, value.statusMessage, type)}
        </InnerBox>
      </Container>
    </TableCell>
  );
};

export default TableCellExpiredCheck;
