import React from "react";
import styled from "styled-components";
import Tooltip from "@mui/material/Tooltip";

interface HoverProps {
  children: React.ReactNode;
  tooltipText?: string;
}

const Container = styled.div`
  display: table;
`;

const Hover: (props: HoverProps) => JSX.Element = ({
  children,
  tooltipText,
}) => {
  return (
    <Container>
      <Tooltip title={tooltipText ?? false} arrow>
        <div>{children}</div>
      </Tooltip>
    </Container>
  );
};

export default Hover;
