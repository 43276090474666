const bluetoothle = window.ble;

/**
 * Helper function to convert a unit8Array to a base64 encoded string for a characteric or descriptor write.
 */
function bytesToEncodedString(bytes: number[]): string {
  // Implementation from https://github.com/randdusing/cordova-plugin-bluetoothle/blob/master/www/bluetoothle.js
  return btoa(String.fromCharCode.apply(null, bytes));
}

/**
 * Helper function to convert a base64 encoded string from a characteristic or descriptor value into a uint8Array object.
 */
function encodedStringToBytes(string: string): Uint8Array {
  // Implementation from https://github.com/randdusing/cordova-plugin-bluetoothle/blob/master/www/bluetoothle.js
  const data = atob(string);
  const bytes = new Uint8Array(data.length);

  for (let i = 0; i < bytes.length; i += 1) {
    bytes[i] = data.charCodeAt(i);
  }

  return bytes;
}

function stringToArrayBuffer(str: string): ArrayBufferLike {
  const ret = new Uint8Array(str.length);

  for (let i = 0; i < str.length; i += 1) {
    ret[i] = str.charCodeAt(i);
  }

  return ret.buffer;
}

/**
 * Helper function to convert a base64 encoded string from a characteristic or descriptor value into ArrayBuffer
 */
function encodedStringToArrayBuffer(string: string): ArrayBufferLike {
  return stringToArrayBuffer(atob(string));
}

function bytesToString(bytes: Uint8Array): string {
  // Implementation from https://github.com/randdusing/cordova-plugin-bluetoothle/blob/master/www/bluetoothle.js
  return String.fromCharCode.apply(
    null,
    new Uint16Array(bytes) as unknown as number[]
  );
}

export {
  bluetoothle,
  bytesToEncodedString,
  encodedStringToBytes,
  encodedStringToArrayBuffer,
  stringToArrayBuffer,
  bytesToString,
};
export default bluetoothle;
