import React from "react";
import styled from "styled-components";
import format from "date-fns/format";
import { Typography } from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import {
  MeasurementAssessment,
  Note,
  mapAssessmentToColor,
  mapAssessmentToUserString,
} from "lib-core";

import theme from "../theme";

interface DetailsViewNoteItemProps {
  item: Note;
  isAssessmentNote: boolean;
  assessment: MeasurementAssessment | undefined;
}

const Signature = styled.div`
  display: flex;
`;

const Author = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const Icon = styled.div`
  display: flex;
  align-items: center;
  padding-right: 1rem;
`;

const Message = styled.div`
  margin-top: 10px;
  margin-left: 40px;
`;

const Stack = styled.div`
  display: flex;
  flex-direction: column;

  &:not(:last-child) {
    margin-bottom: 20px;
  }
`;

const AssessmentIndicator = styled.span`
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 0.5rem;
  margin-right: 10px;
  background: currentColor;
`;

const AssessmentContainer = styled.div<{ assessment: MeasurementAssessment }>`
  display: flex;
  align-items: center;
  color: ${({ assessment, theme }) => mapAssessmentToColor(assessment, theme)};
`;

const DetailsViewNoteItem: (props: DetailsViewNoteItemProps) => JSX.Element = ({
  item,
  isAssessmentNote,
  assessment,
}) => {
  const { createdOn, message } = item;

  const author = item.authorPreferredUsername;
  const timestamp = format(new Date(createdOn), "yyyy-MM-dd HH:mm");

  return (
    <Stack>
      <Signature>
        <Icon>
          <AccountCircleIcon />
        </Icon>
        <Author>
          <Typography variant="body1">{author}</Typography>
          <Typography color={theme.palette.grey[600]} variant="body2">
            {timestamp}
          </Typography>
        </Author>
      </Signature>
      <Message>
        {isAssessmentNote && assessment && (
          <AssessmentContainer assessment={assessment}>
            <AssessmentIndicator />
            <Typography variant="overline" fontWeight="bold">
              Assessment: {mapAssessmentToUserString(assessment)}
            </Typography>
          </AssessmentContainer>
        )}
        <Typography variant="body2">{message}</Typography>
      </Message>
    </Stack>
  );
};

export default DetailsViewNoteItem;
