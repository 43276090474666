import React from "react";
import format from "date-fns/format";

import { TableCell } from "@mui/material";

interface TableCellDateProps {
  value: Date;
  format?: string;
}

export const TableCellDate: (props: TableCellDateProps) => JSX.Element = ({
  value,
  format: dateFormat,
}) => (
  <TableCell>
    <div>{format(value, dateFormat ?? "yy-MM-dd HH:mm:ss")}</div>
  </TableCell>
);

export default TableCellDate;
