import React from "react";
import styled from "styled-components";
import { TableCell } from "@mui/material";

import { CoordsValue } from "./datatable-types";

interface TableCellCoordsProps {
  value: CoordsValue;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Coordinates = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TableCellCoords: (props: TableCellCoordsProps) => JSX.Element = ({
  value,
}) => (
  <TableCell>
    <Container>
      <Coordinates>
        {value.north &&
          value.west &&
          `N ${value.north.substring(0, 8)} W ${value.west.substring(0, 8)}`}
      </Coordinates>
    </Container>
  </TableCell>
);

export default TableCellCoords;
