import { useEffect, useState } from "react";
import {
  HistoryService,
  KeyValueParams,
  StoredMeasurementHistoryItem,
  TableDataArray,
  PagingData,
  ImageService,
  DataTableHeaderType,
  useError,
  UniqueValues,
  useHttpErrorReader,
  NotificationTypes,
  useNotification,
  ModelStatuses,
  assessmentToFilter,
} from "lib-core";

import { AxiosError } from "axios";
import { useTheme } from "@mui/material";
import { historyTableMapper } from "./history-helpers";

export type ImageState = {
  base64: string;
  key: string;
};

type ImageStateArray = ImageState[];

type MeasurementHistoryData = {
  headers: DataTableHeaderType[];
  fetchHistory(page: number, perPage: number, filters?: KeyValueParams): void;
  pagination: PagingData | undefined;
  measurementHistory: TableDataArray<StoredMeasurementHistoryItem>;
  exportCsvMeasurementHistory: (filters: KeyValueParams) => Promise<string>;
  loading: boolean;
};

const headers = [
  { key: "globalBrand", label: "Product" },
  { key: "batchNumber", label: "Batch number" },
  { key: "caseNumber", label: "Case number" },
  { key: "modelStatus", label: "Model" },
  { key: "authResult", label: "Result" },
  { key: "assessment", label: "Assessment" },
  { key: "measurerPreferredUsername", label: "Measurer" },
  { key: "deviceSerialNumber", label: "Sensor" },
  { key: "location", label: "Location" },
  { key: "startTime", label: "From time" },
];

const useMeasurementHistory = (): MeasurementHistoryData => {
  const [measurementHistory, setResultHistory] = useState<
    TableDataArray<StoredMeasurementHistoryItem>
  >([]);
  const [pagination, setPagination] = useState<PagingData>();
  const [loading, setLoading] = useState<boolean>(false);
  const errorReader = useHttpErrorReader();
  const theme = useTheme();

  const { handleErrors } = useError();

  const getMeasurementHistory = async (
    page: number,
    perPage: number,
    filters?: KeyValueParams
  ) => {
    const userId = filters?.filter(({ key }) => key === "userId").shift();
    // const metadata = filters?.filter(({ key }) => key !== "userId");

    const metadata = {};
    filters
      ?.filter(({ key, value }) => key !== "userId" && value)
      .forEach(({ key, value }) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        metadata[key] = value;
      });

    if (userId?.value) {
      return HistoryService.getUserMeasurementHistory(
        userId.value,
        { page, perPage },
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        metadata
      );
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return HistoryService.getMeasurementHistory({ page, perPage }, metadata);
  };

  const fetchHistory = async (
    page: number,
    perPage: number,
    filters?: KeyValueParams
  ) => {
    try {
      setLoading(true);
      const history = await getMeasurementHistory(page, perPage, filters);
      const mapped = historyTableMapper(history.entities, theme);

      setResultHistory(mapped);
      setPagination(history.paging);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      const error = err as AxiosError;
      if (error.response) {
        handleErrors({
          error,
          customHandling: errorReader(error.response),
        });
      }
    }
  };

  const exportCsvMeasurementHistory = async (
    filters: KeyValueParams
  ): Promise<string> => {
    return HistoryService.exportMeasurementHistoryCSVFile(filters);
  };

  return {
    measurementHistory,
    headers,
    fetchHistory,
    pagination,
    exportCsvMeasurementHistory,
    loading,
  };
};

export const userFriendlyModelStatusToValue: Record<string, ModelStatuses> = {
  Candidate: ModelStatuses.APPROVED_CANDIDATE,
  Released: ModelStatuses.APPROVED_PRODUCTION,
};

const modelStatusToFilter: (keyof typeof userFriendlyModelStatusToValue)[] = [
  "Candidate",
  "Released",
];

export const useMeasurementUniqueValues = (
  refreshKey?: number
): {
  filterOptions: UniqueValues | null | undefined;
} => {
  const [filterOptions, setFilterOptions] = useState<
    UniqueValues | null | undefined
  >();

  const { handleErrors } = useError();
  const errorReader = useHttpErrorReader();

  useEffect(() => {
    const metadataKeys = [
      "authResult",
      "globalBrand",
      "measureMode",
      "dosageStrength",
      "dosageForm",
      "batchNumber",
      "caseNumber",
    ];
    const keys = ["measurerUsername", "deviceSerialNumber"];
    const getFilterOptions = async () => {
      const result = await HistoryService.getMeasurementUniqueValues(
        metadataKeys,
        keys
      ).catch((e) => {
        if (e.response) {
          const { data: error } = e.response;

          handleErrors({
            error,
            customHandling: errorReader(e.response),
          });
        }

        return null;
      });

      // Backend does not return the selectable options for model statuses
      // and assessment
      // However, we can include it to the same interface to filterOptions
      // to centralize filter options creation in this hook
      if (result) {
        result.metadataValues.modelStatus = modelStatusToFilter;
        result.metadataValues.assessment = assessmentToFilter;
      }

      setFilterOptions(result);
    };

    getFilterOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshKey]);

  return { filterOptions };
};

export const useMeasurementImages = (
  content: StoredMeasurementHistoryItem
): ImageStateArray => {
  const [storedItems, setStoredItems] = useState<ImageStateArray>([]);

  useEffect(() => {
    const { measurerUsername, id, images } = content;

    const fetchImages = async () => {
      const imageItems = await Promise.all(
        images.map(async (image) => {
          const imageId = image.id.toString();
          const key = `${id}-${imageId}`;

          const base64 = await ImageService.getMeasurementImage(
            measurerUsername,
            id,
            imageId
          );

          return { base64, key };
        })
      );

      setStoredItems(imageItems);
    };

    if (storedItems.length < 1) {
      fetchImages();
    }
  }, [content, storedItems]);

  return storedItems;
};

export const useSpectraImage = (
  measurementId: string
): { spectra?: ImageState; error?: unknown } => {
  const [spectra, setSpectra] = useState<ImageState>();
  const [error, setError] = useState<unknown>();
  const notification = useNotification();

  useEffect(() => {
    const fetchImage = async () => {
      try {
        const base64 = await ImageService.getMeasurementSpectra(measurementId);
        setSpectra({ base64, key: measurementId });
      } catch (error) {
        notification.createNotification({
          type: NotificationTypes.Snackbar,
          message: "Error loading measurement spectra image.",
          severity: "error",
        });
        setError(error);
      }
    };

    if (!spectra && !error) {
      fetchImage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [measurementId, spectra, error]);

  return { spectra, error };
};

export default useMeasurementHistory;
