import { createContext, Dispatch, SetStateAction, useContext } from "react";

type TokenRefreshContextType = {
  tokenRefresh: boolean;
  setTokenRefresh: Dispatch<SetStateAction<boolean>>;
};

export const TokenRefreshContext = createContext<TokenRefreshContextType>({
  tokenRefresh: false,
  setTokenRefresh: () => {
    throw new Error("TokenRefresh context used outside of provider");
  },
});

export const useTokenRefresh = (): TokenRefreshContextType =>
  useContext(TokenRefreshContext);
