/**
 * Returns is true if the input is an Object, falseotherwise
 * @param value to check
 * @returns boolean
 */
function isObject(value: unknown): boolean {
  const type = typeof value;
  // eslint-disable-next-line eqeqeq
  return !!value && (type == "object" || type == "function");
}

export default isObject;
