import React, { useCallback, useMemo, useState } from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import { BlendedSnapshot } from "lib-core";

import { useBlendedSnapshot } from "../../library/blendedSnapshot-hook";
import { headers } from "./blendedSnapshot.headers";
import { BlendedSnapshotRow } from "./BlendedSnapshotRow";
import { UpcomingBlendedSnapshotRow } from "./UpcomingBlendedSnapshotRow";

interface Props {
  blendedSnapshots: BlendedSnapshot[];
  latestUsableSnapshot: BlendedSnapshot | undefined;
}

export const BlendedSnapshotTable = ({
  blendedSnapshots,
  latestUsableSnapshot,
}: Props): JSX.Element => {
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const { downloadBlendedSnapshotMetadata, blendedSnapshotUpcoming } =
    useBlendedSnapshot();

  const isUpcomingSnapshotInfoAvailable = !!blendedSnapshotUpcoming;

  const onClickDownload = useCallback(
    (id: string, version: number) => {
      return downloadBlendedSnapshotMetadata(id, version);
    },
    [downloadBlendedSnapshotMetadata]
  );

  const snapshotsToDisplay = useMemo(() => {
    const startIndex = page * rowsPerPage;
    let endIndex = startIndex + rowsPerPage;
    if (isUpcomingSnapshotInfoAvailable && page === 0) {
      endIndex -= 1;
    }
    return [...blendedSnapshots].slice(startIndex, endIndex);
  }, [blendedSnapshots, page, rowsPerPage, isUpcomingSnapshotInfoAvailable]);

  return (
    <TableContainer component={Paper} elevation={0} square>
      <Table>
        <TableHead>
          <TableRow>
            {headers.map((header) => (
              <TableCell key={header.label} {...header.cellProps}>
                {header.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {isUpcomingSnapshotInfoAvailable && (
            <UpcomingBlendedSnapshotRow snapshot={blendedSnapshotUpcoming} />
          )}
          {snapshotsToDisplay.map((snapshot) => {
            const isSnapshotLatestUsable =
              latestUsableSnapshot?.id === snapshot.id;
            return (
              <BlendedSnapshotRow
                key={snapshot.id}
                snapshot={snapshot}
                isLatestUsable={isSnapshotLatestUsable}
                onClickDownload={onClickDownload}
              />
            );
          })}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              count={blendedSnapshots.length + +isUpcomingSnapshotInfoAvailable}
              onPageChange={(_, page) => setPage(page)}
              onRowsPerPageChange={(e) =>
                setRowsPerPage(parseInt(e.target.value, 10))
              }
              rowsPerPage={rowsPerPage}
              page={page}
              rowsPerPageOptions={[5, 10, 20]}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
};
