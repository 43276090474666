import { MeasurementAssessment } from "../../types/contracts/MeasurementAssessment";

const assessmentToFilter: string[] = [
  "Pending",
  "True Counterfeit",
  "True Authentic",
  "False Authentic",
  "False Counterfeit",
  "Wrong Model Selection",
  "Invalid Sample Presentation",
];

// Specification: https://dev.azure.com/SpectralEngines/SE%20SW/_workitems/edit/6348
const mapAssessmentToUserString = (
  assessment: MeasurementAssessment,
  isInShortForm = false
): string => {
  switch (assessment) {
    case MeasurementAssessment.PENDING:
      return isInShortForm ? "Pending" : "Pending (no change)";
    case MeasurementAssessment.TRUE_POSITIVE:
      return "True Counterfeit";
    case MeasurementAssessment.TRUE_NEGATIVE:
      return "True Authentic";
    case MeasurementAssessment.FALSE_NEGATIVE:
      return "False Authentic";
    case MeasurementAssessment.FALSE_POSITIVE:
      return "False Counterfeit";
    case MeasurementAssessment.WRONG_MODEL:
      return "Wrong Model Selection";
    case MeasurementAssessment.INVALID_SAMPLE:
      return "Invalid Sample Presentation";
    default:
      return "Unknown";
  }
};

const mapUserStringToAssessment = (value: string): MeasurementAssessment => {
  switch (value) {
    case "True Counterfeit":
      return MeasurementAssessment.TRUE_POSITIVE;
    case "True Authentic":
      return MeasurementAssessment.TRUE_NEGATIVE;
    case "False Authentic":
      return MeasurementAssessment.FALSE_NEGATIVE;
    case "False Counterfeit":
      return MeasurementAssessment.FALSE_POSITIVE;
    case "Wrong Model Selection":
      return MeasurementAssessment.WRONG_MODEL;
    case "Invalid Sample Presentation":
      return MeasurementAssessment.INVALID_SAMPLE;
    case "Pending":
    case "Pending (no change)":
    default:
      return MeasurementAssessment.PENDING;
  }
};

interface AppTheme {
  palette: {
    error: { main: string };
    success: { main: string };
    warning: { main: string };
    brandGray: { dark: string };
    text: {
      primary: string;
    };
  };
}

const mapAssessmentToColor = (
  assessment: MeasurementAssessment,
  theme: AppTheme
) => {
  switch (assessment) {
    case MeasurementAssessment.PENDING:
      return theme.palette.brandGray.dark;
    case MeasurementAssessment.TRUE_POSITIVE:
      return theme.palette.error.main;
    case MeasurementAssessment.TRUE_NEGATIVE:
      return theme.palette.success.main;
    case MeasurementAssessment.FALSE_NEGATIVE:
      return theme.palette.warning.main;
    case MeasurementAssessment.FALSE_POSITIVE:
      return theme.palette.warning.main;
    case MeasurementAssessment.WRONG_MODEL:
      return theme.palette.brandGray.dark;
    case MeasurementAssessment.INVALID_SAMPLE:
      return theme.palette.brandGray.dark;
    default:
      return theme.palette.text.primary;
  }
};

export {
  mapAssessmentToUserString,
  mapUserStringToAssessment,
  mapAssessmentToColor,
  assessmentToFilter,
};
