import { createTheme } from "@mui/material/styles";

declare module "@mui/material/TextField" {
  interface TextFieldPropsVariantOverrides {
    small: true;
  }
}

declare module "@mui/material/styles" {
  interface Palette {
    brandGray: Palette["primary"];
  }
  interface PaletteOptions {
    brandGray: PaletteOptions["primary"];
  }
}

const theme = createTheme({
  typography: {
    fontFamily: "Arial, sans-serif",
    fontSize: 14,
    h1: {
      fontSize: "2.75rem", // 44px
      fontWeight: 700,
    },
    h2: {
      fontSize: "2.125rem", // 34px
      fontWeight: 700,
    },
    h3: {
      fontSize: "1.5rem", // 24px
      fontWeight: 700,
    },
    h4: {
      fontSize: "1.125rem", // 18px
      fontWeight: 700,
    },
    h5: {
      fontSize: "1rem",
      fontWeight: 700,
    },
    h6: {
      fontSize: "1rem",
      fontWeight: 700,
    },
    button: {
      fontWeight: 700,
    },
  },
  palette: {
    text: {
      primary: "#000000",
    },
    primary: {
      main: "#0460a9",
    },
    success: {
      main: "#67aa4a",
    },
    warning: {
      main: "#bf7d1a",
    },
    error: {
      main: "#d13a32",
      light: "#fdf1f1",
    },
    background: {
      default: "#ffffff",
    },
    brandGray: {
      dark: "#9d9d9d",
      main: "#c6c6c6",
      light: "#ededed",
    },
  },
  table: {
    hover: "#0460a908",
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        html: { minHeight: "100vh" },
        body: { minHeight: "100vh" },
        "#root": {
          minHeight: "100vh",
          display: "flex",
          flexDirection: "column",
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          boxShadow: "none",
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          fontWeight: 700,
          color: "#0460a9",
          borderBottomColor: "#0460a9",
        },
        footer: {
          borderBottom: 0,
        },
        sizeMedium: {
          lineHeight: 1.5,
          padding: "14px 15px",
        },
        root: {
          borderBottom: "1px solid #c6c6c6",
          "&.MuiTableCell-paddingNone": {
            paddingBlock: 0,
          },
          "&.MuiTableCell-paddingCheckbox": {
            padding: 0,
            width: "24px",
            ".MuiCheckbox-root": {
              margin: "-9px 0 -9px 5px",
              alignSelf: "center",
            },
          },
        },
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          height: 12,
          borderRadius: 6,
        },
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
        variant: "contained",
      },
      styleOverrides: {
        contained: {
          "&, &:disabled": {
            border: "1px solid transparent",
          },
        },
        outlined: {
          "&, &:hover, &:disabled": {
            borderWidth: "2px",
          },
        },
        sizeSmall: {
          padding: "1px 10px",
        },
        root: {
          fontSize: "1rem",
          fontWeight: 700,
          textTransform: "none",
          borderRadius: 2,
          borderColor: "#0460a9",
          letterSpacing: 0,
          padding: "4px 15px",
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          minHeight: "38px",
        },
        indicator: {
          background: "transparent",
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          fontWeight: 700,
          fontSize: "1rem",
          lineHeight: 1.5,
          textTransform: "none",
          padding: "7px 15px",
          minHeight: "auto",
          minWidth: 0,
          color: "#000000",
          opacity: 1,
          "&.Mui-selected": {
            color: "inherit",
            background: "rgb(0,0,0,0.1)",
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          borderWidth: "2px",
          borderRadius: "4px",
          borderColor: "#c6c6c6",
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: "outlined",
        size: "small",
        fullWidth: true,
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          fontSize: "1.5rem",
          padding: "22px 30px 10px",
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: "30px",
          ".MuiDialogTitle-root + &&": {
            paddingTop: "5px",
          },
        },
      },
    },
    MuiDialogContentText: {
      styleOverrides: {
        root: {
          color: "#000000",
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: "0 30px 30px",
          gap: "15px",
          "& > :not(:first-of-type)": {
            marginLeft: 0,
          },
        },
      },
    },
  },
});

export default theme;
