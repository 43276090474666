import { useState } from "react";
import {
  ProductService,
  ProtocolService,
  Protocol,
  PlanningRow,
  PendingChanges,
  useError,
  useHttpErrorReader,
} from "lib-core";
import { AxiosError } from "axios";

type ProductVariantPlanningData = {
  loading: boolean;
  changeLoading: boolean;
  protocolsLoading: boolean;
  error: unknown | undefined;
  fetchProtocols(): void;
  fetchPlanningRows(variantId: number): void;
  sendProductVariantPlanningChangeRequest(
    variantId: number,
    payload: unknown
  ): Promise<void>;
  productVariantPlanningRows: PlanningRow[];
  protocols: Protocol[];
  pendingChanges: PendingChanges | undefined;
};

const useProductVariantPlanning = (): ProductVariantPlanningData => {
  const [loading, setLoading] = useState<boolean>(false);
  const [changeLoading, setChangeLoading] = useState<boolean>(false);
  const [protocolsLoading, setProtocolsLoading] = useState<boolean>(false);
  const [productVariantPlanningRows, setProductVariantPlanningRows] = useState<
    PlanningRow[]
  >([]);
  const [protocols, setProtocols] = useState<Protocol[]>([]);
  const [pendingChanges, setPendingChanges] = useState<PendingChanges>();
  const [error, setError] = useState<unknown>();

  const { handleErrors } = useError();
  const errorReader = useHttpErrorReader();

  const fetchProtocols = async () => {
    setProtocolsLoading(true);
    setError(undefined);
    try {
      const result = await ProtocolService.getProtocols();
      setProtocols(result);
      setProtocolsLoading(false);
    } catch (err) {
      const { response: errorResponse } = err as AxiosError;

      if (errorResponse) {
        const { data: error } = errorResponse;
        handleErrors({
          error,
          customHandling: errorReader(errorResponse),
        });
      }
      setProtocolsLoading(false);
      setError(err);
    }
    setProtocolsLoading(false);
    setError(undefined);
  };

  const fetchPlanningRows = async (variantId: number) => {
    setLoading(true);
    setError(undefined);
    try {
      const result = await ProductService.getProductVariantPlanning(variantId);
      setProductVariantPlanningRows(result.manufacturingSites);
      setPendingChanges(result.pendingChanges);
      setLoading(false);
    } catch (err) {
      const { response: errorResponse } = err as AxiosError;

      if (errorResponse) {
        const { data: error } = errorResponse;
        handleErrors({
          error,
          customHandling: errorReader(errorResponse),
        });
      }
      setLoading(false);
      setError(err);
    }
    setLoading(false);
    setError(undefined);
  };

  const sendProductVariantPlanningChangeRequest = async (
    variantId: number,
    payload: unknown
  ) => {
    setChangeLoading(true);
    setError(undefined);
    try {
      await ProductService.sendProductVariantPlanningChangeRequest(
        variantId,
        payload
      );
      setChangeLoading(false);
      fetchPlanningRows(variantId);
    } catch (err) {
      const { response: errorResponse } = err as AxiosError;

      if (errorResponse) {
        const { data: error } = errorResponse;
        handleErrors({
          error,
          customHandling: errorReader(errorResponse),
        });
      }
      setChangeLoading(false);
      setError(err);
    }
    setChangeLoading(false);
    setError(undefined);
  };

  return {
    loading,
    changeLoading,
    protocolsLoading,
    error,
    fetchProtocols,
    fetchPlanningRows,
    productVariantPlanningRows,
    protocols,
    pendingChanges,
    sendProductVariantPlanningChangeRequest,
  };
};

export default useProductVariantPlanning;
