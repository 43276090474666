import {
  mapMeasureModeToShortUserString,
  PredictionValue,
  StoredMeasurementHistoryItem,
  formatToDateTime,
  mapModelStatusToUserString,
  MeasurementAssessment,
} from "lib-core";

type RenderProps = {
  measurementId: string;
  product: string;
  batchNumber?: string;
  caseNumber?: string;
  manufactured?: string;
  expires?: string;
  user?: string;
  sensor: string;
  location: string;
  timestamp: string;
  modelStatus: string;
  prediction: PredictionValue;
  assessment?: MeasurementAssessment;
  assessmentUser?: string;
  assessmentTime: string;
};

interface DetailsViewMeasurementProps {
  content: StoredMeasurementHistoryItem;
  children: (props: RenderProps) => JSX.Element;
}

const MeasurementDetailsProvider: (
  props: DetailsViewMeasurementProps
) => JSX.Element = ({ content, children }) => {
  const prediction = content.metadata.authResult as PredictionValue;

  const {
    dosageForm,
    dosageStrength,
    dosageUnit,
    measureMode,
    globalBrand: productName,
    assessment,
  } = content.metadata;

  const assessmentNote = content.notes?.find(
    (note) => note.id === content.metadata.assessmentNoteId
  );

  const assessmentTime = assessmentNote?.createdOn
    ? formatToDateTime(assessmentNote?.createdOn)
    : "";

  const assessmentUser = assessmentNote?.authorPreferredUsername;

  const product = `${productName}, ${dosageForm}, ${dosageStrength}${dosageUnit.toLowerCase()}, ${mapMeasureModeToShortUserString(
    measureMode
  )}`;

  const { latitude, longitude, municipality, region, country } =
    content.metadata;

  const humanReadableLocationString = municipality
    ? `${municipality ? `${municipality},` : ""} ${
        region ? `${region},` : ""
      } ${country ? `${country}` : ""}`
    : `N ${latitude.toString().substring(0, 8)}, W ${longitude
        .toString()
        .substring(0, 8)}`;

  const timestamp = formatToDateTime(content.modifiedOn);

  const { manufacturingDate, expiryDate } = content.metadata;

  return children({
    measurementId: content.id,
    product,
    batchNumber: content?.metadata?.batchNumber,
    caseNumber: content?.metadata?.caseNumber,
    manufactured: manufacturingDate,
    expires: expiryDate,
    user: content.measurerPreferredUsername,
    sensor: content.deviceSerialNumber,
    location: humanReadableLocationString,
    timestamp,
    prediction,
    modelStatus: mapModelStatusToUserString(content.metadata.modelStatus),
    assessment,
    assessmentTime,
    assessmentUser,
  });
};

export default MeasurementDetailsProvider;
