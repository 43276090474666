import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText as MuiDialogContentText,
  DialogTitle,
} from "@mui/material";
import styled from "styled-components";

const DialogContentText = styled(MuiDialogContentText)`
  & + & {
    margin-top: 1rem;
  }
`;

interface ModalApproveProps {
  onClose: () => void;
  onSubmit: () => void;
  open: boolean;
  newCandidateName: string;
  oldCandidateName: string | null;
  state: "approve" | "reject";
}

const ModalApprove: (props: ModalApproveProps) => JSX.Element = ({
  onClose,
  onSubmit,
  open,
  newCandidateName,
  oldCandidateName,
  state,
}) => {
  return (
    <Dialog open={open} onClose={onClose}>
      {state === "approve" ? (
        <DialogTitle>Approve new model</DialogTitle>
      ) : (
        <DialogTitle>Reject new model</DialogTitle>
      )}
      <DialogContent>
        {state === "approve" ? (
          <DialogContentText>
            Are you sure you want to approve <strong>{newCandidateName}</strong>{" "}
            to be used as the model candidate?
          </DialogContentText>
        ) : (
          <DialogContentText>
            Are you sure you want to reject <strong>{newCandidateName}</strong>{" "}
            to be used as the model candidate?
          </DialogContentText>
        )}

        {oldCandidateName && state !== "reject" ? (
          <DialogContentText>
            This will also reject the current candidate{" "}
            <strong>{oldCandidateName}</strong>.
          </DialogContentText>
        ) : null}
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <Button onClick={onSubmit}>
          {state === "approve" ? "Approve" : "Reject"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ModalApprove;
