import { bytesToEncodedString } from "./bluetoothle";

const FIFO_SIZE = 20;
/**
 * Give a message, return a fifo.
 * @param message
 * @returns fifo of the message.
 */
const buildFIFO = (message: Uint8Array): string[] => {
  const tmp = [];

  // If length of message is bigger than the FIFO size
  if (message.length > FIFO_SIZE) {
    for (let i = 0; i < message.length; i += FIFO_SIZE) {
      let loopSize = FIFO_SIZE;

      if (i + FIFO_SIZE > message.length) {
        loopSize = message.length - i;
      }

      const p = {
        d: new Uint8Array(loopSize),
      };

      for (let j = 0; j < loopSize; j += 1) {
        // @ts-expect-error: TODO fix unchecked indexed access
        p.d[j] = message[i + j];
      }
      tmp.push(p);
    }
  }

  if (message.length <= FIFO_SIZE) {
    const p = { d: message };
    tmp.push(p);
  }

  const fifo = tmp.map(({ d }) => bytesToEncodedString(Array.from(d)));
  return fifo;
};

export default buildFIFO;
