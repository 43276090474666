import { TextField, TextFieldProps } from "@mui/material";
import React from "react";

interface AutocompleteInputProps {
  placeholder: string;
}

type Props = AutocompleteInputProps & TextFieldProps;

const AutocompleteInput: (props: Props) => JSX.Element = ({
  placeholder,
  ...props
}) => {
  const { inputProps, name } = props;

  return (
    <TextField
      {...props}
      inputProps={{
        ...inputProps,
        "data-testid": `filter-autocomplete-input-${name}`,
      }}
      label={placeholder}
      data-testid={`filter-autocomplete-textfield-${name}`}
    />
  );
};

export default AutocompleteInput;
