import { SaveCalibrationResultRequest } from "../../models/contracts/device/dto/measurement";
import wait from "../../lib/helper/wait";
import { Device } from "../../types/contracts/Device";
import {
  getMockDeviceBySerialNumber,
  mockCalibrationResponse,
  mockedListAllDevices,
} from "../mocks-devices";
import DeviceService from "../../services/domain/DeviceService";
import { WhiteReferenceCalibrationResponse } from "../../models/contracts/device/dto/calibration";

const saveOQCalibrationResult = async (
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  _body: SaveCalibrationResultRequest
): Promise<WhiteReferenceCalibrationResponse> => {
  await wait(1000);

  return mockCalibrationResponse;
};
saveOQCalibrationResult.query = DeviceService.saveOQCalibrationResult.query;

const savePQCalibrationResult = async (
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  _body: SaveCalibrationResultRequest
): Promise<WhiteReferenceCalibrationResponse> => {
  await wait(1000);
  return mockCalibrationResponse;
};
savePQCalibrationResult.query = DeviceService.savePQCalibrationResult.query;

const fetchDevicesMetadata = async (
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  serialNumbers: string[]
): Promise<Device[]> => {
  await wait(1000);
  return serialNumbers.map((serialNumber) =>
    getMockDeviceBySerialNumber(serialNumber)
  );
};
fetchDevicesMetadata.query = DeviceService.fetchDevicesMetadata.query;

const listRegisteredDevices = async (): Promise<string[]> => {
  const mockedDevices = await mockedListAllDevices();
  return mockedDevices.map((device) => device.serialNumber);
};
listRegisteredDevices.query = DeviceService.listRegisteredDevices.query;

export const MockDeviceService = {
  saveOQCalibrationResult,
  savePQCalibrationResult,
  fetchDevicesMetadata,
  listRegisteredDevices,
};
