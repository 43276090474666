import React from "react";
import styled from "styled-components";
import Typography from "@mui/material/Typography";

interface ErrorMessageProps {
  message: string;
}

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
`;

const Content = styled.div`
  width: 70%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const MessageContainer = styled.div`
  margin-bottom: 5rem;
`;

const ErrorMessage: (
  props: React.PropsWithChildren<ErrorMessageProps>
) => JSX.Element = ({ message, children }) => (
  <Container>
    <Content>
      <MessageContainer>
        <Typography variant="h3"> {message}</Typography>
      </MessageContainer>
      <div>{children}</div>
    </Content>
  </Container>
);

export default ErrorMessage;
