import { useDebouncedCallback } from "use-debounce";

type Callback<T> = (filters: T) => void;

interface DebounceFn<T> {
  debounce: (filterList: T) => void;
}

export const useDebounce = <T,>(
  callback: Callback<T>,
  delay: number
): DebounceFn<T> => {
  const debounce = useDebouncedCallback(callback, delay);

  return { debounce };
};
