import { createContext } from "react";
import { BluetoothDevice } from "../../types/types-bluetooth";
import { ProductDetails } from "../../types/types-product";

interface GlobalState {
  isCalibrated: boolean;
  device: BluetoothDevice | undefined;
  productDetails: ProductDetails | undefined;
  viewId: number | undefined;
}

const initialGlobalState: GlobalState = {
  isCalibrated: false,
  device: undefined,
  productDetails: undefined,
  viewId: undefined,
};

const AllStateContext = createContext(initialGlobalState);
const DispatchStateContext = createContext(undefined);

export {
  AllStateContext,
  DispatchStateContext,
  initialGlobalState,
  GlobalState,
};
