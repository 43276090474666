import React from "react";
import { createContainer } from "react-tracked";
import useValue from "./useValue";

interface GlobalStateProviderProps {
  children: React.ReactNode;
}

const { Provider, useTracked } = createContainer(useValue);

const GlobalStateProvider: (props: GlobalStateProviderProps) => JSX.Element = ({
  children,
}) => <Provider>{children}</Provider>;

export { GlobalStateProvider, useTracked as useGlobalState };
