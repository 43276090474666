import LogService from "../services/LogService";
import Constants from "../services/Constants";
import axios from "../AxiosInstance";
import Urls from "../services/Urls";

const loginUrl = process.env.REACT_APP_LOGIN_URL;
const logoutUrl = process.env.REACT_APP_LOGOUT_URL;
const clientId = process.env.REACT_APP_AD_CLIENTID;
const responseType = process.env.REACT_APP_AD_TYPE;
const scope = process.env.REACT_APP_AD_SCOPE;
const redirectHost = process.env.REACT_APP_AD_REDIRECT;

const homeUrl = process.env.REACT_APP_HOME_URL || "";
const code = process.env.REACT_APP_AUTHORIZATION_CODE_NAME || "";

const hostedUiURL = `
    ${loginUrl}?
    client_id=${clientId}
    &response_type=${responseType}
    &scope=${scope}
    &redirect_uri=${redirectHost}
`.replace(/\s/g, "");

const LogoutURL = `
    ${logoutUrl}?
    client_id=${clientId}
    &response_type=${responseType}
    &scope=${scope}
    &post_logout_redirect_uri=${homeUrl}?logout=success
`.replace(/\s/g, "");
export const accessToken: () => string = () =>
  localStorage.getItem(Constants.accessToken) || "";

export const authCode = (): string | null => {
  const url = new URL(window.location.href);
  if (url.hash) {
    // In case we use HashRouter (in desktop app for example)
    const dummyDomain = "http://example.com";
    const hashedUrl = new URL(dummyDomain + url.hash.substring(1));
    return hashedUrl.searchParams.get(code);
  }
  return url.searchParams.get(code);
};

export const clearAll: () => void = () => localStorage.clear();

export const clearTokens: () => void = () => {
  localStorage.removeItem(Constants.accessToken);
  localStorage.removeItem(Constants.refreshToken);
};

export const getDefaultHomePath = (): string => {
  return localStorage.getItem(Constants.previousPath) || "/home";
};

export const redirectToHome = (): void => {
  const previousPath = getDefaultHomePath();
  LogService.log(`HOME URL: ${homeUrl}${previousPath}`);

  window.location.href = homeUrl + previousPath;
};

export const redirectToLoginUI = (previousPath: string): void => {
  localStorage.setItem(Constants.previousPath, previousPath);
  window.location.href = hostedUiURL;
};

export const redirectToLogoutURL = (): void => {
  window.location.href = LogoutURL;
};

export const refreshToken: () => string = () =>
  localStorage.getItem(Constants.refreshToken) || "";

export const saveAccessToken: (token: string) => void = (token: string) => {
  localStorage.setItem(Constants.accessToken, token);
};

export const saveRefreshToken: (token: string) => void = (token: string) => {
  localStorage.setItem(Constants.refreshToken, token);
};

export const savePreviousPath: (previousPath: string) => void = (
  previousPath: string
) => {
  if (previousPath !== "/error-403") {
    localStorage.setItem(Constants.previousPath, previousPath);
  }
  localStorage.setItem(Constants.previousPath, "/home");
};

// This will silently exchange refreshToken to get new accessToken
// And attach new token to request header
export const forceRefreshAccessToken = (): Promise<void> =>
  new Promise<void>((resolve, reject) => {
    const rfToken = refreshToken();
    if (rfToken !== "") {
      axios
        .post(Urls.tokenRefresh(), { refreshToken: rfToken, clientId })
        .then((result) => {
          // Save new token to localStorage
          saveAccessToken(result.data.accessToken);

          resolve();
        })
        .catch(() => {
          // eslint-disable-next-line no-console
          reject(new Error("Fail to refresh accessToken"));

          if (!process.env.REACT_APP_REFRESH_TOKEN) {
            clearTokens();
          }
        });
    } else {
      reject(new Error("RefreshToken not found"));
    }
  });

// Comment this in, if you want to skip the Cognito login.
// You need to put a valid refresh token in the .env file first.
// saveRefreshToken(process.env.REACT_APP_REFRESH_TOKEN || "");
