import React from "react";
import { ProductVariant } from "../../types/types-product";

const ProductNameFormatted: (props: {
  product: ProductVariant;
  className?: string;
}) => JSX.Element = ({ product, className }) => {
  const { globalBrand, dosageForm, dosageStrength, dosageUnit, measureMode } =
    product.metadata;

  return (
    <span className={className}>
      <strong className="product-global-brand">{globalBrand}</strong>
      <span className="product-metadata">
        {`, ${dosageForm}, ${dosageStrength}${dosageUnit}, ${measureMode}`}
      </span>
    </span>
  );
};

export default ProductNameFormatted;
