import React, { useCallback, useState } from "react";
import { Button, TableCell, TableRow } from "@mui/material";
// import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
// import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {
  BlendedSnapshot,
  NotificationTypes,
  formatToDateOnly,
  useNotification,
} from "lib-core";
import styled from "styled-components";
import { failedOrUpcomingLastCol, headers } from "./blendedSnapshot.headers";

const DownloadButton = styled(Button)`
  margin-right: -15px;
  font-size: 0.8rem;
`;

const StatusAwareString = styled.span<{ status: string }>`
  cursor: text;
  color: ${({ status, theme }) => {
    switch (status) {
      case "error":
        return theme.palette.error.main;
      // 'running'
      case "done":
      default:
        return "inherit";
    }
  }};
`;

const TableRowWithStatus = styled(TableRow)<{ status: string }>`
  cursor: pointer;
  background-color: ${({ status, theme }) => {
    switch (status) {
      case "error":
        return theme.palette.error.light;
      // 'running'
      case "done":
      default:
        return "inherit";
    }
  }};
`;

type Props = {
  snapshot: BlendedSnapshot;
  isLatestUsable: boolean;
  onClickDownload: (id: string, version: number) => Promise<void>;
};

export const BlendedSnapshotRow = ({
  snapshot,
  isLatestUsable,
  onClickDownload,
}: Props): JSX.Element => {
  const [downloadingId, setDownloadingId] = useState<string | null>(null);
  // const [isExpanded, setIsExpanded] = useState(false);

  const { createNotification } = useNotification();

  const onClickDownloadWrapper = useCallback(
    (id: string, version: number) => {
      setDownloadingId(id);
      onClickDownload(id, version)
        .catch(() => {
          createNotification({
            type: NotificationTypes.Snackbar,
            severity: "error",
            message: `Failed to download the CSV, please try again`,
          });
        })
        .finally(() => {
          setDownloadingId(null);
        });
    },
    [onClickDownload, createNotification]
  );

  const VersionComponent =
    isLatestUsable || snapshot.state === "error" ? "b" : "p";
  const latestTag = ((): string => {
    if (isLatestUsable) return "— Latest";

    if (snapshot.state === "error") return "— Error";
    return "";
  })();
  const isMetadataAvailable = snapshot.state === "done";
  const isSnapshotFailed = snapshot.state === "error";

  // const onClickExpandRow = useCallback(() => {
  //   setIsExpanded((prev) => !prev);
  // }, []);

  return (
    <>
      <TableRowWithStatus
        key={snapshot.id}
        status={snapshot.state}
        sx={
          isSnapshotFailed ? { "& > *": { borderBottom: "unset" } } : undefined
        }
        // onClick={isSnapshotFailed ? onClickExpandRow : undefined}
      >
        <TableCell {...headers[0].cellProps}>
          <VersionComponent>
            <StatusAwareString status={snapshot.state}>
              V{snapshot.version}.0 {latestTag}
            </StatusAwareString>
          </VersionComponent>
        </TableCell>
        <TableCell {...headers[1].cellProps}>
          <StatusAwareString status={snapshot.state}>
            {formatToDateOnly(snapshot.createdOn)}
          </StatusAwareString>
        </TableCell>
        {snapshot.state === "done" && (
          <>
            <TableCell {...headers[2].cellProps}>
              {snapshot.measurementCount}
            </TableCell>
            <TableCell {...headers[3].cellProps}>
              {isMetadataAvailable && (
                <DownloadButton
                  variant="text"
                  disabled={downloadingId !== null}
                  onClick={() => {
                    onClickDownloadWrapper(snapshot.id, snapshot.version);
                  }}
                >
                  {downloadingId === snapshot.id
                    ? "Downloading..."
                    : "Download"}
                </DownloadButton>
              )}
            </TableCell>
          </>
        )}
        {isSnapshotFailed && (
          <TableCell {...failedOrUpcomingLastCol.cellProps}>
            <StatusAwareString status="error">
              Snapshot generation failed
              {/* TODO: Enable this snapshot failed details component after backend is ready */}
              {/* {isExpanded ? (
                <KeyboardArrowUpIcon style={{ verticalAlign: "middle" }} />
              ) : (
                <KeyboardArrowDownIcon style={{ verticalAlign: "middle" }} />
              )} */}
            </StatusAwareString>
          </TableCell>
        )}
      </TableRowWithStatus>
      {/* TODO: Enable this snapshot failed details component after backend is ready */}
      {/* {isSnapshotFailed && (
        <TableRowWithStatus status="error" style={{ borderTop: "unset" }}>
          <TableCell
            style={{ paddingBottom: 0, paddingTop: 0 }}
            {...failedSnapshotDetailCol.cellProps}
          >
            <Collapse in={isExpanded} unmountOnExit>
              <Grid container columns={12}>
                <Grid xs={4}>
                  <b>Request timestamp</b>
                </Grid>
                <Grid xs={8}>Some timestamp</Grid>
              </Grid>
              <Grid container columns={12}>
                <Grid xs={4}>
                  <b>Request ID</b>
                </Grid>
                <Grid xs={8}>Some id</Grid>
              </Grid>
              <Grid container columns={12} paddingBottom="15px">
                <Grid xs={4}>
                  <b>Error code</b>
                </Grid>
                <Grid xs={8}>500 internal server error</Grid>
              </Grid>
            </Collapse>
          </TableCell>
        </TableRowWithStatus>
      )} */}
    </>
  );
};
