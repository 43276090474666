import { KeyValueParams } from "lib-core";

export const transformUsernameInParams = (
  params: KeyValueParams,
  usernameKey: string,
  usernameMapping: Record<string, string> | undefined
): KeyValueParams => {
  return params.map((param) => {
    const { key, value } = param;
    if (key === usernameKey) {
      return {
        ...param,
        value: usernameMapping?.[value] ?? "",
      };
    }
    return param;
  });
};
