import React, { Fragment, useState } from "react";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";

interface ErrorModalProps {
  response: {
    failedRowDetails: Array<{
      rowNumber: number;
      rowContents: string;
      errorMessages: string[];
    }>;
  };
  acceptedFiles: File[];
}

const ErrorModal: (props: ErrorModalProps) => JSX.Element = ({
  acceptedFiles,
  response,
}) => {
  const [modal, setModal] = useState(false);

  const toggleModal = () => setModal(!modal);

  return (
    <>
      <Button
        onClick={toggleModal}
        variant="outlined"
        disabled={response.failedRowDetails.length === 0}
      >
        Show Errors
      </Button>
      <Dialog
        data-testid="import-csv-error-modal"
        onClose={toggleModal}
        open={modal}
        fullWidth
        maxWidth="md"
      >
        {acceptedFiles?.map((acceptedFile) => (
          <Fragment key={acceptedFile.name}>
            <DialogTitle>Import errors in {acceptedFile.name}</DialogTitle>
            <DialogContent>
              <Table aria-label="error table">
                <TableHead>
                  <TableRow>
                    <TableCell>Row number</TableCell>
                    <TableCell>Errors</TableCell>
                    <TableCell>Data</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {response?.failedRowDetails?.map((detail) => (
                    <TableRow key={detail.rowContents}>
                      <TableCell component="th" scope="row">
                        {detail.rowNumber}
                      </TableCell>
                      <TableCell>
                        {detail.errorMessages.map((errorMessage) => (
                          <p key={errorMessage}>{errorMessage}</p>
                        ))}
                      </TableCell>
                      <TableCell>{detail.rowContents}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </DialogContent>
          </Fragment>
        ))}
        <DialogActions>
          <Button onClick={toggleModal}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ErrorModal;
