// Deprecated
import React, { useState } from "react";
import styled from "styled-components";
import OutlinedInput from "@mui/material/OutlinedInput";
import { default as MuiSelect, SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FilterAltRoundedIcon from "@mui/icons-material/FilterAltRounded";

interface FilterProps {
  items: string[];
  placeholder?: string;
  selected: string;
  selectKey: (selectedKey: string) => void;
  setSearchValue: (searchInput: string) => void;
}

const Container = styled.div`
  display: flex;
  width: 100%;

  > input {
    background-color: ${({ theme }) => theme.palette.common.white};
  }
`;

const Input = styled(OutlinedInput)`
  background-color: ${({ theme }) => theme.palette.common.white};
`;

const Select = styled(MuiSelect)`
  background-color: ${({ theme }) => theme.palette.common.white};
`;

const textToKey = (key: string) => key.toLowerCase().replaceAll(" ", "_");

const KeyValueFilter: (props: FilterProps) => JSX.Element = ({
  items,
  placeholder,
  selectKey,
  setSearchValue,
  selected,
}) => {
  const [selectedKey, setSelectKey] = useState(textToKey(selected));
  const [input, setInput] = useState("");

  const updateSelectedKey = (ev: SelectChangeEvent<unknown>) => {
    const { value } = ev.target;
    setSelectKey(value as string);
    selectKey(value as string);
    setInput("");
    setSearchValue("");
  };

  const updateSearchValue = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = ev.target;
    setSearchValue(value);
    setInput(value);
  };

  const defaultKeyExists = () =>
    items.filter((item) => textToKey(item) === selectedKey).length > 0;

  const addDefaultKey = () => {
    items.unshift(selected);
  };

  if (!defaultKeyExists()) {
    addDefaultKey();
  }

  return (
    <Container>
      <FilterAltRoundedIcon style={{ fontSize: 48 }} />
      <Select
        fullWidth
        id="keyDrownDown"
        onChange={updateSelectedKey}
        value={selectedKey}
        variant="outlined"
      >
        {items.map((item) => {
          const key = textToKey(item);
          return (
            <MenuItem key={key} value={key}>
              {item}
            </MenuItem>
          );
        })}
      </Select>
      <Input
        fullWidth
        id="valueInput"
        onChange={updateSearchValue}
        placeholder={placeholder}
        sx={{ marginLeft: "9px" }}
        value={input}
      />
    </Container>
  );
};

export default KeyValueFilter;
