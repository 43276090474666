import { MockProductService } from "./product";
import { MockCurrentUser } from "./user";
import { MockDeviceService } from "./device";
import { MockProtocolService } from "./protocol";

const MockServices = {
  product: MockProductService,
  currentUser: MockCurrentUser,
  device: MockDeviceService,
  protocol: MockProtocolService,
};

export default MockServices;
