import CurrentUserService from "../../services/CurrentUserService";
import wait from "../../lib/helper/wait";
import { mockUserWithTokenExpireTime } from "../mocks-current-user";
import { UserWithTokenExpireTime } from "../../types/types-user";

const getCurrentUserWithTokenExpireTime =
  async (): Promise<UserWithTokenExpireTime> => {
    await wait(1000);

    return mockUserWithTokenExpireTime;
  };

getCurrentUserWithTokenExpireTime.query =
  CurrentUserService.getCurrentUserWithTokenExpireTime.query;

export const MockCurrentUser = {
  getCurrentUserWithTokenExpireTime,
};
