import HistoryMetadata from "../../types/contracts/HistoryMetadata";

type ModelStatus = HistoryMetadata["modelStatus"];

const mapModelStatusToUserString = (modelStatus: ModelStatus): string => {
  return (
    {
      APPROVED_CANDIDATE: "Candidate",
      APPROVED_PRODUCTION: "Released",
      NOT_APPROVED: "Not approved",
      DECOMMISSIONED: "Decommissioned",
    } as Record<ModelStatus, string>
  )[modelStatus];
};

export { mapModelStatusToUserString };
