import { ImageParams, MeasurementUserAccess } from "../types/types-api";
import { ModelApprovalData } from "../types/types-models";

const frameworkUrls = {
  userContent: (username: string): string => `/user/${username}`,
  views: (): string => `/view`,
  view: (viewId: number): string => `/view/${viewId}`,
  devices: (): string => "/device",
  device: (serialNumber: string): string => `/device/${serialNumber}`,
  userMeasurements: (username: string): string =>
    `/user-measurement/${username}`,
  userMeasurementsUniqueValues: (username: string): string =>
    `/user-measurement/${username}/unique-values`,
  measurements: (): string => "/user-measurement",
  measurementsUniqueValues: (): string => "/user-measurement/unique-values",
  userMeasurement: (username: string, measurementId: string): string =>
    `/user-measurement/${username}/${measurementId}`,
  protocols: (): string => `/protocol`,
  protocol: (protocolName: string): string => `/protocol/${protocolName}`,
  userMeasurementNote: (username: string, measurementId: string): string =>
    `user-measurement/${username}/${measurementId}/note`,
  downloadImage: (params: ImageParams): string => {
    const { username, measurementId, imageId } = params;
    return `/user-measurement/${username}/${measurementId}/image/${imageId}`;
  },
  uploadImage: (username: string, measurementId: string): string => {
    return `/user-measurement/${username}/${measurementId}/image`;
  },
  sampleById: (sampleId: number): string => {
    return `/sample/${sampleId}`;
  },
};

const applicationUrls = {
  updateUserRoles: (): string => "/ext/access-token/user",
  tokenRefresh: (): string => "/ext/access-token/refresh-token",
  tokenExchangeAuthCode: (): string => "/ext/access-token/authorization-code",
  tokenWhoami: (listPermissions = false): string =>
    `/ext/access-token/user?listPermissions=${listPermissions}`,
  tokenWhoamiWithExpireTime: (): string =>
    "/ext/access-token/user?checkExpirationTime=true",
  extAuditTrailExportToCsv: (): string => "/ext/audit-trail/export-to-csv",
  extAuditTrailExportToCsvPrediction: (): string =>
    "/ext/audit-trail/export-to-csv-prediction",
  extProductVariant: (): string => "/ext/product-variant",
  extProductVariantBlendedSnapshotListing: (protocolName: string): string =>
    `/ext/product-variant/blended-snapshot/${protocolName}`,
  extProductVariantBlendedSnapshotUpcoming: (protocolName: string): string =>
    `/ext/product-variant/blended-snapshot/${protocolName}/upcoming`,
  extBlendedSnapshotMetadataDownload: (id: string): string =>
    `/ext/product-variant/blended-snapshot/${id}/metadata`,
  extProductVariantDetail: (variantId: number): string =>
    `/ext/product-variant/${variantId}`,
  extProductVariantVersions: (variantId: number): string =>
    `/ext/product-variant/${variantId}/versions`,
  extProductVariantByProtocol: (protocol: string): string =>
    `/ext/product-variant?protocol=${protocol}`,
  extProductVariantById: (variantId: number): string =>
    `/ext/product-variant/${variantId}`,
  extProductVariantDetailById: (variantId: number): string =>
    `/ext/product-variant/${variantId}?expanded=true`,
  extProductVariantPlanning: (variantId: number): string =>
    `/ext/product-variant/${variantId}/planning`,
  extProductVariantResults: (variantId: number): string =>
    `/ext/product-variant/${variantId}/results`,
  extProductVariantSnapshots: (variantId: number): string =>
    `/ext/product-variant/${variantId}/snapshot`,
  cancelModelTrainingRun: (viewId: number): string =>
    `/ext/model/run/cancel?viewId=${viewId}`,
  productVariantModels: (variantId: number): string =>
    `/ext/product-variant/${variantId}/models`,
  startMeasurementSession: (
    variantId: number,
    dataSetId: number,
    totalNumberOfIntendedMeasurements: number
  ): string =>
    `/ext/product-variant/${variantId}/data-set/${dataSetId}/measurement-session?totalNumberOfIntendedMeasurements=${totalNumberOfIntendedMeasurements}`,
  saveMeasurement: (
    variantId: number,
    dataSetId: number,
    closeOnSave = false
  ): string =>
    `/ext/product-variant/${variantId}/data-set/${dataSetId}/measurements?closeOnSave=${closeOnSave}`,
  libraryImportCsv: (): string => "/ext/product-variant/import-from-csv",
  extProductVariantTrainings: (variantId: number): string =>
    `/ext/product-variant/${variantId}/models`,
  extModelRun: (
    productVariantSnapshot: string,
    selectedPlatform: string,
    noWhiteReference: boolean,
    blendedSnapshot?: string
  ): string => {
    const urlWithoutBlendedSnapshot = `/ext/model/run?snapshot=${productVariantSnapshot}&platform=${selectedPlatform}&useWhiteReference=${
      noWhiteReference ? "false" : "true"
    }`;
    if (blendedSnapshot) {
      return `${urlWithoutBlendedSnapshot}&blendedSnapshot=${blendedSnapshot}`;
    }
    return urlWithoutBlendedSnapshot;
  },
  allDevices: (): string => "/ext/device",
  deviceBySerialNumber: (serialNumber: string): string =>
    `/ext/device/${serialNumber}`,
  devicesExt: (): string => "/ext/device",
  deviceExt: (serialNumber: string): string => `/ext/device/${serialNumber}`,
  deviceExtProtocol: (protocol: string): string =>
    `/ext/device/protocol/${protocol}`,
  deviceExtProtocols: (): string => "/ext/device/protocol",
  saveSpectralReference: (serialNumber: string, protocolName: string): string =>
    `/ext/device/${serialNumber}/spectral-reference/${protocolName}`,
  getPQExpireData: (serialNumber: string, protocolName: string): string =>
    `/ext/device/${serialNumber}/spectral-reference/${protocolName}/expires`,
  updateDevice: (serialNumber: string, status: string): string =>
    `/ext/device/${serialNumber}/status/${status}`,
  updateDeviceStatus: (serialNumber: string, status: string): string =>
    `/ext/device/${serialNumber}/update-status/${status}`,
  devicesExtDetailBySerialNumbers: (queryStr: string): string =>
    `/ext/device?expanded=true${queryStr}`,
  saveOQCalibration: (
    serialNumber: string,
    protocolName: string,
    isLabscanner = false
  ) =>
    `/ext/device/${serialNumber}/white-reference/${protocolName}?labScanner=${isLabscanner}`,
  importFromCsv: (): string => "/ext/device/import-from-csv",
  exportLibraryCsv: (): string => "/ext/product-variant/export-to-csv",
  drugAuthentications: (): string => `/ext/drug-authentication`,
  drugAuthenticationsUniqueValues: (): string =>
    `/ext/drug-authentication/unique-values`,
  drugAuthentication: (measurementId: string): string =>
    `/ext/drug-authentication/${measurementId}`,
  drugAuthenticationSpectra: (measurementId: string): string =>
    `/ext/drug-authentication/${measurementId}/plot`,
  getManufacturingMetadataCsv: (metadataUrl: string): string => metadataUrl,
  exportMeasurementHistoryCsv: (): string =>
    `/ext/drug-authentication/export-to-csv`,
  saveWhiteReference: (serialNumber: string, protocolName: string): string =>
    `/ext/device/${serialNumber}/white-reference/${protocolName}`,
  getWhiteReference: (serialNumber: string, protocolName: string): string =>
    `/ext/device/${serialNumber}/white-reference/${protocolName}`,
  getExpireData: (serialNumber: string, protocolName: string): string =>
    `/ext/device/${serialNumber}/white-reference/${protocolName}/expires`,
  downloadImage: (params: ImageParams): string => {
    const { username, measurementId, imageId } = params;
    return `/ext/drug-authentication/user/${username}/${measurementId}/image/${imageId}`;
  },
  uploadImage: (username: string, measurementId: string): string => {
    return `/ext/drug-authentication/user/${username}/${measurementId}/image`;
  },
  addMeasurementAssessment: (params: MeasurementUserAccess): string => {
    const { username, measurementId } = params;
    return `/ext/drug-authentication/user/${username}/${measurementId}/assessment`;
  },
  getAllViews: (): string => `/ext/view`,
  getViewById: (viewId: number): string => `/ext/view/${viewId}`,
  singleUserMeasurementByUsername: (
    username: string,
    measurementId: string
  ): string => `/ext/drug-authentication/user/${username}/${measurementId}`,
  userMeasurementsByUsername: (username: string): string =>
    `/ext/drug-authentication?username=${username}`,
  userMeasurementsUniqueValuesByUsername: (username: string): string =>
    `/ext/drug-authentication/user/${username}/unique-values`,
  userMeasurementNote: (username: string, measurementId: string): string =>
    `/ext/drug-authentication/user/${username}/${measurementId}/note`,
  approveModel: (modelApprovalData: ModelApprovalData): string =>
    `/ext/model/${modelApprovalData.modelName}/version/${modelApprovalData.modelVersionId}/not-approved-to-candidate`,
  approveCandidateModel: (modelApprovalData: ModelApprovalData): string =>
    `/ext/model/${modelApprovalData.modelName}/version/${modelApprovalData.modelVersionId}/candidate-to-production`,
  rejectModel: (modelApprovalData: ModelApprovalData): string =>
    `/ext/model/${modelApprovalData.modelName}/version/${modelApprovalData.modelVersionId}/reject`,
  decommissionModel: (modelApprovalData: ModelApprovalData): string =>
    `/ext/model/${modelApprovalData.modelName}/version/${modelApprovalData.modelVersionId}/decommission`,
  getModelPlatforms: (): string => `/ext/model/platform`,
  downloadModelTrainingLogs: (modelName: string, runId: string): string =>
    `/ext/model/${modelName}/run/${runId}/logs`,
};

const Urls = {
  ...frameworkUrls,
  ...applicationUrls,
};

export default Urls;
