import format from "date-fns/format";
import {
  CellTypes,
  CoordsValue,
  LastUseValue,
  TableDataArray,
  TableDataRow,
  DataTableHeaderType,
} from "./datatable-types";

interface FilterDataOptions<T> {
  headers: DataTableHeaderType[];
  rows: TableDataArray<T>;
  selectedKey: string;
  searchValue: string;
}

export const filter = <T>(options: FilterDataOptions<T>): TableDataArray<T> => {
  const { headers, rows, selectedKey, searchValue } = options;

  const matchKeys = (header: string) => {
    return header.toLowerCase() === selectedKey.replaceAll("_", " ");
  };

  const findIndex = () => headers.map((h) => h.label).findIndex(matchKeys);

  if (selectedKey === "" || searchValue === "") {
    return rows;
  }

  const index = findIndex();
  return rows.filter((row: TableDataRow<T>) => {
    const needle = searchValue.toLowerCase();

    switch (row.row[index]?.type) {
      case CellTypes.COORDS: {
        const target = row.row[index]?.value as CoordsValue;
        return target.north?.includes(needle) || target.west?.includes(needle);
      }
      case CellTypes.DATETIME: {
        const date = format(row.row[index]?.value as Date, "y-M-d H:m");
        return date.includes(needle);
      }
      case CellTypes.EXPIRED: {
        return false;
      }
      case CellTypes.LASTUSE: {
        const user = (row.row[index]?.value as LastUseValue).user.toLowerCase();
        const date = format(
          (row.row[index]?.value as LastUseValue).date,
          "y / M / d"
        );
        return date.includes(needle) || user.includes(needle);
      }
      default: {
        const target = (row.row[index]?.value as string).toLowerCase();
        return target.includes(needle);
      }
    }
  });
};
