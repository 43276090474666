import { z } from "zod";
import {
  AuditTrailDatabaseService,
  AuditTrailFileSizeUnit,
} from "./auditTrailConstants";

const AuditTrailPredictionQuerySchema = z.object({
  timeMin: z.string(),
  timeMax: z.string(),
  username: z.nullable(z.string()),
  services: z.array(z.nativeEnum(AuditTrailDatabaseService)),
});

export const AuditTrailPredictionResultSchema = z.object({
  query: AuditTrailPredictionQuerySchema,
  rowCount: z.number(),
  diskSpace: z.object({
    value: z.number(),
    unit: z.nativeEnum(AuditTrailFileSizeUnit),
  }),
});
