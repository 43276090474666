import React from "react";
import styled from "styled-components";
import { default as MuiStack } from "@mui/material/Stack";
import { MeasurementAssessment, Note } from "lib-core";
import DetailsViewNoteItem from "./DetailsViewNoteItem";

interface DetailsViewNotesProps {
  notes: Note[];
  assessmentNoteId: number | null;
  assessment: MeasurementAssessment | undefined;
  renderNoteInput: (header: string) => JSX.Element;
}

const Container = styled.div`
  grid-area: notes;
  padding: 24px 30px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const Stack = styled(MuiStack)`
  align-items: flex-start;
`;

const NoteItems = styled.div`
  overflow-x: hidden;
  overflow-y: auto;
  flex: 1 0 0;
  margin-right: -12px;
  padding-right: 12px;
`;

const DetailsViewNotes: (props: DetailsViewNotesProps) => JSX.Element = ({
  notes,
  assessmentNoteId,
  assessment,
  renderNoteInput,
}) => {
  const numberOfNotes = notes.length;

  return (
    <Container>
      <Stack spacing={1}>{renderNoteInput(`Notes (${numberOfNotes})`)}</Stack>
      <NoteItems>
        {notes.map((note: Note) => (
          <DetailsViewNoteItem
            key={note.id + note.createdOn}
            item={note}
            isAssessmentNote={note.id === assessmentNoteId}
            assessment={assessment}
          />
        ))}
      </NoteItems>
    </Container>
  );
};

export default DetailsViewNotes;
