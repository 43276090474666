import React, { useEffect } from "react";
import styled from "styled-components";
import { Button } from "@mui/material";
import {
  ProductVariant,
  ResultsRow,
  ResultsRowStatus,
  formatToDateOnly,
  PermissionKeys,
  PermissionRequired,
} from "lib-core";
import LibraryTable, {
  LibraryTableHeaders,
} from "../components/LibraryTable/LibraryTable";
import ProductVariantSnapshotBox from "../components/snaphostBoxes/ProductVariantSnapshot";
import TestCoverage from "../components/TestCoverage";
import useProductVariantResults from "./productVariantResults-hook";
import { testResultHeaders, TestResultWithButton } from "./helpers";
import { TableRowSet } from "../components/LibraryTable/LibraryTableBody";
import LibrarySubheader from "./LibrarySubheader";
import LoadingIndicator from "../components/LoadingIndicator/LoadingIndicator";
import BlendedSnapshotBox from "../components/snaphostBoxes/BlendedSnapshotBox";

const FooterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 2rem;
`;

const HeaderColspan = 11;

interface ResultsTabProps {
  productVariant: ProductVariant;
}

const { readProductLibrary, readAuthentifieldDummyProduct } = PermissionKeys;

const ResultsTab: (props: ResultsTabProps) => JSX.Element = ({
  productVariant,
}) => {
  const {
    fetchResults,
    downloadManufacturingCsv,
    loadingResults,
    results,
    csvIndex,
    csvRequestBusy,
  } = useProductVariantResults();

  useEffect(() => {
    fetchResults(productVariant.id);
  }, [productVariant]); // eslint-disable-line react-hooks/exhaustive-deps

  const downloadButton = (
    metadataUrl: string,
    manufacturingSite: string,
    rowIndex: number
  ) => {
    const productIsDummy = productVariant?.metadata.dummyVariant;

    const key = productIsDummy
      ? readAuthentifieldDummyProduct
      : readProductLibrary;

    return (
      <PermissionRequired permissionKeys={[key]}>
        {csvIndex === rowIndex ? (
          <LoadingIndicator />
        ) : (
          <Button
            disabled={csvRequestBusy}
            variant="outlined"
            size="small"
            onClick={() =>
              downloadManufacturingCsv(metadataUrl, manufacturingSite, rowIndex)
            }
          >
            Download
          </Button>
        )}
      </PermissionRequired>
    );
  };

  const getRows = (rows: ResultsRow[]) =>
    rows.map((resultsRow, rowIndex) => {
      const { firstMeasurementAt, manufacturingSite, metadataUrl } = resultsRow;

      return {
        ...resultsRow,
        date: firstMeasurementAt ? formatToDateOnly(firstMeasurementAt) : "",
        metadata: downloadButton(metadataUrl, manufacturingSite, rowIndex),
      };
    });

  const resultsRowStatuses = [
    [ResultsRowStatus.Active, "Active"],
    [ResultsRowStatus.Inactive, "Inactive"],
  ];

  const mappedResults = (results.rows ?? []).map((row) => ({
    ...row,
  }));

  const rowSets: TableRowSet<TestResultWithButton>[] = resultsRowStatuses.map(
    ([status, title]) => {
      const rows = mappedResults.filter(
        (row: ResultsRow) => row.status === status
      );
      return {
        label: [
          {
            label: `${title} (${rows.length})`,
            colspan: HeaderColspan,
          },
        ] as LibraryTableHeaders<Record<string, number>>,
        rows: getRows(rows),
      };
    }
  );

  return (
    <>
      <LibrarySubheader
        loadingTitle="Loading test results..."
        title={
          results?.rows?.length === 1
            ? "Test results from 1 manufacturing site"
            : `Test results from ${results?.rows?.length} manufacturing sites`
        }
      />

      <LibraryTable
        loading={loadingResults}
        headers={testResultHeaders}
        rowSets={rowSets}
      />

      <FooterContainer>
        <TestCoverage
          testCompletionRate={results.testCompletionRate || 0}
          testCoverageRate={results.testCoverageRate || 0}
        />
        <BlendedSnapshotBox />
        <ProductVariantSnapshotBox
          productVariantId={productVariant.id}
          results={results}
        />
      </FooterContainer>
    </>
  );
};

export default ResultsTab;
