import React from "react";
import styled from "styled-components";
import { CircularProgress } from "@mui/material";

const IndicatorContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.675rem;
  font-size: 0.875rem;
  color: ${({ theme }) => theme.palette.primary.main};
`;

const LoadingIndicator: () => JSX.Element = () => (
  <IndicatorContainer>
    <CircularProgress size={12} color="inherit" />
    <span>Loading...</span>
  </IndicatorContainer>
);

export default LoadingIndicator;
