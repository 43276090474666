import React from "react";
import { Typography } from "@mui/material";
import styled from "styled-components";

const Container = styled.main`
  display: flex;
  flex-direction: column;
  width: 280px;
  background: ${({ theme }) => theme.palette.brandGray.light};
  padding: 20px;
`;
const TitleBox = styled.div`
  width: 100%;
`;
const TextContainer = styled.div`
  width: 100%;
`;

interface TestCoverageProps {
  testCompletionRate: number;
  testCoverageRate: number;
}

const TestCoverage: (props: TestCoverageProps) => JSX.Element = ({
  testCompletionRate,
  testCoverageRate,
}) => {
  const parsedTestCompletionRate = (testCompletionRate * 100).toFixed(0);
  const parsedTestCoverageRate = (testCoverageRate * 100).toFixed(0);

  return (
    <Container>
      <TitleBox>
        <Typography variant="h4">Test coverage</Typography>
      </TitleBox>
      <TextContainer>
        <Typography variant="body1">
          {parsedTestCompletionRate &&
            `${parsedTestCompletionRate}% completion rate`}
        </Typography>
        <Typography variant="body1">
          {parsedTestCoverageRate && `${parsedTestCoverageRate}% coverage rate`}
        </Typography>
      </TextContainer>
    </Container>
  );
};

export default TestCoverage;
