import { AxiosResponse } from "axios";
import { NotificationTypes } from "../components/notifications/notification-types";
import useNotification from "../components/notifications/hook";

type AccessErrorHandler = () => void;
type ErrorHandler = (messages: string[]) => void;

type AccessErrorReader = (
  httpResponse: AxiosResponse
) => AccessErrorHandler | ErrorHandler;

const useHttpErrorReader = (): AccessErrorReader => {
  const notification = useNotification();

  const accessErrorStatusCode = 403;
  const accessErrorStatusText = "Authorization failed";
  const accessErrorMessage = "Authorization checks failed!";

  const errorMatch403 = (statusCode: number, statusText: string) => {
    if (
      accessErrorStatusCode === statusCode &&
      statusText?.includes(accessErrorStatusText)
    ) {
      return true;
    }

    return false;
  };

  const showAccessError = () => {
    const severity = "error";
    const type = NotificationTypes.Snackbar;

    notification.createNotification({
      message: accessErrorMessage,
      showClose: true,
      severity,
      type,
    });
  };

  const showError = (messages: string[]) => {
    const severity = "error";
    const type = NotificationTypes.Snackbar;

    notification.createNotification({
      message: messages[0] as string,
      showClose: true,
      severity,
      type,
    });
  };

  const errorReader = (
    httpResponse: AxiosResponse
  ): AccessErrorHandler | ErrorHandler => {
    const { data, status } = httpResponse;
    const { detail } = data;

    if (errorMatch403(status, detail)) {
      return showAccessError;
    }

    return showError;
  };

  return errorReader;
};

export { useHttpErrorReader };
