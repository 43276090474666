import { Typography } from "@mui/material";
import React from "react";
import styled from "styled-components";

interface GuideStepProps {
  number: number;
  title: string;
  children?: React.ReactNode;
}

const Container = styled("div")`
  display: grid;
  grid-template:
    "number title" max-content
    "number text" max-content / 30px 1fr;
  gap: 8px 18px;
  max-width: 24rem;
`;

const Number = styled(Typography)`
  background: ${({ theme }) => theme.palette.primary.main};
  color: ${({ theme }) => theme.palette.background.default};
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  padding-top: 1px;
`;

const Title = styled(Typography)`
  grid-area: title;
  padding-top: 4px;
  text-transform: capitalize;
`;

const Text = styled(Typography)`
  grid-area: text;
  padding-bottom: 12px;
`;

const GuideStep: (props: GuideStepProps) => JSX.Element = ({
  number,
  title,
  children,
}) => (
  <Container>
    <Number variant="h4">{number}</Number>
    <Title variant="h4">{title}</Title>
    <Text>{children}</Text>
  </Container>
);

export default GuideStep;
