import React from "react";
import styled from "styled-components";
import { Button as MuiButton } from "@mui/material";

const Button = styled(MuiButton)`
  height: 40px;
  border-radius: 0;
  text-transform: none;
`;

const normalizeString = (string = "") => string.trim().replace(/\s/g, " ");
const getElementText = ({
  element,
}: {
  element: HTMLElement | Element | HTMLSelectElement;
}): string => {
  if (element.nodeName === "SELECT") {
    return normalizeString(
      (element as HTMLSelectElement).options[
        (element as HTMLSelectElement).selectedIndex
      ]?.text
    );
  }
  const { children, innerText } = element as HTMLElement;
  if (children.length > 0) {
    return Array.from(children).reduce<string>(
      (text, child) =>
        normalizeString(`${text} ${getElementText({ element: child })}`),
      ""
    );
  }
  return normalizeString(innerText);
};

export const getTableData = (tableId: string): string[][] => {
  const tableElement: HTMLTableElement = document.getElementById(
    tableId
  ) as HTMLTableElement;

  if (!tableElement) {
    return [];
  }
  const columns: string[][] = [];
  const tBodies = Array.from(tableElement.tBodies);

  const rows = tBodies.flatMap((tBody) => Array.from(tBody.rows));
  rows.forEach((row: HTMLTableRowElement, rowIndex: number) => {
    columns[rowIndex] = Array.from(row.cells).map(
      (column: HTMLTableCellElement) => {
        return getElementText({ element: column });
      }
    );
  });
  return columns;
};

const createCSV = (data: string[][], id: string) => {
  let csv = "";
  data.forEach((row) => {
    csv += row.join(",");
    csv += "\n";
  });

  const hiddenElement = document.createElement("a");
  hiddenElement.href = `data:text/csv;charset=utf-8,${encodeURI(csv)}`;
  hiddenElement.target = "_blank";
  hiddenElement.download = `${id}.csv`;
  hiddenElement.click();
};

type ExportCSVButtonProps = {
  tableId: string;
};

const ExportCSVButton: (props: ExportCSVButtonProps) => JSX.Element = ({
  tableId,
}) => {
  const onClick = () => {
    const tableData = getTableData(tableId);
    createCSV(tableData, tableId);
  };
  return (
    <Button
      data-testid="export-csv-button"
      onClick={onClick}
      size="large"
      variant="contained"
    >
      CSV Export
    </Button>
  );
};

export default ExportCSVButton;
