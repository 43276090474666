import {
  scan,
  createConnection,
  setParameters,
  measure,
  disconnect,
  readDeviceInfo,
} from "../../lib/ble";
import {
  startIosBluetoothStateUpdates,
  stopIosBluetoothStateUpdates,
} from "../../lib/ble/permission";
import { getDeviceRssi, isDeviceConnected } from "../../lib/ble/scan";

const BluetoothService = {
  createConnection,
  disconnect,
  getDeviceRssi,
  isDeviceConnected,
  measure,
  readDeviceInfo,
  scan,
  setParameters,
  startIosBluetoothStateUpdates,
  stopIosBluetoothStateUpdates,
};

export default BluetoothService;
