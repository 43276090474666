import { useEffect, useState } from "react";
import getDeviceInfo from "../services/bluetooth/DeviceInfoService";
import Constants from "../services/Constants";
import LogService from "../services/LogService";
import { DeviceInfo } from "../types/types-bluetooth";

interface DeviceInformation {
  deviceInfo: DeviceInfo | undefined | void;
  errorMessage: string;
}

const useDeviceInfo = (): DeviceInformation => {
  const [deviceInfo, setDeviceInfo] = useState<DeviceInfo | void>();
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    const fetchDeviceInfo = async () => {
      const selectedDevice = JSON.parse(
        localStorage.getItem(Constants.persistSelectedDevice) as string
      );

      if (selectedDevice) {
        const deviceInfo = await getDeviceInfo(selectedDevice.address).catch(
          (e) => {
            LogService.warn("Error fetching device info: ", e);
            setErrorMessage("Error fetching device info");
          }
        );

        setDeviceInfo(deviceInfo);
      }
    };

    if (!deviceInfo) {
      fetchDeviceInfo();
    }
  });

  return { deviceInfo, errorMessage };
};

export default useDeviceInfo;
