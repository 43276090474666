import React from "react";
import { css, ListItemButton, styled } from "@mui/material";
import { useHistory } from "react-router-dom";
import KeyValueCellContent from "./KeyValueCellContent";
import { KeyValueCellProps } from "./mobiletable-types";

const bodyPadding = { x: "1.25rem", y: "0.75rem" };
const bodySpacing = { x: "1rem", y: "0.5rem" };
const headerPadding = { x: bodyPadding.x, y: "0.5rem" };

const cellBase = css`
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${bodySpacing.y} ${bodySpacing.x};
`;

const bodyCellBase = css`
  padding: ${bodyPadding.y} ${bodyPadding.x};
  color: inherit;
`;

const ActionCell = styled(ListItemButton)`
  ${cellBase}
  ${bodyCellBase}
`;

const StaticCell = styled("div")`
  ${cellBase}
  ${bodyCellBase}
`;

const HeaderCell = styled("div")`
  ${cellBase}
  padding: ${headerPadding.y} ${headerPadding.x};
`;

const KeyValueCell: (props: KeyValueCellProps) => JSX.Element = ({
  item,
  arrowIcon,
}) => {
  const history = useHistory();
  const itemClass = (item.type !== "custom" && item.className) || "";

  switch (item.type) {
    case "custom":
      return item.element;

    case "link":
    case "action": {
      const onClick = () =>
        item.type === "link"
          ? item.route && history.push(item.route)
          : item.action();
      return (
        <ActionCell
          className={`MobileTable-cell MobileTable-cell-${item.type} ${itemClass}`}
          onClick={onClick}
          disabled={item.disabled}
        >
          <KeyValueCellContent
            itemKey={item.key}
            itemValue={item.value}
            arrowIcon={arrowIcon}
            showArrow={item.showArrow}
          />
        </ActionCell>
      );
    }

    case "header":
      return (
        <HeaderCell
          className={`MobileTable-cell MobileTable-cell-header ${itemClass}`}
        >
          <KeyValueCellContent itemKey={item.key} itemValue={item.value} />
        </HeaderCell>
      );

    case "static":
    default:
      return (
        <StaticCell
          className={`MobileTable-cell MobileTable-cell-static ${itemClass}`}
        >
          <KeyValueCellContent itemKey={item.key} itemValue={item.value} />
        </StaticCell>
      );
  }
};

export default KeyValueCell;
