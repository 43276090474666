import React, { useEffect, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  createFilter,
  createAutocompleteFilter,
  DataFilter,
  DataTable,
  FilterTypes,
  Hover,
  KeyValueParams,
  PermissionKeys,
  StoredMeasurementHistoryItem,
  TableSnapShot,
  usePermissionChecking,
  mapUserStringToAssessment,
} from "lib-core";
import { Button } from "@mui/material";
import Page from "../components/Layout/Page";
import { PageContent, PageSidebar } from "../components/Layout/PageContent";
import Header from "../components/Layout/Header";
import useMeasurementHistory, {
  useMeasurementUniqueValues,
  userFriendlyModelStatusToValue,
} from "./history-hook";
import HistoryDetails from "./HistoryDetails";
import { downloadFile } from "../shared/file-helpers";
import { transformUsernameInParams } from "../shared/preferred-usernames-helpers";

const historyCsvRowsLimit = 1000;

const { readMeasurementDetails, readMeasurementHistory, readWebPortal } =
  PermissionKeys;

const filterValue = (filterValues: KeyValueParams, filterName: string) =>
  filterValues.find((filter) => filter.key === filterName)?.value;

const transformModelStatusInParams = (params: KeyValueParams) => {
  return params.map((param) => {
    const { key, value } = param;
    if (key === "modelStatus") {
      return {
        ...param,
        value: userFriendlyModelStatusToValue[value] as string,
      };
    }
    return param;
  });
};

const transformAssessmentInParams = (params: KeyValueParams) => {
  return params.map((param) => {
    const { key, value } = param;
    if (key === "assessment") {
      return {
        ...param,
        value: value ? mapUserStringToAssessment(value) : "",
      };
    }
    return param;
  });
};

const HistoryHomeView: () => JSX.Element = () => {
  const {
    measurementHistory,
    headers,
    fetchHistory,
    pagination,
    exportCsvMeasurementHistory,
    loading,
  } = useMeasurementHistory();

  const history = useHistory();
  const { search } = useLocation();
  const [perPage, setPerPage] = useState(10);
  const [filterOptionRefreshKey, setFilterOptionRefreshKey] = useState(0);
  const [rawFilterValues, setFilterValues] = useState<KeyValueParams>([]);

  const { filterOptions } = useMeasurementUniqueValues(filterOptionRefreshKey);

  const filterValuesForDisplay = useMemo(() => {
    const usernameTransformed = transformUsernameInParams(
      rawFilterValues,
      "username",
      filterOptions?.usernameMapping
    );
    return transformAssessmentInParams(usernameTransformed);
  }, [rawFilterValues, filterOptions]);

  const transformFilterValuesForApi = (param: KeyValueParams) => {
    const modelStatusTransformed = transformModelStatusInParams(param);
    const assessmentTransformed = transformAssessmentInParams(
      modelStatusTransformed
    );

    return transformUsernameInParams(
      assessmentTransformed,
      "username",
      filterOptions?.usernameMapping
    );
  };

  const requirePermission = usePermissionChecking();

  const openDetailsPermission = requirePermission([readMeasurementDetails]);
  const exportMCsvPermission = requirePermission([readMeasurementHistory]);
  const allowReadMeasurementHistory = requirePermission([
    readMeasurementHistory,
    readWebPortal,
  ]);

  useEffect(() => {
    if (allowReadMeasurementHistory === false) {
      history.replace("/error-403");
    }
  }, [allowReadMeasurementHistory, history]);

  const readSearchParams = () => {
    const params = new URLSearchParams(search);
    return Object.fromEntries(params);
  };

  const redirectToModal = <T,>(data: T, snapshot: TableSnapShot) => {
    // eslint-disable-next-line prettier/prettier
    const {
      id,
      measurerUsername,
      // eslint-disable-next-line prettier/prettier
    } = data as unknown as StoredMeasurementHistoryItem;

    const { rowsOnPage, page, sorting } = snapshot;

    const queryString = new URLSearchParams({
      id,
      measurerUsername,
      rowsOnPage,
      page,
      sorting: sorting.join(","),
    }).toString();

    history.push(`?${queryString}&openDetails=true`);
  };

  const onFilterUpdate = (params: KeyValueParams) => {
    fetchHistory(0, perPage, transformFilterValuesForApi(params));
    setFilterValues(params);
  };

  const params = readSearchParams();

  const openDetails = !!params.openDetails && openDetailsPermission;

  useEffect(() => {
    // After user may edit status of a measurement, the filter options may change and we
    // need to reload the filter options again
    setFilterOptionRefreshKey((prev) => prev + 1);
  }, [openDetails]);

  const filters = useMemo(
    () => [
      createAutocompleteFilter({
        name: "globalBrand",
        placeholder: "Product",
        options: filterOptions?.metadataValues?.globalBrand ?? [],
        value: filterValue(filterValuesForDisplay, "globalBrand"),
      }),
      createAutocompleteFilter({
        name: "batchNumber",
        placeholder: "Batch number",
        options: filterOptions?.metadataValues?.batchNumber ?? [],
        value: filterValue(filterValuesForDisplay, "batchNumber"),
      }),
      createAutocompleteFilter({
        name: "caseNumber",
        placeholder: "Case number",
        options: filterOptions?.metadataValues?.caseNumber ?? [],
        value: filterValue(filterValuesForDisplay, "caseNumber"),
      }),
      createAutocompleteFilter({
        name: "modelStatus",
        placeholder: "Model",
        options: filterOptions?.metadataValues?.modelStatus ?? [],
        value: filterValue(filterValuesForDisplay, "modelStatus"),
      }),
      createAutocompleteFilter({
        name: "authResult",
        placeholder: "Result",
        options: filterOptions?.metadataValues?.authResult ?? [],
        value: filterValue(filterValuesForDisplay, "authResult"),
      }),
      createAutocompleteFilter({
        name: "assessment",
        placeholder: "Assessment",
        options: filterOptions?.metadataValues?.assessment ?? [],
        value: filterValue(filterValuesForDisplay, "assessment"),
      }),
      createAutocompleteFilter({
        name: "username",
        placeholder: "Measurer",
        // User won't be able to read the random string as username
        // so we use preferred username, which is email address as the value displayed
        options: filterOptions?.values?.measurerPreferredUsername ?? [],
        value: filterValue(filterValuesForDisplay, "measurerPreferredUsername"),
      }),
      createAutocompleteFilter({
        name: "deviceSerialNumber",
        placeholder: "Sensor",
        options: filterOptions?.values?.deviceSerialNumber ?? [],
        value: filterValue(filterValuesForDisplay, "deviceSerialNumber"),
      }),
      createFilter(
        "startTime",
        "From time",
        FilterTypes.DateTime,
        filterValue(filterValuesForDisplay, "startTime")
      ),
    ],
    [filterOptions, filterValuesForDisplay]
  );

  const resetMeasurementHistory = () => {
    // eslint-disable-next-line prettier/prettier
    const page = params.page as unknown as number;
    // eslint-disable-next-line prettier/prettier
    const perPage = params.perPage as unknown as number;
    fetchHistory(page, perPage);
  };

  const exportCSV = async () => {
    const csv = await exportCsvMeasurementHistory(
      transformFilterValuesForApi(rawFilterValues)
    );
    const url = `data:text/csv;charset=utf-8,${encodeURI(csv)}`;
    const fileName = "measurements.csv";

    downloadFile(fileName, url);
  };

  const hoverTooltipText = (rowsTotal: number | undefined) => {
    if (!rowsTotal || rowsTotal <= historyCsvRowsLimit) {
      return "";
    }
    return `Rows in file are limited to ${historyCsvRowsLimit} rows`;
  };

  const exportRowsBtnText = (rowsTotal: number | undefined) => {
    let rows = "";
    if (!rowsTotal) {
      rows = "-";
    } else if (rowsTotal <= historyCsvRowsLimit) {
      rows = rowsTotal.toString();
    } else {
      rows = historyCsvRowsLimit.toString();
    }
    return `Export CSV with ${rows} results`;
  };

  const disableCsvExport = () => !pagination || !exportMCsvPermission;

  return (
    <>
      <Header title="Measurement History">
        <Hover tooltipText={hoverTooltipText(pagination?.total)}>
          <Button
            data-testid="export-csv-button"
            disabled={disableCsvExport()}
            onClick={exportCSV}
            variant="outlined"
          >
            {exportRowsBtnText(pagination?.total)}
          </Button>
        </Hover>
      </Header>
      <Page>
        {openDetails && (
          <HistoryDetails
            id={params.id as string}
            measurerUsername={params.measurerUsername as string}
            resetMeasurementHistory={resetMeasurementHistory}
          />
        )}
        <PageSidebar>
          <DataFilter delay={500} filters={filters} onChange={onFilterUpdate} />
        </PageSidebar>
        <PageContent>
          <DataTable
            headers={headers}
            id="measurement-history-table"
            onClick={openDetailsPermission ? redirectToModal : undefined}
            loading={loading}
            disableSorting
            rows={measurementHistory}
            pagination={pagination}
            onPageChange={(page: number, perPage: number) => {
              fetchHistory(
                page,
                perPage,
                transformFilterValuesForApi(rawFilterValues)
              );
            }}
            rowsPerPageOptions={[10, 50, 100, 200]}
            onPerPageChange={setPerPage}
            filterValues={filterValuesForDisplay}
          />
        </PageContent>
      </Page>
    </>
  );
};
export default HistoryHomeView;
