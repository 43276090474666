/* eslint-disable no-bitwise */
import {
  encodeSetInactivityTimerPayload,
  encodeSetParametersPayload,
} from "./payload";
import buildFIFO from "./fifo";
import { encodedStringToBytes } from "./bluetoothle";
import {
  START_BYTE,
  STOP_BYTE,
  PAYLOAD_START_POS,
  MSG_HEADER_LEN,
  MSGID_CRC16_LEN,
  PAYLOAD_LENGTH_POS,
  MESSAGE_ID_POS,
  FIRST_MSG_RESULT_DATA_LEN,
  RESULT_DATA_LEN,
  START_HEADER_LENGTH,
  NON_LAST_MSG_HEADER_LEN,
  LAST_MSG_HEADER_LEN,
  PAYLOAD_HEADER_BYTES,
  INDICATION_BYTE,
  SET_INACTIVITY_TIMER_REQUEST,
  SET_PARAMETERS_REQUEST,
} from "./constants";
import { GET_MESSAGE } from "./error_codes";
import LogService from "../../services/LogService";
import {
  SetInactivityTimerPayload,
  SetParametersPayload,
} from "../../types/types-bluetooth";

/**
 * This file contains the implementation for building and parsing messages.
 * One message consists of one or more packets. If the payload cannot fit in a single packet, multiple packets are used.
 * Apparently due to a Cordova plugin issue https://github.com/don/cordova-plugin-ble-central/issues/419 the packets
 * might be received out-of-order. A workaround for this is to add a sequence number to each non-first packet.
 * Note however that the implementation in this file assumes that the first and the last packet are in order.
 * Also note that outgoing multi-packet messages lack the index, presumably because the device doesn't have a similar bug.
 */

/**
 * Build the message by given messageID.
 * @param messageID
 * @param content - The content of the message.
 */
const buildMessage = (
  messageID: number,
  content?: SetParametersPayload | SetInactivityTimerPayload
): Uint8Array => {
  /*
   * BLE API message structure for message to be sent:
   * start byte      : 1 byte
   * payload length  : 2 bytes
   * message ID      : 2 bytes
   * CRC16           : 2 bytes
   * payload         : n bytes
   * end byte        : 1 byte
   */
  let payload: Uint8Array | null;
  if (content) {
    if (messageID === SET_PARAMETERS_REQUEST) {
      payload = encodeSetParametersPayload(content as SetParametersPayload);
    } else if (messageID === SET_INACTIVITY_TIMER_REQUEST) {
      payload = encodeSetInactivityTimerPayload(
        content as SetInactivityTimerPayload
      );
    } else {
      throw new Error(
        `Payload content is only allowed for SetParameters and SetInactivityTimer requests`
      );
    }
  } else {
    payload = null;
  }
  const payloadLength = content && payload ? payload.length : 0x0000;
  const messageLength = content
    ? MSG_HEADER_LEN + payloadLength
    : MSG_HEADER_LEN; // Add length of payload to length of message
  const message = new Uint8Array(messageLength);

  // Start byte
  message[0] = START_BYTE;
  // Payload length
  message[1] = ((payloadLength + MSGID_CRC16_LEN) & 0xff00) >> 8;
  message[2] = (payloadLength + MSGID_CRC16_LEN) & 0x00ff;
  // Msg id
  message[3] = (messageID & 0xff00) >> 8;
  message[4] = messageID & 0x00ff;
  // CRC16, 0x0000 means that crc isn't used
  message[5] = 0x00;
  message[6] = 0x00;

  // Add payload in message
  if (payloadLength > 0 && payload) {
    for (let i = 0; i < payloadLength; i += 1) {
      // @ts-expect-error: TODO fix unchecked indexed access
      message[PAYLOAD_START_POS + i] = payload[i];
    }

    // Add stop byte
    message[PAYLOAD_START_POS + payloadLength] = STOP_BYTE;
  }

  // Add stop byte
  if (payloadLength <= 0) {
    message[PAYLOAD_START_POS] = STOP_BYTE;
  }

  return message;
};
/**
 * Build fifo of message.
 * @param {number} messageID
 * @param {object} content
 */
const buildMessageFIFO = (
  messageID: number,
  content?: SetParametersPayload | SetInactivityTimerPayload
): string[] => {
  const message = buildMessage(messageID, content);
  const fifo = buildFIFO(message);

  return fifo;
};
/**
 * Used to determine if the received packet is the last one of a multi-packet message.
 * This is determined by calculating if the total payload bytes received would equal
 * payload length after receiving this packet.
 * @param payloadLength
 * @param messageLength
 * @param totalPayloadBytesReceived
 * @returns True if the packet is the last one
 */
const getIsLastPacket = (
  payloadLength: number,
  messageLength: number,
  totalPayloadBytesReceived: number
): boolean =>
  // Note: This assumes that the last packet is received last, e.g. in order.
  totalPayloadBytesReceived + messageLength - LAST_MSG_HEADER_LEN ===
  payloadLength;
/**
 * Returns true if all the indices have been received.
 * Note that indices start from 1, and the last index is given as a parameter.
 * @param receivedIndices array of indices, where the value is true for the numbered index if it has been received
 * @param lastIndex the index of the last message.
 * @returns true if receivedIndices is valid
 */
const isReceivedIndicesValid = (
  receivedIndices: boolean[],
  lastIndex: number
): boolean => {
  for (let index = 1; index <= lastIndex; index += 1) {
    if (receivedIndices[index] !== true) {
      return false;
    }
  }

  // There shouldn't be anything after the lastIndex
  if (receivedIndices.length !== lastIndex + 1) {
    return false;
  }

  return true;
};
/**
 * Returns true if the message is a short message, e.g. one packet for the whole message.
 * This returns false if the packet is a (start) of a multi-packet message.
 * @param messageLength
 * @param payloadLength
 */
const getIsShortMessage = (
  messageLength: number,
  payloadLength: number
): boolean => messageLength === 7 + payloadLength + 1;
/**
 * Get the index (e.g. offset) for a multi-packet message using the piece index.
 * pieceIndex starts from 1 and is increased 1 for sequential packets.
 * @param pieceIndex
 */
const getMultiPacketIndex = (pieceIndex: number): number =>
  FIRST_MSG_RESULT_DATA_LEN + (pieceIndex - 2) * RESULT_DATA_LEN;

export interface Message {
  messageID: number;
  payload: number[];
}

/**
 * Create a parser object, which has two methods to handling incoming fifo message.
 * @return {parser}
 */

/**
 * The `parser` object created by [createParser](#createparser),
 * @typedef {Object} parser
 * @property {fifoPiecesHandler} handler
 */
const createParser = (): {
  handler: (value: string) => Promise<Message>;
} => {
  // Store the complete payload of one or more packets into this byte array
  let payload: number[] = [];
  let messageID: number;
  let payloadLength: number;
  let isShortMessage: boolean;
  // The number of the packets received. 1 for the first one, 2 for the second, ...
  let packetNumber = 0;
  // The number of payload (e.g. non-header) bytes received.
  // This is used when a long message is split into multiple packets.
  // We know the total length from the first packets (indicated with START_BYTE).
  // After we have read the expected amount of bytes we can resolve the message (e.g. stop reading).
  // The last byte of the last packet has STOP_BYTE, but crucially some of the middle packets might also have
  // STOP_BYTE by chance. Therefore we cannot rely on it - but we must remove it from the last message.
  let totalPayloadBytesReceived = 0;
  // If receivingMultiPart is true, we are excepting the next packet to be a middle packet or a last packet, not a start packet.
  // This is required so that we can reset variables on a new START_BYTE message, but only if we're not in the middle of reading
  // a multipacket message.
  let receivingMultiPart = false;
  let receivedIndices: boolean[] = [];
  /**
   * @name getMessage
   * @description return the complete message assembled by fifo pieces.
   */
  const getMessage = (): Message => {
    // This should never evaluate to true, since the received data is validated in the handler function.
    if (!isShortMessage && payloadLength !== totalPayloadBytesReceived) {
      LogService.error(
        `${GET_MESSAGE} : Payload length (${payloadLength}) doesn't equal bytes received (${totalPayloadBytesReceived})`
      );
      throw new Error(
        `${GET_MESSAGE} : Payload length (${payloadLength}) doesn't equal bytes received (${totalPayloadBytesReceived})`
      );
    }

    return {
      messageID,
      payload,
    };
  };

  const isIndication = (message: Uint8Array): boolean => {
    // Regarding to the spec all notifications start with
    return (
      message[0] === START_BYTE &&
      message[1] === 0 &&
      message[2] === 6 &&
      message[3] === INDICATION_BYTE
    );
  };

  /**
   * @name fifoPiecesHandler
   * @description Accept fifo pieces in string format. Return a promise which resolves to the message.
   * @param {string} value
   */
  const handler = (value: string) =>
    new Promise<ReturnType<typeof getMessage>>((resolve, reject) => {
      try {
        // Note: this implementation assumes that the first and last packet are received in order, but the middle ones may not.
        // See also https://github.com/don/cordova-plugin-ble-central/issues/419
        // Note: the implementation has a few theoretically unnecessary validations to ensure the transmission can be trusted.
        // They might also reveal bugs on the device side.

        packetNumber += 1;
        // Convert a base64 encoded string to a uint8Array
        const message = encodedStringToBytes(value);

        // Packets might be received out of order.
        // If the first packet is not a start message, throw an exception.
        // It's difficult to handle arbitrary order for all packets, since the proprietary protocol lacks sufficient
        // information for e.g. the packet type.
        if (packetNumber === 1 && message[0] !== START_BYTE) {
          LogService.error(
            `Bluetooth packet received out of order. (${packetNumber})`
          );
          reject(
            new Error(
              `Bluetooth packet received out of order. (${packetNumber})`
            )
          );

          return;
        }

        // This was a workaround for an old firmware bug. This should be safe to be removed.
        // There was a bug in the firmware side where the last packet was in some cases 1 byte too long, and
        // the ublox BLE module would automatically break it up into two packets, with the last packet
        // containing just the STOP_BYTE. Without this statement the message was never resolved.
        if (message[0] === STOP_BYTE) {
          receivingMultiPart = false;
          LogService.error(
            `Bluetooth invalid stop packet received. (${packetNumber})`
          );
          reject(
            new Error(
              `Bluetooth invalid stop packet received. (${packetNumber})`
            )
          );

          return;
        }

        if (message[0] === START_BYTE) {
          // Assert that we're not expecting non-first packet of a multipacket message
          if (receivingMultiPart && !isIndication(message)) {
            LogService.error(
              `Bluetooth first packet received out of order. (${packetNumber})`
            );
            reject(
              new Error(
                `Bluetooth first packet received out of order. (${packetNumber})`
              )
            );

            return;
          }

          // Reset variables since we're reading a new message
          payload = [];
          packetNumber = 1;
          totalPayloadBytesReceived = 0;
          receivingMultiPart = false;
          receivedIndices = [];

          messageID =
            // @ts-expect-error: TODO fix unchecked indexed access
            (message[MESSAGE_ID_POS] << 8) + message[MESSAGE_ID_POS + 1];
          payloadLength =
            // @ts-expect-error: TODO fix unchecked indexed access
            (message[PAYLOAD_LENGTH_POS] << 8) +
            // @ts-expect-error: TODO fix unchecked indexed access
            message[PAYLOAD_LENGTH_POS + 1] -
            PAYLOAD_HEADER_BYTES;
          isShortMessage = getIsShortMessage(message.length, payloadLength);
          // Mark the first packet as received
          receivedIndices[1] = true;

          if (isShortMessage) {
            // Assert that the short message ends with a STOP_BYTE
            if (message[message.length - 1] !== STOP_BYTE) {
              LogService.error(
                `Bluetooth short message is missing stop byte. (${packetNumber})`
              );
              reject(
                new Error(
                  `Bluetooth short message is missing stop byte. (${packetNumber})`
                )
              );

              return;
            }

            // For short start messages, the message contains the start header, payload and the stop byte
            totalPayloadBytesReceived += message.length - MSG_HEADER_LEN;

            const a = Array.from(message);
            a.pop();
            payload = a.slice(PAYLOAD_START_POS + 1);
            resolve(getMessage());

            return;
          }
          // Such as 515 (read measurement result respond)
          receivingMultiPart = true;
          let index = 0;
          // For long start messages, the message contains the start header and the rest is payload
          totalPayloadBytesReceived += message.length - START_HEADER_LENGTH;

          for (
            let i = PAYLOAD_START_POS + 1;
            i < message.length;
            i += 1, index += 1
          ) {
            // @ts-expect-error: TODO fix unchecked indexed access
            payload[index] = message[i];
          }

          return;
        }

        // Such as the body of 515 (read measurement result respond)
        if (message[0] !== START_BYTE) {
          // Assert that the packet should not be the first we receive
          if (packetNumber === 1) {
            LogService.error(
              `Bluetooth non-start packet received out of order. (${packetNumber})`
            );
            reject(
              new Error(
                `Bluetooth non-start packet received out of order. (${packetNumber})`
              )
            );

            return;
          }

          // Note: the first index is 1
          const pieceIndex = message[0];

          // Assert that the pieceIndex is 2 or larger. Note that pieceIndex 1 is for start packet
          // @ts-expect-error: TODO fix unchecked indexed access
          if (pieceIndex < 2) {
            LogService.error(
              `Bluetooth piece index is less than 2. (${packetNumber})`
            );
            reject(
              new Error(
                `Bluetooth piece index is less than 2. (${packetNumber})`
              )
            );

            return;
          }

          // Middle packets are allowed to be received out of order.
          // This log is added to collect some information if the out of order bug still exists.
          // The idea is Rollbar will collect this warn level message.
          if (packetNumber !== pieceIndex) {
            LogService.warn(
              `Received packet index ${pieceIndex} out of order as number ${packetNumber}`
            );
          }

          // Assert that we've not already received this index
          // @ts-expect-error: TODO fix unchecked indexed access
          if (receivedIndices[pieceIndex]) {
            LogService.error(
              `Bluetooth packet index ${pieceIndex} already received. (${packetNumber})`
            );
            reject(
              new Error(
                `Bluetooth packet index ${pieceIndex} already received. (${packetNumber})`
              )
            );

            return;
          }

          // Mark the packet index as received
          // @ts-expect-error: TODO fix unchecked indexed access
          receivedIndices[pieceIndex] = true;

          // This index is the offset to the payload byte array
          // @ts-expect-error: TODO fix unchecked indexed access
          const index = getMultiPacketIndex(pieceIndex);
          // Note: this assumes that the last packet is received in order.
          // If not, the promise will never resolve.
          const isLastPacket = getIsLastPacket(
            payloadLength,
            message.length,
            totalPayloadBytesReceived
          );
          // Non-start multipart messages contains 1 or 2 bytes of header, rest is payload:
          // 1b header if it is not the last message (NON_LAST_MSG_HEADER_LEN)
          // 1b header + 1b STOP BYTE if it's the last message (LAST_MSG_HEADER_LEN)
          // Note: by chance a non-last message can contain STOP_BYTE as the last byte, even if it's part of the payload
          totalPayloadBytesReceived +=
            message.length -
            (isLastPacket ? LAST_MSG_HEADER_LEN : NON_LAST_MSG_HEADER_LEN);

          // Save the payload to the payload array
          for (let i = 1; i < message.length; i += 1) {
            // @ts-expect-error: TODO fix unchecked indexed access
            payload[index + i - 1] = message[i];
          }

          if (isLastPacket) {
            // Assert that last byte of the last message is STOP_BYTE
            if (message[message.length - 1] !== STOP_BYTE) {
              LogService.error(
                `Last bluetooth packet is missing the stop marker. (${packetNumber})`
              );
              reject(
                new Error(
                  `Last bluetooth packet is missing the stop marker. (${packetNumber})`
                )
              );

              return;
            }

            // Assert that all packets have been received.
            // @ts-expect-error: TODO fix unchecked indexed access
            if (!isReceivedIndicesValid(receivedIndices, pieceIndex)) {
              LogService.error(`Not all packets received. (${packetNumber})`);
              reject(new Error(`Not all packets received. (${packetNumber})`));

              return;
            }

            // Remove STOP_BYTE from the payload
            payload.pop();

            LogService.log(
              `[BLE] fifo, handler(), long fifo msg ended! payloadLength ${payloadLength}, payload.length ${payload.length}`
            );
            resolve(getMessage());
          }
        }
      } catch (e) {
        // Note: this shouldn't ever happen
        reject(e);
      }
    });

  return {
    handler,
  };
};
export {
  buildMessage,
  buildMessageFIFO,
  createParser,
  getIsLastPacket,
  isReceivedIndicesValid,
  getIsShortMessage,
  getMultiPacketIndex,
};
