import React from "react";
import { css, styled } from "@mui/material";
import { KeyValueCellContentProps } from "./mobiletable-types";

const Key = styled("div")`
  flex-grow: 1;
  font-weight: 700;
`;

const Value = styled("div")`
  text-align: right;
`;

const iconBase = css`
  width: 1rem;
  height: 1.5rem;
`;

const ArrowIcon = styled("div")`
  svg {
    ${iconBase}
    display: block;
  }
`;

const ArrowPlaceholder = styled("div")`
  ${iconBase}
`;

const KeyValueCellContent: (props: KeyValueCellContentProps) => JSX.Element = ({
  itemKey,
  itemValue = null,
  arrowIcon = null,
  showArrow = "none",
}) => (
  <>
    <Key className="MobileTable-key">{itemKey}</Key>
    {itemValue ? (
      <Value className="MobileTable-value">{itemValue}</Value>
    ) : null}
    {showArrow === "arrow" && arrowIcon ? (
      <ArrowIcon className="MobileTable-arrow-icon">{arrowIcon}</ArrowIcon>
    ) : null}
    {showArrow === "placeholder" ? (
      <ArrowPlaceholder className="MobileTable-arrow-placeholder" />
    ) : null}
  </>
);

export default KeyValueCellContent;
