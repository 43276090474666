import React, { useState } from "react";
import styled from "styled-components";
import { Button, useTheme } from "@mui/material";
import { default as MuiSnackbar } from "@mui/material/Snackbar";
import { default as MuiSnackbarContent } from "@mui/material/SnackbarContent";
import { HorizontalPositions, VerticalPositions } from "./notification-types";

interface SnackbarProps {
  action?: JSX.Element;
  duration?: number | null;
  horizontal?: HorizontalPositions;
  message: string;
  severity?: "error" | "info" | "success" | "warning";
  showClose?: boolean;
  vertical?: VerticalPositions;
}

interface SnackbarContentProps {
  background: string;
  text: string;
}

const SnackbarContent = styled(MuiSnackbarContent)<SnackbarContentProps>`
  background-color: ${({ background }) => background} !important;
  color: ${({ text }) => text} !important;
`;

const Snackbar: (props: SnackbarProps) => JSX.Element = ({
  action,
  duration,
  horizontal,
  message,
  severity,
  showClose,
  vertical,
}): JSX.Element => {
  const [status, setStatus] = useState(true);
  const theme = useTheme();

  const close = () => setStatus(false);

  const actions = (
    <>
      {action}
      {showClose && (
        <Button color="inherit" sx={{ fontSize: "0.8rem" }} onClick={close}>
          CLOSE
        </Button>
      )}
    </>
  );

  const mapSeverityToAlertColor = () => {
    const { black, white } = theme.palette.common;

    const { main: error } = theme.palette.error;
    const { main: info } = theme.palette.info;
    const { main: success } = theme.palette.success;
    const { main: warning } = theme.palette.warning;

    switch (severity) {
      case "error":
        return { background: error, text: white };
      case "info":
        return { background: info, text: white };
      case "success":
        return { background: success, text: white };
      case "warning":
        return { background: warning, text: white };
      default:
        return { background: white, text: black };
    }
  };

  const anchor = {
    horizontal: horizontal || "right",
    vertical: vertical || "bottom",
  };

  const { background, text } = mapSeverityToAlertColor();

  return (
    <MuiSnackbar
      anchorOrigin={{ ...anchor }}
      autoHideDuration={typeof duration !== "undefined" ? duration : 6000}
      key={message}
      message={message}
      onClose={close}
      open={status}
    >
      <SnackbarContent
        action={actions}
        background={background}
        message={message}
        text={text}
      />
    </MuiSnackbar>
  );
};

export default Snackbar;
