import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  AssessmentSubmissionParameter,
  DrugAuthenticationService,
  HistoryService,
  StoredMeasurementHistoryItem,
  useError,
  useHttpErrorReader,
} from "lib-core";

import { AxiosError } from "axios";
import { useMutation, useQuery } from "@tanstack/react-query";
import DetailsModal from "./DetailsModal";
import HistoryDetailsContent from "./HistoryDetailsContent";
import { NoteParameters } from "./history-types";

interface HistoryDetailsProps {
  id: string;
  measurerUsername: string;
  resetMeasurementHistory: () => void;
}

const HistoryDetails: (props: HistoryDetailsProps) => JSX.Element = ({
  id,
  measurerUsername,
  resetMeasurementHistory,
}) => {
  const { handleErrors } = useError();
  const errorReader = useHttpErrorReader();
  const [refreshContentKey, setRefreshContentKey] = useState(0);

  const {
    isFetching: isFetchingMeasurementDetails,
    data: storedItem,
    error: fetchStoredItemError,
    refetch: refreshMeasurementDetails,
  } = useQuery<StoredMeasurementHistoryItem>({
    queryKey: ["measurement-details", measurerUsername, id],
    queryFn: async () => {
      const { getMeasurementHistoryItemWithUserContent } = HistoryService;
      return getMeasurementHistoryItemWithUserContent(measurerUsername, id);
    },
  });

  useEffect(() => {
    if (fetchStoredItemError) {
      const { response: errorResponse } = fetchStoredItemError as AxiosError;
      if (errorResponse) {
        const { data } = errorResponse;
        const customHandling = errorReader(errorResponse);

        handleErrors({ error: data, customHandling });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchStoredItemError]);

  const history = useHistory();

  const closeModal = () => {
    history.push("/history");
  };

  const { mutateAsync: saveSubmissionParam } = useMutation({
    mutationFn: async (body: AssessmentSubmissionParameter) => {
      if (storedItem) {
        await DrugAuthenticationService.submitAssessment({
          body,
          params: {
            username: storedItem.measurerUsername,
            measurementId: storedItem.id,
          },
        });
      }
    },
    onSuccess() {
      resetMeasurementHistory();
      refreshMeasurementDetails();
    },
    onError(error) {
      const { response: errorResponse } = error as AxiosError;
      if (errorResponse) {
        const { data: error } = errorResponse;
        const customHandling = errorReader(errorResponse);
        handleErrors({
          error,
          customHandling,
          options: {
            errorMessage: "Error happened while submitting assessment.",
          },
        });
      }
    },
  });

  const { mutateAsync: storeNote } = useMutation({
    mutationFn: async (params: NoteParameters) => {
      const { measurementId, note, userId } = params;

      await HistoryService.addMeasurementHistoryNote(
        userId,
        measurementId,
        note
      );
    },
    onSuccess() {
      resetMeasurementHistory();
      refreshMeasurementDetails();
      setRefreshContentKey((prev) => prev + 1);
    },
    onError(error) {
      const { response: errorResponse } = error as AxiosError;
      if (errorResponse) {
        const { data: error } = errorResponse;
        const customHandling = errorReader(errorResponse);
        handleErrors({
          error,
          customHandling,
        });
      }
    },
  });

  if (storedItem) {
    return (
      <DetailsModal isOpen handleClose={closeModal}>
        <HistoryDetailsContent
          closeModal={closeModal}
          saveSubmissionParam={saveSubmissionParam}
          content={storedItem}
          storeNote={storeNote}
          refreshContentKey={refreshContentKey}
          isFetching={isFetchingMeasurementDetails}
        />
      </DetailsModal>
    );
  }

  return <></>;
};

export default HistoryDetails;
