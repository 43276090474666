import React, { useState } from "react";
import styled from "styled-components";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import {
  PermissionKeys,
  StoredMeasurementHistoryItem,
  usePermissionChecking,
} from "lib-core";

import DetailsViewPhotos from "./DetailsViewPhotos";
import DetailsViewTabPanel from "./DetailsViewTabPanel";
import DetailsViewSpectraImage from "./DetailsViewSpectraImage";
import { ImageState } from "./history-hook";

interface DetailsViewSpectraProps {
  content: StoredMeasurementHistoryItem;
  spectraData: { spectra?: ImageState; error?: unknown };
}

const Container = styled.div`
  grid-area: spectra;
  background: ${({ theme }) => theme.palette.brandGray.light};
  padding: 24px 30px;
`;

const { readMeasurementSpectra } = PermissionKeys;

const DetailsViewSpectra: (props: DetailsViewSpectraProps) => JSX.Element = ({
  content,
  spectraData,
}) => {
  const requirePermission = usePermissionChecking();
  const spectraPermissions = requirePermission([readMeasurementSpectra]);
  const defaultTab = spectraPermissions ? 0 : 1;

  const [activeTab, setActiveTab] = useState(defaultTab);

  const changeTab = (_: React.SyntheticEvent, tabIndex: number) => {
    setActiveTab(tabIndex);
  };

  const { id: measurementId, images } = content;

  const disablePhotos = () => images.length < 1;

  const disableSpectra = () => !spectraPermissions;

  return (
    <Container>
      <Tabs
        aria-label="Measurement Spectra and Photos"
        centered
        onChange={changeTab}
        value={activeTab}
      >
        <Tab label="Spectra" disabled={disableSpectra()} />
        <Tab label="Photos" disabled={disablePhotos()} />
      </Tabs>
      <DetailsViewTabPanel activeTab={activeTab} index={0}>
        <DetailsViewSpectraImage
          measurementId={measurementId}
          spectraData={spectraData}
        />
      </DetailsViewTabPanel>
      <DetailsViewPhotos activeTab={activeTab} content={content} />
    </Container>
  );
};

export default DetailsViewSpectra;
