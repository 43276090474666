import React, { useEffect, useState } from "react";
import styled from "styled-components";

import ImageService from "../../services/domain/ImageService";
import { Image } from "../../types/contracts/MeasurementHistoryItem";
import SimpleMobileImagePreview from "./SimpleMobileImagePreview";

interface PhotolistProps {
  measurementId: string;
  photoList: Image[];
  userId: string;
}

type PhotoListArray = {
  base64: string;
  key: string;
}[];

const ImageList = styled.div`
  text-align: left;
  white-space: nowrap;
  width: 100vw;
  height: 70px;
  overflow-y: hidden;
  border-bottom: 1px solid ${({ theme }) => theme.palette.grey[400]};
`;

const SmallImage = styled.img`
  display: inline-block;
  overflow: hidden;
  height: 100%;
`;

const Photolist: (props: PhotolistProps) => JSX.Element = ({
  measurementId,
  photoList,
  userId,
}) => {
  const [base64List, setBase64List] = useState<PhotoListArray>([]);
  const [selectedImage, setSelectedImage] = React.useState<string>();

  const handleOpen = (selectedImage: string) => {
    setSelectedImage(selectedImage);
  };

  const handleClose = () => {
    setSelectedImage(undefined);
  };

  useEffect(() => {
    const getPicturesInBase64 = async () => {
      const results = await Promise.all(
        photoList.map(async (photo) => {
          const photoId = photo.id.toString();
          const key = `${measurementId}-${photoId}`;

          const base64 = await ImageService.getMeasurementImage(
            userId,
            measurementId,
            photoId
          );

          return { base64, key };
        })
      );

      setBase64List(results);
    };

    getPicturesInBase64();
  }, [measurementId, photoList, userId]);

  if (photoList.length < 1) {
    return <></>;
  }

  return (
    <>
      <SimpleMobileImagePreview
        handleClose={handleClose}
        image={selectedImage}
        open={!!selectedImage}
      />
      <ImageList>
        {base64List.map((photo) => {
          const { base64, key } = photo;

          return (
            <SmallImage
              key={key}
              onClick={() => handleOpen(base64)}
              src={base64}
            />
          );
        })}
      </ImageList>
    </>
  );
};

export default Photolist;
