import { Typography } from "@mui/material";
import React from "react";
import styled from "styled-components";

interface HeaderProps {
  title: string;
  children?: React.ReactNode;
}

const Container = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 19px 30px;
  box-shadow: 0 1px 12px ${({ theme }) => theme.palette.brandGray.main}b0;
  z-index: 1;
`;

const Header: (props: HeaderProps) => JSX.Element = ({ title, children }) => (
  <Container>
    <Typography variant="h2">{title}</Typography>
    {children}
  </Container>
);

export default Header;
