import React from "react";
import styled from "styled-components";
import {
  MeasurementAssessment,
  PermissionKeys,
  PermissionRequired,
  PredictionValue,
  StoredMeasurementHistoryItem,
  mapAssessmentToColor,
  mapAssessmentToUserString,
} from "lib-core";
import Table from "@mui/material/Table";
import Typography from "@mui/material/Typography";

import { Divider, TableBody, TableRow, Theme } from "@mui/material";
import MeasurementDetailsProvider from "./MeasurementDetailsProvider";
import { Td, Th } from "../components/keyValueTable/keyValueTable";
import DetailsViewAssessment from "./DetailsViewAssessment";

interface DetailsViewMeasurementProps {
  content: StoredMeasurementHistoryItem;
  isFetching: boolean;
  selectedAssessment: MeasurementAssessment;
  setSelectedAssessment: (value: MeasurementAssessment) => void;
}

const Container = styled.div`
  grid-area: measurement;
  padding: 24px 30px;
`;

const Headline = styled(Typography)`
  margin-bottom: 12px;
`;

const StatusText = styled.div<{ colorMapper: (theme: Theme) => string }>`
  display: flex;
  align-items: center;
  gap: 10px;
  font-weight: 700;
  color: ${({ theme, colorMapper }) => colorMapper(theme)};
`;

const ResultIndicator = styled.span`
  width: 1.1rem;
  height: 1.1rem;
  border-radius: 1.1rem;
  background: currentColor;
`;

const { measurementStatusChange } = PermissionKeys;

const DetailsViewMeasurement: (
  props: DetailsViewMeasurementProps
) => JSX.Element = ({
  content,
  selectedAssessment,
  setSelectedAssessment,
  isFetching,
}) => {
  const allowUpdatingAssessment = (() => {
    if (!content.metadata.assessment) {
      return true;
    }
    return content.metadata.assessment === MeasurementAssessment.PENDING;
  })();

  return (
    <Container>
      <Headline variant="h3">Measurement details</Headline>

      <MeasurementDetailsProvider content={content}>
        {({
          measurementId,
          product,
          batchNumber,
          caseNumber,
          manufactured,
          expires,
          user,
          sensor,
          timestamp,
          location,
          prediction,
          modelStatus,
          assessment,
          assessmentTime,
          assessmentUser,
        }) => {
          const mapPredictionToColor = (theme: Theme) => {
            switch (prediction) {
              case PredictionValue.Fail:
              case PredictionValue.ConfirmedFail:
                return theme.palette.error.main;
              case PredictionValue.Unknown:
                return theme.palette.warning.main;
              case PredictionValue.Invalid:
                return theme.palette.brandGray.dark;
              default:
                return theme.palette.success.main;
            }
          };

          const mapAssessmentToStatusColor = (theme: Theme) => {
            if (!assessment) {
              return theme.palette.text.primary;
            }
            return mapAssessmentToColor(assessment, theme);
          };

          return (
            <Table aria-label="measurement details table">
              <TableBody>
                <TableRow>
                  <Th>Measurement ID</Th>
                  <Td>{measurementId}</Td>
                </TableRow>
                <TableRow>
                  <Th>Product</Th>
                  <Td>{product}</Td>
                </TableRow>
                <TableRow>
                  <Th>Batch number</Th>
                  <Td>{batchNumber}</Td>
                </TableRow>
                <TableRow>
                  <Th>Case number</Th>
                  <Td>{caseNumber}</Td>
                </TableRow>
                <TableRow>
                  <Th>Manufacturing date</Th>
                  <Td>{manufactured}</Td>
                </TableRow>
                <TableRow>
                  <Th>Expiration date</Th>
                  <Td>{expires}</Td>
                </TableRow>
                <TableRow>
                  <Th>User</Th>
                  <Td>{user}</Td>
                </TableRow>
                <TableRow>
                  <Th>Sensor</Th>
                  <Td>{sensor}</Td>
                </TableRow>
                <TableRow>
                  <Th>Location</Th>
                  <Td>{location}</Td>
                </TableRow>
                <TableRow>
                  <Th>Timestamp</Th>
                  <Td>{timestamp}</Td>
                </TableRow>
                <TableRow>
                  <Th>Model</Th>
                  <Td>{modelStatus}</Td>
                </TableRow>
                <TableRow>
                  <Th>Result</Th>
                  <Td>
                    <StatusText colorMapper={mapPredictionToColor}>
                      <ResultIndicator />
                      {prediction}
                    </StatusText>
                  </Td>
                </TableRow>
                {allowUpdatingAssessment && (
                  <PermissionRequired
                    hidden
                    permissionKeys={[measurementStatusChange]}
                  >
                    <DetailsViewAssessment
                      disabled={isFetching}
                      modelStatus={content.metadata.modelStatus}
                      selectedAssessment={selectedAssessment}
                      setSelectedAssessment={setSelectedAssessment}
                    />
                  </PermissionRequired>
                )}
                {!!assessment &&
                  !allowUpdatingAssessment && [
                    <Divider component="tr" key="divider" />,
                    <TableRow key="assessment">
                      <Th>Assessment</Th>
                      <Td>
                        <StatusText colorMapper={mapAssessmentToStatusColor}>
                          <ResultIndicator />
                          {mapAssessmentToUserString(assessment)}
                        </StatusText>
                      </Td>
                    </TableRow>,
                    <TableRow key="analyst-id">
                      <Th>Analyst ID</Th>
                      <Td>{assessmentUser}</Td>
                    </TableRow>,
                    <TableRow key="assessed-on">
                      <Th>Assessed on</Th>
                      <Td>{assessmentTime}</Td>
                    </TableRow>,
                  ]}
              </TableBody>
            </Table>
          );
        }}
      </MeasurementDetailsProvider>
    </Container>
  );
};

export default DetailsViewMeasurement;
