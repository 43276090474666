export const supportedDeviceMetadata = {
  manufacturer: "FTDI",
  productId: "6015",
  vendorId: "0403",
};

export enum DeviceStatusMessageValue {
  OK = "OK",
  TOO_MANY_FAILED_CALIBRATIONS = "TOO_MANY_FAILED_CALIBRATIONS",
  DEACTIVATED_BY_ADMIN = "DEACTIVATED_BY_ADMIN",
}
