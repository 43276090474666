import LogService from "../../services/LogService";

enum BluetoothStateMobile {
  PermissionRequested,
  PermissionDenied,
  BluetoothOn,
  BluetoothOff,
  Failure,
}

function startIosBluetoothStateUpdates(
  callback: (state: BluetoothStateMobile) => void
): void {
  window.ble.startStateNotifications(
    (state) => {
      switch (state) {
        case "on":
          callback(BluetoothStateMobile.BluetoothOn);
          break;
        case "off":
          callback(BluetoothStateMobile.BluetoothOff);
          break;
        case "unknown":
          callback(BluetoothStateMobile.PermissionRequested);
          break;
        case "unauthorized":
          callback(BluetoothStateMobile.PermissionDenied);
          break;
        case "turningOn":
          callback(BluetoothStateMobile.BluetoothOn);
          break;
        case "turningOff":
          callback(BluetoothStateMobile.BluetoothOff);
          break;
        default:
          LogService.log(`Unexpected bluetooth state: ${state}`);
          callback(BluetoothStateMobile.Failure);
          break;
      }
    },
    () => {
      LogService.log("startIosBluetoothStateUpdates() failed");
      callback(BluetoothStateMobile.Failure);
    }
  );
}

function stopIosBluetoothStateUpdates(): void {
  window.ble.stopStateNotifications();
}

export {
  startIosBluetoothStateUpdates,
  stopIosBluetoothStateUpdates,
  BluetoothStateMobile,
};
