/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";

import Button from "@mui/material/Button";
import useApi from "../api";
import Urls from "../services/Urls";
import { HttpConfig } from "../types/types-api";
import ErrorMessage from "../components/ErrorMessage";
import {
  authCode,
  saveAccessToken,
  saveRefreshToken,
  getDefaultHomePath,
} from "./helpers";

const CLIENTID = process.env.REACT_APP_AD_CLIENTID;
const REDIRECT_URI = process.env.REACT_APP_AD_REDIRECT;

interface TokenResponse {
  accessToken: string;
  refreshToken: string;
}

interface AuthorizeProps {
  homePath: string | undefined;
  redirectToLogout: () => void;
}

const AuthorizationError = ({
  redirectToLogout,
}: Pick<AuthorizeProps, "redirectToLogout">) => (
  <ErrorMessage message="Error during authorization. Please log out and try again, and if problem persists, contact support.">
    <Button variant="outlined" onClick={redirectToLogout}>
      Log out
    </Button>
  </ErrorMessage>
);

interface TokenExchangeData {
  clientId: string;
  authorizationCode: string;
  redirectUri: string;
}

const Authorize = ({
  homePath,
  redirectToLogout,
}: AuthorizeProps): JSX.Element => {
  const [shouldRedirect, setShouldRedirect] = useState(false);
  const httpConfig: HttpConfig<TokenExchangeData> = {
    method: "post",
    url: Urls.tokenExchangeAuthCode(),
    data: {
      clientId: `${CLIENTID}`,
      authorizationCode: `${authCode()}`,
      redirectUri: `${REDIRECT_URI}`,
    },
  };

  const { data, isLoading, isError } = useApi<TokenExchangeData, TokenResponse>(
    httpConfig
  );

  useEffect(() => {
    if (data?.accessToken && data?.refreshToken) {
      saveAccessToken(data.accessToken);

      saveRefreshToken(data.refreshToken);
      setShouldRedirect(true);
    }
  }, [data?.accessToken, data?.refreshToken]);

  if (!isLoading && isError) {
    return <AuthorizationError redirectToLogout={redirectToLogout} />;
  }

  if (shouldRedirect) {
    return <Redirect to={homePath ?? getDefaultHomePath()} />;
  }

  return <></>;
};

export default Authorize;
