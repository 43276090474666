import React from "react";
import styled from "styled-components";
import { CircularProgress, Typography, useTheme } from "@mui/material";
import { default as ErrorIcon } from "@mui/icons-material/WarningAmberRounded";
import { default as SuccessIcon } from "@mui/icons-material/DoneRounded";

interface StatusMessageIcons {
  error?: JSX.Element;
  loading?: JSX.Element;
  warning?: JSX.Element;
  success?: JSX.Element;
}

interface StatusMessageProps {
  bottomGutter?: boolean;
  children: React.ReactNode;
  display?: boolean;
  messageType: "error" | "loading" | "success" | "warning";
  topGutter?: boolean;
  className?: string;
  icons?: StatusMessageIcons;
}

interface MessageProps {
  bottomGutter?: boolean;
  color: string;
  topGutter?: boolean;
}

const Message = styled.div<MessageProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  color: ${({ color }) => color};
  margin-top: ${({ topGutter }) => (topGutter ? "1.75rem" : 0)};
  margin-bottom: ${({ bottomGutter }) => (bottomGutter ? "1.75rem" : 0)};
`;

const Icon = styled("div")`
  width: 1.5em;
  text-align: center;

  svg {
    display: block;
    position: relative;
    top: -1px;
  }
`;

const Text = styled(Typography)`
  font-weight: 700;
`;

const StatusMessage: (props: StatusMessageProps) => JSX.Element = ({
  bottomGutter,
  children,
  display,
  messageType,
  topGutter,
  className,
  icons,
}) => {
  const theme = useTheme();

  const selectMessageIconAndColor = () => {
    switch (messageType) {
      case "error":
        return {
          color: theme.palette.error.main,
          icon: icons?.error || <ErrorIcon />,
        };
      case "loading":
        return {
          color: theme.palette.primary.main,
          icon: icons?.loading || (
            <CircularProgress size="1em" color="inherit" thickness={5} />
          ),
        };
      case "warning":
        return {
          color: theme.palette.warning.main,
          icon: icons?.warning || <ErrorIcon />,
        };
      default:
        return {
          color: theme.palette.success.main,
          icon: icons?.success || <SuccessIcon />,
        };
    }
  };

  const { color, icon } = selectMessageIconAndColor();

  if (!display) {
    return <></>;
  }

  return (
    <Message
      color={color}
      topGutter={topGutter}
      bottomGutter={bottomGutter}
      className={`StatusMessage ${className}`}
    >
      <Icon className="StatusMessage-icon">{icon}</Icon>
      <Text className="StatusMessage-text">{children}</Text>
    </Message>
  );
};

export default StatusMessage;
