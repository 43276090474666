import { Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import { BluetoothStateMobile } from "../../lib/ble/permission";

import BluetoothService from "../../services/bluetooth/BluetoothService";
import DialogFailure from "./DialogFailure";

interface BluetoothStateMobileProps {
  onBluetoothOk: () => void;
  className?: string;
}

const BluetoothCheckMobile: (props: BluetoothStateMobileProps) => JSX.Element =
  ({ onBluetoothOk, className = "" }) => {
    const [bluetoothState, setBluetoothState] = useState<
      BluetoothStateMobile | undefined
    >(undefined);

    useEffect(() => {
      BluetoothService.startIosBluetoothStateUpdates((state) => {
        switch (state) {
          case BluetoothStateMobile.Failure:
            setBluetoothState(BluetoothStateMobile.Failure);
            break;
          case BluetoothStateMobile.BluetoothOn:
            setBluetoothState(undefined);
            onBluetoothOk();
            break;
          case BluetoothStateMobile.PermissionDenied:
            setBluetoothState(BluetoothStateMobile.PermissionDenied);
            break;
          case BluetoothStateMobile.BluetoothOff:
            setBluetoothState(BluetoothStateMobile.BluetoothOff);
            break;
          case BluetoothStateMobile.PermissionRequested:
          default:
            setBluetoothState(BluetoothStateMobile.PermissionRequested);
            break;
        }
      });

      return () => {
        // Cleanup function
        BluetoothService.stopIosBluetoothStateUpdates();
      };
    }, [onBluetoothOk]);

    const showPermissionDenied =
      bluetoothState === BluetoothStateMobile.PermissionDenied;
    const showBluetoothOff =
      bluetoothState === BluetoothStateMobile.BluetoothOff;
    const showFailure = bluetoothState === BluetoothStateMobile.Failure;

    const createBleErrorMessage = () => {
      switch (true) {
        case showBluetoothOff:
          return "Please turn bluetooth on!";
        case showFailure:
          return "Unknown error!";
        case showPermissionDenied:
          return "Please check your Bluetooth permissions";
        default:
          return "Unknown error!";
      }
    };

    return (
      <>
        <DialogFailure
          open={showBluetoothOff}
          title="Bluetooth turned off"
          description="To continue, turn Bluetooth on manually in the system settings."
        />
        <DialogFailure
          open={showPermissionDenied}
          title="Bluetooth permission not granted"
          description="To continue, allow this app to use Bluetooth manually in the system
            settings."
        />
        <DialogFailure
          open={showFailure}
          title="Something went wrong"
          description="Restart the app and try again."
        />
        <Typography className={`BluetoothCheckMobile-error ${className}`}>
          {createBleErrorMessage()}
        </Typography>
      </>
    );
  };

export default BluetoothCheckMobile;
