import React, { ReactNode } from "react";
import styled from "styled-components";
import { default as MuiTableRow } from "@mui/material/TableRow";
import { TableCell } from "@mui/material";
import { LibraryTableHeader } from "./LibraryTableHeaders";

interface LibraryTableRowsProps<T> {
  headers: Array<LibraryTableHeader<T>>;
  rows: Array<Record<keyof T, ReactNode>>;
  isRowSelected?(row: unknown): boolean;
}

const LibraryTableRow = styled(MuiTableRow)`
  &:hover td,
  &:hover th {
    background-color: ${({ theme }) => theme.table.hover};
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const LibraryTableRows: <T>(props: LibraryTableRowsProps<T>) => JSX.Element = ({
  rows,
  headers,
  isRowSelected,
}) => {
  return (
    <>
      {rows.map((row, i) => (
        <LibraryTableRow
          key={i}
          selected={isRowSelected ? isRowSelected(row) : false}
        >
          {headers.map((header) =>
            header.key && row[header.key] ? (
              <TableCell key={header.key as string} padding={header.padding}>
                <Container>{row[header.key]}</Container>
              </TableCell>
            ) : (
              <TableCell key={header.key as string} padding={header.padding}>
                &mdash;
              </TableCell>
            )
          )}
        </LibraryTableRow>
      ))}
    </>
  );
};

export default LibraryTableRows;
