const accessToken = "auth:access_token";
const previousPath = "auth:previous_path";
const refreshToken = "auth:refresh_token";
const userInfo = "auth:userinfo";
const userPermissions = "auth:permissions";
const superadmin = "auth:superadmin";
const userRoles = "auth:userRoles";

const disableSetupDevice = "deviceSetup:disabled";
const persistSelectedDevice = "selectedDevice:persisted";
const persistSelectedDeviceId = "selectedDeviceId:persisted";

const persistSelectedProduct = "selectedProduct:persisted";
const persistSelectedViewId = "selectedViewId:persisted";

const rolesSetup = "user:role_setup";

const defaultProtocol = "DS2-0-default" as const;

const Constants = {
  accessToken,
  disableSetupDevice,
  persistSelectedDevice,
  persistSelectedDeviceId,
  persistSelectedProduct,
  persistSelectedViewId,
  previousPath,
  refreshToken,
  superadmin,
  userInfo,
  userPermissions,
  rolesSetup,
  userRoles,
  defaultProtocol,
};

export default Constants;
