/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from "react";
import {
  MeasurementAssessment,
  StoredMeasurementHistoryItem,
  mapAssessmentToUserString,
} from "lib-core";
import {
  Divider,
  FormControl,
  MenuItem,
  Select,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material";
import styled from "styled-components";
import { Td, Th } from "../components/keyValueTable/keyValueTable";

interface DetailsViewAssessmentProps {
  modelStatus: StoredMeasurementHistoryItem["metadata"]["modelStatus"];
  selectedAssessment: MeasurementAssessment;
  disabled: boolean;
  setSelectedAssessment: (value: MeasurementAssessment) => void;
}

const ThPadded = styled(Th)`
  padding-top: 16px;
`;

const group1AssessmentOptions = [
  MeasurementAssessment.TRUE_POSITIVE,
  MeasurementAssessment.TRUE_NEGATIVE,
  MeasurementAssessment.FALSE_POSITIVE,
  MeasurementAssessment.FALSE_NEGATIVE,
];

const group2AssessmentOptions = [
  MeasurementAssessment.WRONG_MODEL,
  MeasurementAssessment.INVALID_SAMPLE,
];

// https://dev.azure.com/SpectralEngines/SE%20SW/_workitems/edit/6348
const colorStatuses = {
  [MeasurementAssessment.PENDING]: "primary",
  [MeasurementAssessment.TRUE_POSITIVE]: "error",
  [MeasurementAssessment.TRUE_NEGATIVE]: "success",
  [MeasurementAssessment.FALSE_POSITIVE]: "warning",
  [MeasurementAssessment.FALSE_NEGATIVE]: "warning",
  [MeasurementAssessment.WRONG_MODEL]: "primary",
  [MeasurementAssessment.INVALID_SAMPLE]: "primary",
} as const;

const PendingMenuItem = styled(MenuItem)`
  color: ${({ theme }) => theme.palette.brandGray.dark};
`;

const DetailsViewAssessment: (
  props: DetailsViewAssessmentProps
) => JSX.Element = ({
  modelStatus,
  selectedAssessment,
  setSelectedAssessment,
  disabled,
}) => {
  const theme = useTheme();
  const [open, setOpen] = useState(false);

  const assessmentNotSelected =
    selectedAssessment === MeasurementAssessment.PENDING;

  if (modelStatus !== "APPROVED_PRODUCTION") {
    return <></>;
  }

  return (
    <TableRow>
      <ThPadded>Assessment</ThPadded>
      <Td>
        <FormControl sx={{ width: 300, mb: 1 }} size="small">
          <Select
            disabled={disabled}
            open={open}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            displayEmpty
            color={colorStatuses[selectedAssessment]}
            inputProps={{
              "aria-label": "Without label",
              sx: {
                color: assessmentNotSelected
                  ? theme.palette.brandGray.dark
                  : theme.palette.text.primary,
              },
            }}
            value={selectedAssessment}
            onChange={(e) => {
              setSelectedAssessment(e.target.value as MeasurementAssessment);
            }}
          >
            <PendingMenuItem value={MeasurementAssessment.PENDING}>
              {open
                ? mapAssessmentToUserString(MeasurementAssessment.PENDING)
                : "Pending"}
            </PendingMenuItem>
            <Divider />
            {group1AssessmentOptions.map((option) => {
              return (
                <MenuItem value={option} key={option}>
                  {mapAssessmentToUserString(option)}
                </MenuItem>
              );
            })}
            <Divider />
            {group2AssessmentOptions.map((option) => {
              return (
                <MenuItem value={option} key={option}>
                  {mapAssessmentToUserString(option)}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>

        {!assessmentNotSelected && (
          <Typography
            sx={{ color: `${colorStatuses[selectedAssessment]}.main` }}
          >
            Remember to add a note to your assessment!
          </Typography>
        )}
      </Td>
    </TableRow>
  );
};

export default DetailsViewAssessment;
