import { useCallback } from "react";
import PermissionService from "../../services/PermissionService";
import { PermissionKey } from "../../types/types-permission";

type PermissionChecking = (permissionKeys: PermissionKey[]) => boolean;

const usePermissionChecking = (): PermissionChecking => {
  const requirePermission = useCallback(
    (permissionKeys: PermissionKey[]): boolean => {
      return PermissionService.verify(permissionKeys);
    },
    []
  );

  return requirePermission;
};

export default usePermissionChecking;
