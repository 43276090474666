import React, { useState } from "react";
import styled from "styled-components";
import Button from "@mui/material/Button";
import { Typography } from "@mui/material";

interface BannerProps {
  action?: JSX.Element;
  message: string;
  showClose?: boolean;
}

const Container = styled.div`
  display: flex;
  justify-content: center;
  background-color: ${({ theme }) => theme.palette.common.white};
`;

const BannerContainer = styled.div`
  display: flex;
  height: 56px;
  width: 100%;
  align-items: center;
  justify-content: center;
  max-width: ${({ theme }) => theme.breakpoints.values.lg - 100}px;
  padding-left: 20px;
  padding-right: 20px;
  position: relative;
`;

const Actions = styled.div`
  position: absolute;
  right: 1em;
`;

const Banner: (props: BannerProps) => JSX.Element = ({
  action,
  message,
  showClose,
}) => {
  const [status, setStatus] = useState(true);

  const close = () => setStatus(false);

  const actions = (
    <>
      <div>
        {action}
        {showClose && (
          <Button size="small" onClick={close}>
            CLOSE
          </Button>
        )}
      </div>
    </>
  );

  return (
    <Container>
      {status && (
        <BannerContainer>
          <Typography>{message}</Typography>
          <Actions>{actions}</Actions>
        </BannerContainer>
      )}
    </Container>
  );
};

export default Banner;
