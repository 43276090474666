import { ProductVariant } from "../../types/types-product";
import wait from "../../lib/helper/wait";
import {
  ProductVariantDetail,
  ProductVariantMeasurementInput,
  ProductVariantMeasurementSessionResponse,
} from "../../types/contracts/Product";
import MockedProductVariant from "../mocks-product-variant";
import ProductsService from "../../services/domain/ProductsService";
import { PagedResult, PagingData } from "../../types/types-api";
import { KeyValueParams } from "../../components/filter/datafilter-types";

const getProductVariants = async (
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  _pagination: PagingData,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  _metadata: KeyValueParams
): Promise<PagedResult<ProductVariant>> => {
  await wait(1000);

  return {
    paging: { page: 0, perPage: 100 },
    entities: MockedProductVariant.mockProducts,
  };
};
getProductVariants.query = ProductsService.getProductVariants.query;

const getProductVariantDetail = async (
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  _id: number
): Promise<ProductVariantDetail> => {
  await wait(1000);
  const response = MockedProductVariant.getDetailById();

  return response;
};
getProductVariantDetail.query = ProductsService.getProductVariantDetail.query;

const startProductVariantMeasurementSession = async (
  id: number,
  _dataSetId: number,
  totalNumberOfIntendedMeasurements?: number
): Promise<ProductVariantMeasurementSessionResponse> => {
  await wait(1000);

  const mockVariant = MockedProductVariant.getStartMeasurementSession(
    id,
    totalNumberOfIntendedMeasurements
  );

  return mockVariant;
};
startProductVariantMeasurementSession.query =
  "product-variant/measurement-session";

const uploadProductVariantMeasurement = async (
  id: number,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  _dataSetId: number,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  _closeOnSave: boolean,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  _payload: ProductVariantMeasurementInput
): Promise<ProductVariantMeasurementSessionResponse> => {
  await wait(1000);

  const mockVariant = MockedProductVariant.getStartMeasurementSession(id);

  return mockVariant;
};

export const MockProductService = {
  getProductVariants,
  getProductVariantDetail,
  startProductVariantMeasurementSession,
  uploadProductVariantMeasurement,
};
