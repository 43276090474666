import { createFlow } from "./flow";
import {
  START_MEASURE_REQUEST,
  READ_DEVICE_READY_STATUS_RESPONSE,
  SCANNER_MOVEMENT_DETECTED,
  READ_MEASURE_RESULT_REQUEST,
  READ_MEASURE_RESULT_RESPONSE,
  START_MEASURE_RESPONSE,
  START_MEASURE_RESPONSE_VALUES,
  READ_DEVICE_READY_STATUS_REQUEST,
} from "./constants";

import { buildMessageFIFO } from "./message";
import { MEASURE } from "./error_codes";
import LogService from "../../services/LogService";
import { decodeReadMeasurementResultPayload } from "./payload";
import { ReadMeasurementResultPayload } from "../../types/types-bluetooth";

/**
 * Start measurement and return a Promise, which will be resolved with an object of measurement result (a message with ID 515).
 * @example
 * await init();
 * const devices = await scan([serviceUUID], 'PurplPro');
 * const { address } = devices;
 * const connected = await createConnection(address);
 * if (connected) {
 *   const services = await discover(address);
 *   if (services) {
 *     const message = await measure(address);
 *     LogService.log(message);
 *     // should print the following message in console:
 *     // {
 *     //    messageID: 515,
 *     //    payload: [0, 0, 10, 2, ....]
 *     // }
 *   }
 * }
 * @param {string} address - The address of the device.
 * @return {Promise<Message>}
 */
/**
 *
 * @typedef {Object} Message
 * @property {number} messageID The ID of the message
 * @property {Array} payload An array of the payload of the message
 */
const measure = (address: string): Promise<ReadMeasurementResultPayload> =>
  new Promise(async (resolve, reject) => {
    LogService.log("1 - open flow for measure");

    try {
      const flow = await createFlow(address, (e) =>
        reject(new Error(e.message))
      );
      const readResultFIFO = buildMessageFIFO(READ_MEASURE_RESULT_REQUEST);
      const startMeasureFIFO = buildMessageFIFO(START_MEASURE_REQUEST);

      flow.subscribe(READ_MEASURE_RESULT_RESPONSE, (message) => {
        LogService.log(
          `[BLE] measure(), got result ${JSON.stringify(
            message
          )}, close flow control connection and unsubscribe to credits/fifo characteristics.`
        );

        // message.payload contains the measured values as uint8 values.
        // These need to be interpreted as float32 values:
        const values = decodeReadMeasurementResultPayload(message.payload);

        flow.close().then((closed) => {
          if (closed) {
            LogService.log("3 - closed flow for measure");
            resolve(values);
          }
        });
      });

      flow.subscribe(START_MEASURE_RESPONSE, (message) => {
        if (message.payload[0] === START_MEASURE_RESPONSE_VALUES.NOT_OK) {
          LogService.error(
            `Error - ${MEASURE} : measurement failed to start, please try again: code: START_MEASUREMENT.MEAS_NOT_OK`
          );
          reject(new Error(`${MEASURE} : Please try again.`));
        }

        if (message.payload[0] === START_MEASURE_RESPONSE_VALUES.STARTED) {
          LogService.log("[BLE] measure(), measure started.");
        }

        if (message.payload[0] === START_MEASURE_RESPONSE_VALUES.READY) {
          LogService.log("[BLE] measure(), measure ended, ask for results.");
          flow.write(readResultFIFO);
        }
      });

      flow.subscribe(READ_DEVICE_READY_STATUS_RESPONSE, (message) => {
        if (message.payload[0] === 0) {
          LogService.log(
            "[BLE] measure(), device is ready, ask device to start measuring."
          );
          flow.write(startMeasureFIFO);
        }
      });

      flow.subscribe(SCANNER_MOVEMENT_DETECTED, async (message) => {
        if (message.payload[0] === 1) {
          // 1 means one byte indicating true
          LogService.error(
            new Error(
              `${MEASURE} : Please keep device stable during measurement.`
            )
          );

          await flow.close();

          reject(
            new Error(
              `${MEASURE} : Please keep device stable during measurement`
            )
          );
        }
      });
      const isDeviceReady = buildMessageFIFO(READ_DEVICE_READY_STATUS_REQUEST);
      LogService.log({ isDeviceReady });
      flow.write(isDeviceReady);
    } catch (e) {
      LogService.log("CATCH create flow control ", e);
    }
  });

export { measure };
export default measure;
