import { SelectChangeEvent } from "@mui/material";
import PredictionValue from "../../types/contracts/PredictionValue";

// CONSTANTS

// TYPES
export enum CellTypes {
  COORDS = "coords",
  LOCATION = "location",
  DATETIME = "datetime",
  EXPIRED = "expired",
  LASTUSE = "lastuse",
  RESULT = "result",
  STRING = "string",
  SELECT = "select",
}

export type CoordsValue = {
  north: string;
  west: string;
};

export interface LocationValue extends CoordsValue {
  municipality: string | undefined;
  region: string | undefined;
  country: string | undefined;
}

export type ExpiredValue = {
  isValid: boolean;
  limit: number;
  date: string;
  status?: string;
  statusMessage?: string;
  serialNumber?: string;
};

export type LastUseValue = {
  date: Date;
  user: string;
};

export type ResultValue = {
  value: PredictionValue;
};

interface CellType {
  key: string;
  readonly stringValue?: string;
}

interface CoordsCellType extends CellType {
  readonly type: CellTypes.COORDS;
  readonly value: CoordsValue;
}

interface LocationCellType extends CellType {
  readonly type: CellTypes.LOCATION;
  readonly value: LocationValue;
}

interface DateTimeCellType extends CellType {
  readonly type: CellTypes.DATETIME;
  readonly format?: string;
  readonly value: Date;
}

interface DateWithUserCellType extends CellType {
  readonly type: CellTypes.LASTUSE;
  readonly value: LastUseValue;
}

interface ExpiresCellType extends CellType {
  readonly type: CellTypes.EXPIRED;
  readonly value: ExpiredValue;
}

interface ResultCellType extends CellType {
  readonly type: CellTypes.RESULT;
  readonly selectColor?: (value: string) => string;
  readonly value: string;
}

interface StringCellType extends CellType {
  readonly type: CellTypes.STRING;
  readonly value: string;
}

interface SelectCellType extends CellType {
  readonly type: CellTypes.SELECT;
  readonly value: string;
  readonly options: Array<{ value: string | number; label: string }>;
  readonly onChange: (event: SelectChangeEvent) => void;
}

export type TableCellType = {
  extra?: JSX.Element;
} & (
  | CoordsCellType
  | ExpiresCellType
  | DateTimeCellType
  | DateWithUserCellType
  | ResultCellType
  | SelectCellType
  | StringCellType
  | LocationCellType
);

export type TableCellTypeArray = TableCellType[][];

export type TableDataRow<T> = {
  data: T;
  id: string;
  row: TableCellType[];
};

export type TableDataArray<T> = TableDataRow<T>[];

export type SortingParameter = {
  setSortBy: (column: number) => void;
  sortables: { index: number; direction: "asc" | "desc" }[];
  sortBy: number;
};

export type FilteringParameter = {
  selectedKey: string;
  searchValue: string;
};

export type TableSnapShot = {
  page: string;
  rowsOnPage: string;
  sorting: string[];
};

export type TableCallbackFn = <T>(data: T, snapshot: TableSnapShot) => void;

export type DataTableHeaderType = {
  key: string;
  label: string;
};
