import {
  CrudOperation,
  PermissionKey,
  ResourceService,
  ResourceType,
  WildcardOptions,
} from "./types-permission";

export const measurementStatusChange: PermissionKey = {
  objectId: WildcardOptions.All,
  operation: CrudOperation.Write,
  service: ResourceService.Authentifield,
  type: ResourceType.ResultMitigation,
};

export const readMeasurementDetails: PermissionKey = {
  objectId: WildcardOptions.All,
  operation: CrudOperation.Read,
  service: ResourceService.Authentifield,
  type: ResourceType.ResultMitigation,
};

export const readMeasurementHistory: PermissionKey = {
  objectId: WildcardOptions.All,
  operation: CrudOperation.Read,
  service: ResourceService.Authentifield,
  type: ResourceType.DrugAuthentication,
};

export const readMeasurementSpectra: PermissionKey = {
  objectId: WildcardOptions.All,
  operation: CrudOperation.Read,
  service: ResourceService.Authentifield,
  type: ResourceType.DrugAuthentication,
};

export const readProductLibrary: PermissionKey = {
  objectId: WildcardOptions.All,
  operation: CrudOperation.Read,
  service: ResourceService.Authentifield,
  type: ResourceType.ProductLibrary,
};

export const createProductLibrary: PermissionKey = {
  objectId: WildcardOptions.All,
  operation: CrudOperation.Create,
  service: ResourceService.Authentifield,
  type: ResourceType.ProductLibrary,
};

export const readSensorManagement: PermissionKey = {
  objectId: WildcardOptions.All,
  operation: CrudOperation.Read,
  service: ResourceService.Authentifield,
  type: ResourceType.DeviceList,
};

export const writeMeasurementNote: PermissionKey = {
  objectId: WildcardOptions.All,
  operation: CrudOperation.Write,
  service: ResourceService.Authentifield,
  type: ResourceType.DrugAuthentication,
};

export const writeLibraryPlanningApproval: PermissionKey = {
  objectId: WildcardOptions.All,
  operation: CrudOperation.Write,
  service: ResourceService.Authentifield,
  type: ResourceType.ProductLibraryPlanningApproval,
};

export const writeLibraryPlanningRequest: PermissionKey = {
  objectId: WildcardOptions.All,
  operation: CrudOperation.Write,
  service: ResourceService.Authentifield,
  type: ResourceType.ProductLibraryPlanningRequest,
};

export const writeProductLibrary: PermissionKey = {
  objectId: WildcardOptions.All,
  operation: CrudOperation.Write,
  service: ResourceService.Authentifield,
  type: ResourceType.ProductLibrary,
};

export const createAuthentifieldSnapshot: PermissionKey = {
  objectId: WildcardOptions.All,
  operation: CrudOperation.Create,
  service: ResourceService.Authentifield,
  type: ResourceType.Snapshot,
};

export const approveOrRejectSnapshot: PermissionKey = {
  objectId: WildcardOptions.All,
  operation: CrudOperation.Write,
  service: ResourceService.Authentifield,
  type: ResourceType.ProductLibrary,
};

export const cancelModelTraining: PermissionKey = {
  objectId: WildcardOptions.All,
  operation: CrudOperation.Write,
  service: ResourceService.Authentifield,
  type: ResourceType.Modeling,
};

export const readAuditTrailReport: PermissionKey = {
  objectId: WildcardOptions.All,
  operation: CrudOperation.Read,
  service: ResourceService.Authentifield,
  type: ResourceType.AuditTrail,
};

export const readAuthentifieldDummyProduct: PermissionKey = {
  objectId: WildcardOptions.All,
  operation: CrudOperation.Read,
  service: ResourceService.Authentifield,
  type: ResourceType.DummyProductVariant,
};

export const readAuthentifieldProductLibraryPlanning: PermissionKey = {
  objectId: WildcardOptions.All,
  operation: CrudOperation.Read,
  service: ResourceService.Authentifield,
  type: ResourceType.ProductLibraryPlanning,
};

export const readAuthentifieldProductLibraryTraining: PermissionKey = {
  objectId: WildcardOptions.All,
  operation: CrudOperation.Read,
  service: ResourceService.Authentifield,
  type: ResourceType.ProductLibraryTraining,
};

export const readAuthentifieldProductLibraryModels: PermissionKey = {
  objectId: WildcardOptions.All,
  operation: CrudOperation.Read,
  service: ResourceService.Authentifield,
  type: ResourceType.ProductLibraryModels,
};

export const readLibraryModellingApproval: PermissionKey = {
  objectId: WildcardOptions.All,
  operation: CrudOperation.Read,
  service: ResourceService.Authentifield,
  type: ResourceType.ModellingApproval,
};

export const writeLibraryModellingApproval: PermissionKey = {
  objectId: WildcardOptions.All,
  operation: CrudOperation.Write,
  service: ResourceService.Authentifield,
  type: ResourceType.ModellingApproval,
};

export const readModelling: PermissionKey = {
  objectId: WildcardOptions.All,
  operation: CrudOperation.Read,
  service: ResourceService.Authentifield,
  type: ResourceType.Modeling,
};

export const writeModelling: PermissionKey = {
  objectId: WildcardOptions.All,
  operation: CrudOperation.Write,
  service: ResourceService.Authentifield,
  type: ResourceType.Modeling,
};

export const createModelling: PermissionKey = {
  objectId: WildcardOptions.All,
  operation: CrudOperation.Create,
  service: ResourceService.Authentifield,
  type: ResourceType.Modeling,
};

export const readResults: PermissionKey = {
  objectId: WildcardOptions.All,
  operation: CrudOperation.Read,
  service: ResourceService.Authentifield,
  type: ResourceType.Results,
};

export const readSnapshots: PermissionKey = {
  objectId: WildcardOptions.All,
  operation: CrudOperation.Read,
  service: ResourceService.Authentifield,
  type: ResourceType.Snapshot,
};

export const readWebPortal: PermissionKey = {
  objectId: WildcardOptions.All,
  operation: CrudOperation.Read,
  service: ResourceService.Authentifield,
  type: ResourceType.WebPortal,
};

export const readMobileApp: PermissionKey = {
  objectId: WildcardOptions.All,
  operation: CrudOperation.Read,
  service: ResourceService.Authentifield,
  type: ResourceType.MobileApp,
};

export const readLabscanner: PermissionKey = {
  objectId: WildcardOptions.All,
  operation: CrudOperation.Read,
  service: ResourceService.Authentifield,
  type: ResourceType.Labscanner,
};

export const importDevice: PermissionKey = {
  objectId: WildcardOptions.All,
  operation: CrudOperation.Create,
  service: ResourceService.Authentifield,
  type: ResourceType.Device,
};

export const readDeviceUpdate: PermissionKey = {
  objectId: WildcardOptions.All,
  operation: CrudOperation.Read,
  service: ResourceService.Authentifield,
  type: ResourceType.DeviceUpdate,
};

export const writeDeviceUpdate: PermissionKey = {
  objectId: WildcardOptions.All,
  operation: CrudOperation.Write,
  service: ResourceService.Authentifield,
  type: ResourceType.DeviceUpdate,
};
