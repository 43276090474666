import React, { ReactNode, useEffect, useMemo, useState } from "react";
import styled, { css, keyframes } from "styled-components";
import Checkbox from "@mui/material/Checkbox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";
import {
  Button as MuiButton,
  TextField,
  MenuItem,
  FormControlLabel,
  Switch,
  Button,
} from "@mui/material";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";

import {
  PlanningRow,
  PlanningRowStatus,
  PlanningAction,
  PermissionRequired,
  PermissionKeys,
  usePermissionChecking,
} from "lib-core";
import LibraryTable, {
  LibraryTableHeaders,
} from "../components/LibraryTable/LibraryTable";
import { TableRowSet } from "../components/LibraryTable/LibraryTableBody";

import {
  referenceMaterialHeaders,
  ReferenceMaterialWithCheckbox,
} from "./helpers";

import useProductVariantPlanning from "./productVariantPlanning-hook";

import ActionContainer from "./ActionContainer";
import LibrarySubheader from "./LibrarySubheader";
import useProductVariant, {
  useSelectedProductVariantVersions,
} from "./productVariant-hook";
import LibraryBanner from "./LibraryBanner";

const PlanningTabView = styled.div`
  display: flex;
  flex-direction: column;
`;

const Actions = styled.div`
  display: flex;
  gap: 1rem;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 1em;
`;

const BlueText = styled.div`
  margin-bottom: 10px;
  color: ${({ theme }) => theme.palette.primary.main};
  display: flex;
  font-size: 18px;
  font-weight: bold;
  margin-left: 10px;
`;

const FlexWrap = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: space-around;
`;

const outlineKeyframes = keyframes` from { outline-offset: 0; } to { outline-offset: 5px; }`;

const AnimatedBigButton = styled(MuiButton)<{ $highlightAnimation: boolean }>`
  min-width: 180px;
  height: 40px;
  align-items: center;
  padding: 0 0;
  ${({ $highlightAnimation, theme }) =>
    $highlightAnimation
      ? css`
          outline: 3px solid ${theme.palette.primary.main}80;
          animation: ${outlineKeyframes} 1s linear infinite;
          animation-direction: alternate;
        `
      : ""}
`;

const BigButton = styled(MuiButton)`
  min-width: 180px;
  height: 40px;
  align-items: center;
  padding: 0 0;
`;

const TextBox = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const Title = styled.h3`
  margin: 0 0;
  margin-bottom: 10px;
  margin-left: 10px;
  display: flex;
`;

const InputFields = styled(TextField)`
  margin-right: 15px;
  min-width: 220px;
`;

const ChangedValue = styled.span`
  display: flex;
  justify-items: center;

  svg {
    margin-top: 2px;
  }
`;

// Colspan count minus checkbox fo checking all
// for subheader th cell to cover full length
const HEADER_COLSPAN = 11;

// There is limitation what status can be requested for planning row
// Draft -> Released -> Decommisioned, so in Draft status Released is next possible
const pathOfStatuses: { [PlanningRowStatus: string]: PlanningRowStatus } = {
  [PlanningRowStatus.DRAFT]: PlanningRowStatus.RELEASED,
  [PlanningRowStatus.RELEASED]: PlanningRowStatus.DECOMMISSIONED,
};

// Permissions
const { writeLibraryPlanningApproval, writeLibraryPlanningRequest } =
  PermissionKeys;

const PlanningTab: () => JSX.Element = () => {
  const [selected, setSelected] = useState<number[]>([]);
  const [refMaterialStatus, setRefMaterialStatus] = useState("");
  const [refMaterialProtocol, setProtocol] = useState("");

  const { productVariant, refetchSelectedProductVariant } = useProductVariant();

  const [refMaterialRowsStatus, setRefMaterialRowsStatus] = useState<
    Record<number, Record<keyof PlanningRow, unknown>>
  >({});
  const [refMaterialMeasurements, setRefMaterialMeasurements] = useState("");

  const [refMaterialMeasurementChange, setRefMaterialMeasurementChange] =
    useState("");

  const [typeOfSelected, setTypeOfSelected] = useState<PlanningRowStatus>();

  const [approvals, setApprovals] = useState<number>(0);
  const [decommissions, setDecommissions] = useState<number>(0);
  const [showApprovalButton, setShowApprovalButton] = useState<boolean>(false);
  const [showDecommissioned, setShowDecommissioned] = useState<boolean>(false);
  const [shouldAnimate, setShouldAnimate] = useState<boolean>(false);

  const requirePermission = usePermissionChecking();

  const allowPlanningApproval = requirePermission([
    writeLibraryPlanningApproval,
  ]);
  const allowPlanningRequest = requirePermission([writeLibraryPlanningRequest]);

  const {
    loading,
    fetchPlanningRows,
    productVariantPlanningRows,
    pendingChanges,
    sendProductVariantPlanningChangeRequest,
    changeLoading,
    fetchProtocols,
    protocols,
  } = useProductVariantPlanning();

  const { versions } = useSelectedProductVariantVersions();

  const isAnyRowHasNewerVersion = (() => {
    if (!versions) {
      return false;
    }
    if (productVariantPlanningRows.length === 0) {
      return false;
    }
    return productVariantPlanningRows.some((row) => {
      const currentVersion = row.ver;
      const newVersion = versions.dataSets[row.rowId];
      return newVersion !== currentVersion;
    });
  })();

  const handleRefreshPlanningTab = () => {
    refetchSelectedProductVariant();
  };

  const clearAllValues = () => {
    setSelected([]);
    setProtocol("");
    setRefMaterialStatus("");
    setRefMaterialRowsStatus({});
    setRefMaterialMeasurements("");
    setRefMaterialMeasurementChange("");
    setTypeOfSelected(undefined);
    setApprovals(0);
    setDecommissions(0);
    setShowApprovalButton(false);
  };

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    if (
      (refMaterialProtocol || refMaterialMeasurements || refMaterialStatus) &&
      !showApprovalButton
    ) {
      timeoutId = setTimeout(() => {
        setShouldAnimate(true);
      }, 5000);
    }
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [
    refMaterialProtocol,
    refMaterialMeasurements,
    refMaterialStatus,
    showApprovalButton,
  ]);

  useEffect(() => {
    clearAllValues();
    if (productVariant?.id) {
      fetchPlanningRows(productVariant.id);
    }
    fetchProtocols();
  }, [productVariant]); // eslint-disable-line react-hooks/exhaustive-deps

  // Reset selected type to release all types to selectable
  useEffect(() => {
    if (selected.length === 0) {
      setTypeOfSelected(undefined);
    }
  }, [selected]);

  const onCheckboxChange = (ids: number[]) => {
    const selectedState = [...selected];
    ids.forEach((id) => {
      if (selectedState.includes(id)) {
        selectedState.splice(selectedState.indexOf(id), 1);
      } else {
        selectedState.push(id);
      }
    });
    setSelected(selectedState);
  };

  const onAllCheckboxChange = (rows: PlanningRow[]) => {
    const ids = rows.map((planningRow) => planningRow.rowId);
    // Is all in given row array selected
    const allChecked = ids.every((id) => selected.includes(id));
    const selectedState = [...selected];
    if (!allChecked) {
      // Remove already selected
      ids.forEach((id) => {
        if (selectedState.indexOf(id) > -1) {
          selectedState.splice(selectedState.indexOf(id), 1);
        }
      });
      // Set other selected and given rows selected
      setSelected([...selectedState, ...ids]);
    } else {
      clearAllValues();
      // Remove all selected
      ids.forEach((id) => {
        selectedState.splice(selectedState.indexOf(id), 1);
      });
      setSelected([...selectedState]);
    }
  };

  // Helper function to check if some of given rows are selected
  const isSomeChecked = (rows: PlanningRow[]) =>
    rows.map((row) => row.rowId).some((id) => selected.includes(id));

  // Helper function to check if all of given rows are selected
  const isAllChecked = (rows: PlanningRow[]) =>
    rows.length > 0 &&
    rows.map((row) => row.rowId).every((id) => selected.includes(id));

  // Helper function to check if released rows are selected
  const isReleasedChecked = (rows: PlanningRow[]) =>
    rows.some(
      (row) => row.status === "released" && selected.includes(row.rowId)
    );

  // Is given row selected
  const isRowSelected = (row: ReferenceMaterialWithCheckbox) => {
    return selected.includes(row.rowId);
  };

  // Managers acceptance process button action
  const onApprovalButtonClick = (action: PlanningAction) => {
    if (productVariant)
      sendProductVariantPlanningChangeRequest(productVariant.id, {
        ver: productVariant.ver,
        action,
        // Reduce manufacturingSiteUpdates object from selected rows.
        // Object format is {rowId: <object>} where currently per row
        // can be changed status and protocol. i.e {status: "released"}
        manufacturingSiteUpdates: selected.reduce((acc, curr) => {
          const selectedRow = productVariantPlanningRows.find(
            (r) => r.rowId === curr
          );
          return selectedRow
            ? {
                ...acc,
                [selectedRow.rowId]: { status: selectedRow.pendingStatus },
              }
            : acc;
        }, {}),
        ...(pendingChanges?.numberOfMeasurementsPerBatch !== null
          ? {
              nMeasurementsPerBatch:
                pendingChanges?.numberOfMeasurementsPerBatch,
            }
          : {}),
        ...(pendingChanges?.protocolName !== null
          ? {
              protocolName: pendingChanges?.protocolName,
            }
          : {}),
      }).then(() => {
        clearAllValues();
        refetchSelectedProductVariant();
      });
  };

  // RowChanges is per row change and other changes are in product variant level
  const onRowModifyButtonClick = () => {
    if (selected.length > 0) {
      setShouldAnimate(false);
      setShowApprovalButton(!showApprovalButton);
      const rowsForModification = selected.reduce((acc, curr) => {
        const selectedRow = productVariantPlanningRows.find(
          (r) => r.rowId === curr
        );
        return selectedRow
          ? { ...acc, [selectedRow.rowId]: { status: refMaterialStatus } }
          : acc;
      }, {});
      if (refMaterialStatus === "decommissioned") {
        setDecommissions(selected.length);
      }
      if (refMaterialStatus === "released") {
        setApprovals(selected.length);
      }
      setRefMaterialRowsStatus(rowsForModification);
    }

    // Set measurement amount to state
    if (refMaterialMeasurements !== undefined) {
      setRefMaterialMeasurementChange(refMaterialMeasurements);
    }
  };

  // Send actual change request to API
  const onRequestApprovalBtnClick = () => {
    if (productVariant)
      sendProductVariantPlanningChangeRequest(productVariant.id, {
        ver: productVariant.ver,
        action: PlanningAction.REQUEST,
        manufacturingSiteUpdates: refMaterialRowsStatus,
        ...(refMaterialMeasurementChange && refMaterialMeasurementChange !== ""
          ? { nMeasurementsPerBatch: refMaterialMeasurementChange }
          : {}),
        ...(refMaterialProtocol && refMaterialProtocol !== ""
          ? { protocolName: refMaterialProtocol }
          : {}),
      }).then(() => {
        clearAllValues();
        refetchSelectedProductVariant();
      });
  };

  // Sub selection checkbox for (un)selecting all in set
  const checkAllCheckboxLabel = (
    rows: PlanningRow[],
    type: PlanningRowStatus
  ) => {
    return {
      key: "checkbox",
      label: (
        <Checkbox
          icon={<CheckBoxOutlineBlankIcon />}
          checkedIcon={<CheckBoxIcon />}
          indeterminateIcon={<IndeterminateCheckBoxIcon />}
          checked={isAllChecked(rows)}
          indeterminate={isSomeChecked(rows) && !isAllChecked(rows)}
          onChange={() => {
            onAllCheckboxChange(rows);
            setTypeOfSelected(type);
          }}
          disabled={
            rows.length === 0 ||
            (type !== PlanningRowStatus.IN_APPROVAL && allowPlanningApproval) ||
            (type === PlanningRowStatus.IN_APPROVAL && allowPlanningRequest) ||
            type === PlanningRowStatus.DECOMMISSIONED ||
            (type !== PlanningRowStatus.IN_APPROVAL &&
              typeOfSelected &&
              typeOfSelected !== type)
          }
        />
      ),
    };
  };

  // Set rowsets
  const statuses: [PlanningRowStatus, string][] = [
    [PlanningRowStatus.IN_APPROVAL, "Pending requests"],
    [PlanningRowStatus.DRAFT, "Draft"],
    [PlanningRowStatus.RELEASED, "Released"],
    [PlanningRowStatus.DECOMMISSIONED, "Decommissioned"],
  ];

  // If the "show decommissioned" switch is toggled on, show everything
  const filteredStatuses = statuses.filter((status) => {
    if (!showDecommissioned && status[0] === PlanningRowStatus.DECOMMISSIONED) {
      return null;
    }
    return status;
  });

  const getCheckbox = (
    row: PlanningRow,
    status: PlanningRowStatus,
    selectionType: PlanningRowStatus | undefined
  ) => (
    <Checkbox
      checked={selected.includes(row.rowId)}
      onChange={() => {
        onCheckboxChange([row.rowId]);
        setTypeOfSelected(row.status);
      }}
      disabled={
        (status !== PlanningRowStatus.IN_APPROVAL &&
          status === PlanningRowStatus.DECOMMISSIONED) ||
        (selectionType && selectionType !== row.status) ||
        (status !== PlanningRowStatus.IN_APPROVAL && allowPlanningApproval) ||
        (status === PlanningRowStatus.IN_APPROVAL && allowPlanningRequest)
      }
    />
  );

  // Indicate value change with arrow from old or return unchanged value
  const changedValueOrUnchanged = (
    changeAvailable: boolean,
    oldValue: ReactNode,
    newValue: ReactNode
  ) =>
    changeAvailable ? (
      <ChangedValue>
        {oldValue}
        <ArrowRightAltIcon sx={{ fontSize: 16 }} />
        {newValue}
      </ChangedValue>
    ) : (
      oldValue
    );

  // Check if there is pending change in protocol in API response or in state from input field
  const productVariantProtocolChange = (row: PlanningRow) => {
    const { protocolName } = row;

    const protocolChangeAvailable =
      pendingChanges?.protocolName !== null || refMaterialProtocol !== "";

    const protocolChange = pendingChanges?.protocolName || refMaterialProtocol;

    return changedValueOrUnchanged(
      protocolChangeAvailable,
      protocolName,
      protocolChange
    );
  };

  // Check if there is pending change in measurement amount in API response or in state from input field
  const productVariantMeasurementAmountChange = (row: PlanningRow) => {
    const { numberOfMeasurementsPerBatch } = row;

    const measurementChangeAvailable =
      pendingChanges?.numberOfMeasurementsPerBatch !== null ||
      refMaterialMeasurementChange !== "";

    const measurementChange =
      pendingChanges?.numberOfMeasurementsPerBatch ||
      refMaterialMeasurementChange;

    return changedValueOrUnchanged(
      measurementChangeAvailable,
      numberOfMeasurementsPerBatch,
      measurementChange
    );
  };

  // Check if there is pending change in status in API response or in state from select
  const rowStatusChange = (row: PlanningRow) => {
    const { status, pendingStatus, rowId } = row;

    const newRequestStatusAvailable = Object.keys(
      refMaterialRowsStatus
    ).includes(`${rowId}`);

    const newRequestStatus = newRequestStatusAvailable
      ? (refMaterialRowsStatus[rowId]?.status as unknown as string)
      : "";

    const newStatusAvailable =
      status !== pendingStatus || newRequestStatusAvailable;

    return changedValueOrUnchanged(
      newStatusAvailable,
      status,
      status !== pendingStatus ? pendingStatus : newRequestStatus
    );
  };

  // Rows with checkbox and possible change indicators
  const getRows = (rows: PlanningRow[], status: PlanningRowStatus) =>
    rows.map((planningRow) => {
      return {
        ...planningRow,
        numberOfMeasurementsPerBatch:
          productVariantMeasurementAmountChange(planningRow),
        status: rowStatusChange(planningRow),
        protocolName: productVariantProtocolChange(planningRow),
        checkbox: getCheckbox(planningRow, status, typeOfSelected),
        primaryPackaging: productVariant?.metadata.measureMode,
        variantName: productVariant?.name,
        localBrands: planningRow.localBrands.join(", "),
      };
    });

  // Map statuses to rows and set subheader label for each of them with checkbox to select all in that status
  const rowSets: TableRowSet<ReferenceMaterialWithCheckbox>[] =
    filteredStatuses.map(([status, title]) => {
      const rows = (productVariantPlanningRows ?? []).filter((row) => {
        const { status: rowStatus, pendingStatus: rowPendingStatus } = row;
        return (
          (rowStatus !== rowPendingStatus
            ? PlanningRowStatus.IN_APPROVAL
            : rowStatus) === status
        );
      });

      return {
        label: [
          { ...checkAllCheckboxLabel(rows, status), padding: "checkbox" },
          {
            label: `${title} (${rows.length})`,
            colspan: HEADER_COLSPAN,
          },
        ] as LibraryTableHeaders<Record<string, number>>,
        rows: getRows(rows, status),
      };
    });

  // There is limitations of statuses for rows, only next possible status can be available
  // Draft -> Released -> Decommisioned, so i.e. with Draft status Released is only next possible status
  const rowStatusOptions: string[][] = [
    [PlanningRowStatus.RELEASED, "Released"],
    [PlanningRowStatus.DECOMMISSIONED, "Decommissioned"],
  ].filter(
    (option) => typeOfSelected && option[0] === pathOfStatuses[typeOfSelected]
  );

  const managerApprovalDisabled =
    changeLoading ||
    (selected.length === 0 &&
      pendingChanges?.protocolName === null &&
      pendingChanges?.numberOfMeasurementsPerBatch === null);

  const handleChange = () => {
    setShowDecommissioned(!showDecommissioned);
  };

  const refSwitch = () => {
    return <Switch onChange={handleChange} checked={showDecommissioned} />;
  };

  const rowCount = useMemo(() => {
    let count = 0;
    productVariantPlanningRows.forEach(() => {
      count += 1;
    });
    return count;
  }, [productVariantPlanningRows]);

  return (
    <PlanningTabView>
      {isAnyRowHasNewerVersion && (
        <LibraryBanner
          action={
            <Button onClick={() => handleRefreshPlanningTab()}>
              Refresh page
            </Button>
          }
          message="This product variant has been updated. Please refresh the page."
        />
      )}
      <LibrarySubheader
        loading={loading}
        loadingTitle="Loading reference materials..."
        title={
          rowCount === 1
            ? "1 reference material imported"
            : `${rowCount} reference materials imported`
        }
        buttons={
          <FormControlLabel
            control={refSwitch()}
            label="Show decommissioned reference materials"
          />
        }
      />

      <LibraryTable
        headers={referenceMaterialHeaders}
        rowSets={rowSets}
        isRowSelected={isRowSelected}
        loading={loading}
      />

      <PermissionRequired
        hidden
        permissionKeys={[writeLibraryPlanningApproval]}
      >
        <Actions>
          <ActionContainer withPointer={Boolean(true)}>
            <BlueText>{selected.length} items selected</BlueText>
            <FlexWrap>
              <BigButton
                onClick={() => onApprovalButtonClick(PlanningAction.APPROVE)}
                disabled={managerApprovalDisabled}
              >
                Approve changes
              </BigButton>
              <BigButton
                variant="outlined"
                onClick={() => onApprovalButtonClick(PlanningAction.REJECT)}
                disabled={managerApprovalDisabled}
              >
                Reject changes
              </BigButton>
            </FlexWrap>
          </ActionContainer>
        </Actions>
      </PermissionRequired>
      <PermissionRequired hidden permissionKeys={[writeLibraryPlanningRequest]}>
        <Actions>
          <ActionContainer withPointer={Boolean(true)}>
            <BlueText>{selected.length} items selected</BlueText>
            <FlexWrap>
              <InputFields
                onChange={(event) => {
                  setRefMaterialStatus(event.target.value as unknown as string);
                }}
                value={refMaterialStatus}
                label="Status"
                size="small"
                disabled={selected.length === 0}
                select
              >
                {rowStatusOptions.map(([status, label]) => (
                  <MenuItem key={status} value={status}>
                    {label}
                  </MenuItem>
                ))}
              </InputFields>

              <InputFields
                onChange={(event) => {
                  setProtocol(event.target.value as unknown as string);
                }}
                value={refMaterialProtocol}
                label="Protocol"
                size="small"
                disabled={
                  selected.length === 0 ||
                  isReleasedChecked(productVariantPlanningRows)
                }
                select
              >
                {protocols.map(({ name }) => (
                  <MenuItem key={name} value={name}>
                    {name}
                  </MenuItem>
                ))}
              </InputFields>

              <InputFields
                aria-label="Number of measurements"
                inputProps={{ "data-testid": `abcd-input` }}
                onChange={(ev) =>
                  setRefMaterialMeasurements(ev.currentTarget.value)
                }
                label="Number of measurements"
                value={refMaterialMeasurements}
                disabled={
                  selected.length === 0 ||
                  isReleasedChecked(productVariantPlanningRows)
                }
              />
              <AnimatedBigButton
                onClick={onRowModifyButtonClick}
                disabled={changeLoading}
                $highlightAnimation={shouldAnimate}
              >
                Apply changes
              </AnimatedBigButton>
            </FlexWrap>
          </ActionContainer>
          <ActionContainer>
            <Title>New approvals and decommissions</Title>
            <FlexWrap>
              <div>
                <TextBox>{approvals} item(s) to approve</TextBox>
                <TextBox>{decommissions} item(s) to decommission</TextBox>
              </div>
              <BigButton
                onClick={onRequestApprovalBtnClick}
                disabled={changeLoading || showApprovalButton === false}
              >
                Request approval
              </BigButton>
            </FlexWrap>
          </ActionContainer>
        </Actions>
      </PermissionRequired>
    </PlanningTabView>
  );
};

export default PlanningTab;
