import React from "react";
import { Link, useHistory } from "react-router-dom";

import { ButtonBase, Typography, styled } from "@mui/material";
import BackArrow from "@mui/icons-material/ArrowBackIosRounded";

type Route = {
  text: string | JSX.Element;
  route: string;
};

type CallToAction = Route | { element: JSX.Element };

interface StatusBarProps {
  backButton?: boolean;
  backButtonAction?: () => void;
  backButtonIcon?: JSX.Element;
  callToAction?: CallToAction;
  title: string;
  className?: string;
}

const Container = styled("header")`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  z-index: 2;
`;

const Title = styled(Typography)`
  margin: 0;
  font-size: inherit;
`;

const IconSlot = styled("div")`
  min-width: 3.5rem;
  text-align: center;
`;

const StatusBarButton = styled(ButtonBase)`
  font-size: inherit;

  svg {
    font-size: inherit;
  }
`;

const RenderBackButton: (
  props: Pick<
    StatusBarProps,
    "backButton" | "backButtonAction" | "backButtonIcon"
  >
) => JSX.Element | null = ({
  backButton,
  backButtonAction,
  backButtonIcon,
}) => {
  const history = useHistory();
  const defaultAction = () => {
    history.go(-1);
  };

  if (!backButton) {
    return null;
  }

  return (
    <StatusBarButton
      onClick={backButtonAction || defaultAction}
      className="StatusBar-button StatusBar-back-button"
    >
      {backButtonIcon}
    </StatusBarButton>
  );
};

const RenderCallToAction: (
  props: Pick<StatusBarProps, "callToAction">
) => JSX.Element | null = ({ callToAction }) => {
  if (!callToAction) {
    return null;
  }

  if ("element" in callToAction) {
    return callToAction.element;
  }

  const action = callToAction;
  return (
    <Link
      component={StatusBarButton}
      to={action.route}
      className="StatusBar-button StatusBar-action-button"
    >
      {action.text}
    </Link>
  );
};

const StatusBar: (props: StatusBarProps) => JSX.Element = ({
  backButton = false,
  backButtonAction,
  backButtonIcon = <BackArrow />,
  callToAction,
  title,
  className = "",
}) => {
  return (
    <Container className={`StatusBar ${className}`}>
      <IconSlot className="StatusBar-icon-slot StatusBar-icon-slot-before">
        <RenderBackButton
          backButton={backButton}
          backButtonAction={backButtonAction}
          backButtonIcon={backButtonIcon}
        />
      </IconSlot>
      <Title variant="h1" className="StatusBar-title">
        {title}
      </Title>
      <IconSlot className="StatusBar-icon-slot StatusBar-icon-slot-after">
        <RenderCallToAction callToAction={callToAction} />
      </IconSlot>
    </Container>
  );
};

export default StatusBar;
