import * as React from "react";
import CloseIcon from "@mui/icons-material/Close";
import Modal from "@mui/material/Modal";
import styled from "styled-components";

interface SimpleMobileImagePreviewProps {
  handleClose: () => void;
  image?: string;
  open: boolean;
}

const LargeImage = styled.img`
  width: 100%;
  object-fit: contain;
`;

const Close = styled(CloseIcon)`
  font-size: 35px;
  position: absolute;
  right: 25px;
  top: calc(25px + env(safe-area-inset-top));
  color: #ffffff;
`;

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.palette.grey[700]};
`;

const SimpleMobileImagePreview: (
  props: SimpleMobileImagePreviewProps
) => JSX.Element = ({ handleClose, image, open }) => (
  <Modal
    open={open}
    onClose={handleClose}
    aria-labelledby="modal-full-screen-photo"
    aria-describedby="modal-full-screen-photo"
  >
    <Container>
      <Close onClick={handleClose} />
      <LargeImage src={image} alt=" " />
    </Container>
  </Modal>
);

export default SimpleMobileImagePreview;
