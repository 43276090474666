import React, { useCallback, useEffect, useMemo, useState } from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { default as MuiPaper } from "@mui/material/Paper";

import { PagingData, Sorting } from "../../types/types-api";
import ListTableHeaders from "./ListTableHeaders";
import ListTableRows from "./ListTableRows";
import {
  TableCallbackFn,
  TableDataArray,
  DataTableHeaderType,
} from "./datatable-types";
import DataTableLoading from "./DataTableLoading";
import { KeyValueParams } from "../filter/datafilter-types";

interface DataTableProps<T> {
  headers: DataTableHeaderType[];
  onClick?: TableCallbackFn;
  rows: TableDataArray<T>;
  onPageChange?(page: number, perPage: number): void;
  pagination?: PagingData;
  rowsPerPageOptions?: number[];
  id?: string;
  onPerPageChange?(perPage: number): void;
  disableSorting?: boolean;
  onSorting?(sorting: Sorting[]): void;
  loading?: boolean;
  singleColumnSort?: boolean;
  filterValues?: KeyValueParams;
}

const Paper = styled(MuiPaper)`
  box-shadow: none;
`;

const DataTable: <T>(props: DataTableProps<T>) => JSX.Element = ({
  headers,
  onClick,
  rows,
  onPageChange,
  pagination,
  rowsPerPageOptions,
  id = "dataTable",
  onPerPageChange,
  onSorting,
  loading,
  singleColumnSort = false,
  filterValues,
  disableSorting,
}) => {
  const [sortInformation, setSortInformation] = useState<Sorting[]>([]);
  const [page, setPage] = useState(0);
  const [rowsOnPage, setRowsOnPage] = React.useState(10);

  /* PAGINATION */
  type ChangePageEvent = React.MouseEvent<HTMLButtonElement> | null;
  type ChangeRowsOnPageEvent = React.ChangeEvent<
    HTMLTextAreaElement | HTMLInputElement
  >;

  const changePage = useCallback((_: ChangePageEvent, number: number) => {
    setPage(number);
  }, []);

  const changeRowsOnPage = useCallback(
    (ev: ChangeRowsOnPageEvent) => {
      const number = parseInt(ev.target.value, 10);
      setRowsOnPage(number);
      setPage(0);
      if (onPerPageChange) {
        onPerPageChange(number);
      }
    },
    [onPerPageChange]
  );

  // Snapshot contains information about paging and sorting
  const snapshot = useMemo(() => {
    return {
      rowsOnPage: rowsOnPage.toString(),
      page: page.toString(),
      sorting: sortInformation.map((sort) => `${sort.direction}:${sort.key}`),
    };
  }, [page, rowsOnPage, sortInformation]);

  useEffect(() => {
    setPage(0);
  }, [filterValues]);

  useEffect(() => {
    if (onPageChange) {
      onPageChange(page, rowsOnPage);
    }
  }, [page, rowsOnPage]); // eslint-disable-line react-hooks/exhaustive-deps

  const changeOrAddSort = (
    sortArray: Sorting[],
    key: string,
    index: number
  ) => {
    const mutatedSortInformation = [...sortArray];
    if (mutatedSortInformation[index]) {
      const dir = mutatedSortInformation[index]?.direction;
      const keyInIndex = mutatedSortInformation[index]?.key;
      let direction: "asc" | "desc" = dir === "asc" ? "desc" : "asc";
      if (key !== keyInIndex) {
        direction = "asc";
      }

      mutatedSortInformation[index] = {
        key,
        direction,
      };
    } else {
      mutatedSortInformation.push({
        key,
        direction: "asc",
      });
    }

    return mutatedSortInformation;
  };

  // Set some header information about sorting.
  const onSort = (key: string, index: number) => {
    const mutatedSortInformation = changeOrAddSort(sortInformation, key, index);
    setSortInformation(mutatedSortInformation);
    if (onSorting) {
      onSorting(mutatedSortInformation);
    }
  };

  const multiColumnSorting = (key: string) => {
    const isSortedIndex = sortInformation.findIndex((s) => s.key === key);
    onSort(key, isSortedIndex);
  };

  const singleColumnSorting = (key: string) => {
    onSort(key, 0);
  };

  const resultCount = () => {
    if (rows.length < 1) return 0;
    return pagination?.total ? pagination.total : 0;
  };

  const count = resultCount();

  const SelectProps: React.ComponentProps<
    typeof TablePagination
  >["SelectProps"] = useMemo(() => {
    return {
      inputProps: {
        "aria-label": "rows per page",
      },
      // Self specify an id to avoid collision of id with other text input
      // causing screen jumping and wrong input focus
      id: `${id}-rows-per-page-table`,
    };
  }, [id]);

  return (
    <TableContainer component={Paper}>
      <Table id={id}>
        <ListTableHeaders
          headers={headers}
          sorting={disableSorting ? [] : sortInformation}
          onSort={singleColumnSort ? singleColumnSorting : multiColumnSorting}
        />
        <ListTableRows
          onClick={onClick}
          rows={rows}
          snapshot={snapshot}
          loading={loading}
        />
        <TableFooter>
          <TableRow>
            <DataTableLoading loading={loading} resultCount={count} />
            <TablePagination
              count={count}
              onPageChange={changePage}
              onRowsPerPageChange={changeRowsOnPage}
              rowsPerPage={rowsOnPage}
              page={page}
              rowsPerPageOptions={rowsPerPageOptions}
              SelectProps={SelectProps}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
};

export default DataTable;
