import React from "react";
import styled from "styled-components";

interface PageProps {
  children: React.ReactNode;
}

const Container = styled.main`
  display: flex;
  flex: 1 0 0;
`;

const Page: (props: PageProps) => JSX.Element = ({ children }) => (
  <Container>{children}</Container>
);

export default Page;
