import React, { useEffect } from "react";
import { Link as RouterLink, useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";
import AppBar from "@mui/material/AppBar";
import Button from "@mui/material/Button";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import PersonIcon from "@mui/icons-material/Person";

import { CurrentUserService, Constants } from "lib-core";
import { Typography } from "@mui/material";
import NovartisLogo from "./components/NovartisLogo";
import HeaderNavigation from "./components/navigation/HeaderNavigation";
import { useTokenRefresh } from "./router/TokenRefreshContext";

interface AppHeaderProps {
  headerText: string;
}

const Container = styled.header`
  display: flex;
  gap: 30px;
  padding: 20px 30px;
  color: ${({ theme }) => theme.palette.text.primary};
  width: 100%;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  border-bottom: 1px solid ${({ theme }) => theme.palette.brandGray.light};
  z-index: 2;
`;

const LogoContainer = styled.div``;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
`;

const UserActions = styled.div`
  padding-right: 5px;
  margin-right: 23px;
  border-right: 1px solid ${({ theme }) => theme.palette.brandGray.main};
`;

const Link = styled(RouterLink)`
  color: ${({ theme }) => theme.palette.text.primary};
  text-decoration: none;
  padding: 7px 15px;
  font-weight: 700;
  &.selected {
    background: ${({ theme }) => theme.palette.brandGray.light};
  }
`;

const UserMenuButton = styled(Button)`
  background: transparent;
  padding: 4px 0;
`;

const UserMenuArrow = styled(ArrowDropDownIcon)`
  margin-left: -5px;
`;

const AppHeader: (props: AppHeaderProps) => JSX.Element = ({ headerText }) => {
  // User information
  const currentUser = CurrentUserService.getCurrentUser();
  const { tokenRefresh, setTokenRefresh } = useTokenRefresh();
  const history = useHistory();

  useEffect(() => {
    if (tokenRefresh === true) {
      history.push("/home");
      setTokenRefresh(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokenRefresh, history, setTokenRefresh]);

  const [targetElement, setTargetElement] = React.useState<null | HTMLElement>(
    null
  ); // Open menu target
  // Location
  const location = useLocation();
  // Set target to be open
  const handleButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setTargetElement(event.currentTarget);
  };

  const open = Boolean(targetElement);

  const getPreviousPath = () => {
    if (location && location.pathname !== "/error-403") {
      return location.pathname;
    }
    return "/home";
  };

  const onLogoutClick = () => {
    // Set location to localstorage for nex login
    localStorage.setItem(Constants.previousPath, getPreviousPath());
  };

  return (
    <AppBar position="static">
      <Container>
        <LogoContainer>
          <NovartisLogo width="180" />
        </LogoContainer>
        <HeaderNavigation />
        <TitleContainer>
          {currentUser ? (
            <UserActions>
              <UserMenuButton
                aria-label="User actions menu"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleButtonClick}
                color="inherit"
              >
                <PersonIcon fontSize="small" />
                <UserMenuArrow fontSize="small" />
              </UserMenuButton>
              <Menu
                id="basic-menu"
                anchorEl={targetElement}
                open={open}
                onClose={() => setTargetElement(null)}
                MenuListProps={{ "aria-labelledby": "basic-button" }}
              >
                <MenuItem onClick={() => setTargetElement(null)}>
                  <Link to="/logout" onClick={onLogoutClick}>
                    Logout
                  </Link>
                </MenuItem>
              </Menu>
            </UserActions>
          ) : null}
          <Typography variant="h3">{headerText}</Typography>
        </TitleContainer>
      </Container>
    </AppBar>
  );
};

export default AppHeader;
