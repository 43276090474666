import { v4 as uuidv4 } from "uuid";
import axios from "../../AxiosInstance";
import { DrugAuthenticationRequest } from "../../types/contracts/DrugAuthenticationRequest";
import StoredMeasurementHistoryItem from "../../types/contracts/StoredMeasurementHistoryItem";
import Urls from "../Urls";
import { AssessmentSubmissionParameter } from "../../types/contracts/MeasurementAssessment";

const performAuthentication = async (
  requestData: DrugAuthenticationRequest
): Promise<StoredMeasurementHistoryItem> => {
  const measurementId = uuidv4();
  const response = await axios({
    method: "post",
    url: Urls.drugAuthentication(measurementId),
    data: requestData,
  });
  return response.data;
};

const submitAssessment = async (requestData: {
  body: AssessmentSubmissionParameter;
  params: {
    username: string;
    measurementId: string;
  };
}) => {
  const response = await axios({
    method: "post",
    url: Urls.addMeasurementAssessment(requestData.params),
    data: requestData.body,
  });
  return response.data;
};

const DrugAuthenticationService = {
  performAuthentication,
  submitAssessment,
};

export default DrugAuthenticationService;
