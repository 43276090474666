import { createFlow } from "./flow";
import { buildMessageFIFO } from "./message";
import { bytesToString } from "./bluetoothle";
import LogService from "../../services/LogService";
import {
  READ_DEVICE_INFO_REQUEST,
  READ_DEVICE_INFO_RESPONSE,
} from "./constants";
import { DeviceInfo } from "../../types/types-bluetooth";

const toString = (bytes: Uint8Array) => bytesToString(bytes);
const readDeviceInfo = (address: string): Promise<DeviceInfo> =>
  new Promise(async (resolve, reject) => {
    LogService.log("1 - open flow for readDeviceInfo");
    try {
      const flow = await createFlow(address, (e) =>
        reject(new Error(e.message))
      );

      flow.subscribe(READ_DEVICE_INFO_RESPONSE, async (message) => {
        const readingInUnit8Array = new Uint8Array(message.payload);
        const dataView = new DataView(readingInUnit8Array.buffer);

        flow.close().then((closed) => {
          if (closed) {
            LogService.log("3 - closed flow for readDeviceInfo");
            resolve({
              serialNumber: toString(readingInUnit8Array.slice(0, 11)),
              softwareVersion: toString(readingInUnit8Array.slice(11, 17)),
              nmFirmwareVersion: toString(readingInUnit8Array.slice(17, 27)),
              ubloxFirmwareVersion: toString(readingInUnit8Array.slice(27, 32)),
              batteryLevel: dataView.getInt8(32),
              inactivityDetectionTimer: dataView.getUint16(33),
              minWavelength: dataView.getUint16(35),
              maxWavelength: dataView.getUint16(37),
            });
          }
        });
      });

      const deviceInfo = buildMessageFIFO(READ_DEVICE_INFO_REQUEST);
      flow.write(deviceInfo);
    } catch (e) {
      LogService.error("CATCH createFlow ", e);
    }
  });

export default readDeviceInfo;
