import React, { useState } from "react";

import Button from "@mui/material/Button";
import Menu, { MenuProps } from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import DescriptionIcon from "@mui/icons-material/Description";
import Typography from "@mui/material/Typography";

import { PermissionKeys, PermissionRequired } from "lib-core";
import styled from "styled-components";

import CreateReportModal from "./CreateReportModal";

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))({});

const Audit: () => JSX.Element = () => {
  const { readAuditTrailReport } = PermissionKeys;
  const [isOpen, openModal] = useState(false);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const openCreateModal = () => {
    handleClose();
    openModal(true);
  };

  return (
    <PermissionRequired hidden permissionKeys={[readAuditTrailReport]}>
      <Button
        variant="outlined"
        disableElevation
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
      >
        Audit
      </Button>
      <StyledMenu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem onClick={openCreateModal} disableRipple>
          <DescriptionIcon fontSize="small" />
          <Typography sx={{ marginLeft: "5px" }}>
            Create new audit trail export
          </Typography>
        </MenuItem>
      </StyledMenu>
      {isOpen && (
        <CreateReportModal open={isOpen} onClose={() => openModal(false)} />
      )}
    </PermissionRequired>
  );
};
export default Audit;
