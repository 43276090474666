import {
  WildcardOptions,
  Permission,
  PermissionKey,
} from "../types/types-permission";
import Constants from "./Constants";

const getUserPermissions = (): Permission[] => {
  const permissions = localStorage.getItem(Constants.userPermissions);
  return permissions ? JSON.parse(permissions) : [];
};

const isSuperAdmin = (): boolean => {
  const allowedOps: string[] = [];
  const permissions = getUserPermissions();

  const filterOperations = (permission: Permission): boolean => {
    if (!allowedOps.includes(permission.operation)) {
      allowedOps.push(permission.operation);
      return true;
    }

    return false;
  };

  const filtered = permissions
    .filter(
      (permission) =>
        permission.resource.objectId === WildcardOptions.All &&
        permission.resource.service === WildcardOptions.All &&
        permission.resource.type === WildcardOptions.All
    )
    .filter(filterOperations);

  if (filtered.length >= 4) {
    return true;
  }

  return false;
};

const storeToStorage = (permissions: Permission[]): void => {
  localStorage.setItem(Constants.userPermissions, JSON.stringify(permissions));
};

const verify = (keys: PermissionKey[]): boolean => {
  if (isSuperAdmin() || keys.length === 0) {
    return true;
  }

  const permissions = getUserPermissions();
  const allAllowedPermissions: Permission[][] = [];

  keys.forEach((key) => {
    const { objectId, operation, service, type } = key;
    const allowed = permissions.filter(
      (permission) =>
        permission.resource.objectId === objectId &&
        permission.operation === operation &&
        permission.resource.service === service &&
        permission.resource.type === type
    );

    if (allowed.length > 0) {
      allAllowedPermissions.push(allowed);
    }
  });

  if (allAllowedPermissions.length === keys.length) {
    return true;
  }

  return false;
};

const PermissionService = {
  isSuperAdmin,
  storeToStorage,
  verify,
};

export default PermissionService;
