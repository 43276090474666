import React, { ReactNode } from "react";
import styled from "styled-components";
import {
  TableHead,
  TableRow,
  TableCell as MuiTableCell,
  TableCellProps,
} from "@mui/material";

export interface LibraryTableHeader<T> {
  key?: keyof T;
  label: ReactNode;
  colspan?: number;
  padding?: TableCellProps["padding"];
}
// TODO: possible sort functionality
interface LibraryTableHeadersType {
  headers: LibraryTableHeader<Record<string, number>>[];
  subheader?: boolean;
  loading?: boolean;
  empty?: boolean;
}

const TableCell = styled(MuiTableCell)(
  ({ theme }) => `
    &.subheader {
      background: ${theme.palette.brandGray.light};
      color: ${theme.palette.text.primary};
      border-bottom-color: ${theme.palette.brandGray.main};
      font-size: ${theme.typography.h4.fontSize};
      line-height: 1;
      transition: color 0.15s;

      &.loading,
      &.empty {
        color: ${theme.palette.brandGray.dark};
      }
    }
  `
);

const HeaderValue = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`;

const LibraryTableHeaders: (props: LibraryTableHeadersType) => JSX.Element = ({
  headers,
  subheader = false,
  loading = false,
  empty = false,
}) => {
  return (
    <TableHead>
      <TableRow>
        {headers.map((header, i) => (
          <TableCell
            key={i}
            colSpan={header.colspan}
            padding={header.padding}
            className={`
              ${subheader ? "subheader" : ""}
              ${loading ? "loading" : ""}
              ${empty ? "empty" : ""}
            `}
          >
            <HeaderValue>{header.label}</HeaderValue>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default LibraryTableHeaders;
