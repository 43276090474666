import React from "react";
import {
  List,
  ListItem,
  ListSubheader as MuiListSubheader,
  styled,
} from "@mui/material";
import ArrowForwardIosRounded from "@mui/icons-material/ArrowForwardIosRounded";
import { v4 as uuidv4 } from "uuid";
import { MobileTableProps } from "./mobiletable-types";
import KeyValueCell from "./KeyValueCell";
import PermissionRequired from "../permissions/RequirePermission";

const ListSubheader = styled(MuiListSubheader)`
  line-height: inherit;
  top: env(safe-area-inset-top);
`;

const MobileTable: (props: MobileTableProps) => JSX.Element = ({
  arrowIcon = <ArrowForwardIosRounded fontSize="inherit" />,
  className = "",
  items = [],
}) => {
  return (
    <List className={`MobileTable ${className}`} disablePadding>
      {items.map((item) => {
        const key = uuidv4();
        const content = <KeyValueCell item={item} arrowIcon={arrowIcon} />;

        switch (item.type) {
          case "header":
            return (
              <ListSubheader
                key={key}
                className="MobileTable-row MobileTable-row-header"
                disableGutters
              >
                {content}
              </ListSubheader>
            );
          case "link":
            return (
              <PermissionRequired
                permissionKeys={item.requiredPermissions}
                hidden
              >
                <ListItem
                  key={key}
                  className="MobileTable-row MobileTable-row-body"
                  disablePadding
                  disableGutters
                >
                  {content}
                </ListItem>
              </PermissionRequired>
            );
          default:
            return (
              <ListItem
                key={key}
                className="MobileTable-row MobileTable-row-body"
                disablePadding
                disableGutters
              >
                {content}
              </ListItem>
            );
        }
      })}
    </List>
  );
};

export default MobileTable;
