export enum AuditTrailDatabaseService {
  AccessControl = "AccessControl",
  Device = "Device",
  Experiment = "Experiment",
  History = "History",
  Product = "View",
}

export enum AuditTrailFileSizeUnit {
  MB = "MB",
  GB = "GB",
  KB = "KB",
  TB = "TB",
}
