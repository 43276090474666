import { TableCellType, TableDataArray, TableDataRow } from "./datatable-types";

export const merge = <T>(
  listA: TableDataArray<T>,
  listB: TableDataArray<T>
): TableDataArray<T> => {
  const merged: TableDataArray<T> = [];

  while (listA.length && listB.length) {
    const listRowA = listA[0] as TableDataRow<T>;
    const listRowB = listB[0] as TableDataRow<T>;

    const valueA = listRowA.row[0] as TableCellType;
    const valueB = listRowB.row[0] as TableCellType;

    if (valueA.value <= valueB.value) {
      merged.push(listA.shift() as TableDataRow<T>);
    } else {
      merged.push(listB.shift() as TableDataRow<T>);
    }
  }

  while (listA.length > 0) merged.push(listA.shift() as TableDataRow<T>);
  while (listB.length > 0) merged.push(listB.shift() as TableDataRow<T>);

  return merged;
};

export const sort = <T>(input: TableDataArray<T>): TableDataArray<T> => {
  if (input.length <= 1) return input;

  const listA = input.slice(0, Math.ceil(input.length / 2));
  const listB = input.slice(listA.length, input.length);

  return merge(sort(listA), sort(listB));
};
