import { ReactNode } from "react";
import { Model } from "lib-core";
import { LibraryTableHeaders } from "../components/LibraryTable/LibraryTable";

export interface PlanningRow {
  rowId: number;
  status: string;
  pendingStatus: string;
  protocolName: string;
  numberOfMeasurementsPerBatch: number;
  referenceMaterialId: string;
  materialNumber: string;
  globalBrand: string;
  localBrands: string[];
  variantName: string;
  primaryPackaging: string;
  manufacturingSite: string;
  batchNumber: string;
}

export interface ReferenceMaterialWithCheckbox extends PlanningRow {
  checkbox: ReactNode;
}

export interface ResultsRow {
  firstMeasurementAt: string;
  manufacturingSite: string;
  measurementCount: number;
  metadataUrl: string;
  status: string;
}

export interface TestResultWithButton extends ResultsRow {
  metadata: ReactNode;
  date: ReactNode;
}

export const testResultHeaders: LibraryTableHeaders<Record<string, number>> = [
  {
    key: "manufacturingSite",
    label: "Manufacturing site",
  },
  {
    key: "measurementCount",
    label: "Measurements",
  },
  {
    key: "date",
    label: "First measurement date",
  },
  {
    key: "metadata",
    label: "CSV metadata",
    padding: "none",
  },
];

export const referenceMaterialHeaders: LibraryTableHeaders<
  Record<string, number>
> = [
  {
    key: "checkbox",
    label: null,
  },
  {
    key: "status",
    label: "Status",
  },
  {
    key: "protocolName",
    label: "Protocol",
  },
  {
    key: "numberOfMeasurementsPerBatch",
    label: "Measurements",
  },
  {
    key: "referenceMaterialId",
    label: "Ref. Material ID",
  },
  {
    key: "materialNumber",
    label: "Material number",
  },
  {
    key: "localBrands",
    label: "Local brands",
  },
  {
    key: "libraryVariant",
    label: "Variant name",
  },
  {
    key: "model",
    label: "Model",
  },
  {
    key: "primaryPackaging",
    label: "Primary packaging",
  },
  {
    key: "manufacturingSite",
    label: "Manufacturing site",
  },
  {
    key: "batchNumber",
    label: "Batch number",
  },
];

export interface ModelWithButtons extends Model {
  actionButtons: ReactNode;
}

export const modelsHeaders: LibraryTableHeaders<Record<string, number>> = [
  {
    key: "modelExternalName",
    label: "Name",
  },
  {
    key: "modelVersionId",
    label: "Version",
  },
  {
    key: "modelStatus",
    label: "Status",
  },
  {
    key: "snapshots",
    label: "Snapshots",
  },
  {
    key: "actionButtons",
    label: "Actions",
    padding: "none",
  },
];

export const trainingHeaders: LibraryTableHeaders<Record<string, number>> = [
  {
    key: "modelExternalName",
    label: "Name",
  },
  {
    key: "modelVersionId",
    label: "Version",
  },
  {
    key: "modelStatus",
    label: "Status",
  },
  {
    key: "snapshots",
    label: "Snapshots",
  },
  {
    key: "actionButtons",
    label: "Actions",
    padding: "none",
  },
];
