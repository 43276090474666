import { AxiosResponse } from "axios";
import axios from "../../AxiosInstance";
import { AuditTrailPredictionResultSchema } from "../../models/contracts/auditTrail/auditTrail";
import { AuditTrailPredictionResult } from "../../types/contracts/AuditTrail";
import { HttpConfig } from "../../types/types-api";
import Urls from "../Urls";

const exportToCsv = async (
  queries: Record<string, string | string[]>
): Promise<Blob> => {
  const params = new URLSearchParams();
  if (queries) {
    Object.entries(queries).map(([param, value]) => {
      if (Array.isArray(value)) {
        return value.forEach((val) => params.append(param, val));
      }
      return params.append(param, value);
    });
  }

  const response: AxiosResponse<Blob> = await axios({
    method: "get",
    params,
    url: Urls.extAuditTrailExportToCsv(),
    responseType: "blob",
  });

  return response.data;
};

const exportToCsvPrediction = async (
  queries: Record<string, string | string[]>
): Promise<AuditTrailPredictionResult> => {
  const params = new URLSearchParams();
  if (queries) {
    Object.entries(queries).map(([param, value]) => {
      if (Array.isArray(value)) {
        return value.forEach((val) => params.append(param, val));
      }

      if (value) {
        return params.append(param, value);
      }

      return params;
    });
  }

  const httpConfig: HttpConfig = {
    method: "get",
    params,
    url: Urls.extAuditTrailExportToCsvPrediction(),
  };

  // eslint-disable-next-line no-useless-catch
  try {
    const httpResult: AxiosResponse<AuditTrailPredictionResult> = await axios(
      httpConfig
    );

    try {
      const result = await AuditTrailPredictionResultSchema.parseAsync(
        httpResult.data
      );

      return result;
    } catch (error) {
      throw new Error("Fail to parse response data");
    }
  } catch (error) {
    throw error;
  }
};

const AuditTrailService = {
  exportToCsv,
  exportToCsvPrediction,
};

export default AuditTrailService;
