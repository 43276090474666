import { useState, useEffect } from "react";
import axios from "../AxiosInstance";
import LogService from "../services/LogService";
import { HttpConfig, HttpResult } from "../types/types-api";

const useApi = <T, U>({
  method,
  url,
  data,
}: HttpConfig<T>): HttpResult<U | undefined> => {
  const [resultData, setResultData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const dataJson = JSON.stringify(data);

  useEffect(() => {
    const fetchData = async () => {
      setIsError(false);
      setIsLoading(true);

      try {
        const httpResult = await axios({
          method,
          url,
          data: dataJson,
        });
        setResultData(httpResult.data);
      } catch (e) {
        LogService.warn("useApi call failed ", e);
        setIsError(true);
      }

      setIsLoading(false);
    };

    if (!isError) {
      fetchData();
    }
  }, [method, url, dataJson, isError]);
  // Important: Only use primitive types or states in useEffect() dependencies.
  // Objects will cause the effect to be called again unexpectedly,
  // because === doesn't work on objects and are seen as a reason to run the effect again.

  return { data: resultData, isLoading, isError };
};

export default useApi;
