import { MeasureMode } from "../../types/types-measurement";

const mapMeasureModeToShortUserString = (measureMode: MeasureMode): string => {
  switch (measureMode) {
    case MeasureMode.InPP:
      return "InPP";
    case MeasureMode.OutPP:
      return "OutPP";
    default:
      return "Unknown";
  }
};

const mapMeasureModeToLongUserString = (measureMode: MeasureMode): string => {
  switch (measureMode) {
    case MeasureMode.InPP:
      return "In primary packaging";
    case MeasureMode.OutPP:
      return "Outside of primary packaging";
    default:
      return "Unknown measure mode";
  }
};

export { mapMeasureModeToShortUserString, mapMeasureModeToLongUserString };
