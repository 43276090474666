import ExpireData from "../types/contracts/ExpireData";
import DeviceService from "./domain/DeviceService";

const EXPIRATION_BUFFER_SECONDS = 60 * 60; // 60 min

const getExpiryData = async (
  serialNumber: string
): Promise<(ExpireData | undefined)[]> => {
  const protocols = await DeviceService.getProtocols();

  const expireData = await Promise.all(
    protocols.map((p) => {
      return DeviceService.getExpireData(serialNumber, p.name);
    })
  );

  return expireData;
};

const calcCalibrationStatus = (
  expireData: (ExpireData | undefined)[]
): boolean => {
  return expireData.every(
    (e: ExpireData | undefined) =>
      e && e.secondsLeft > EXPIRATION_BUFFER_SECONDS
  );
};

const checkIsCalibrated = async (serialNumber: string): Promise<boolean> => {
  const expiryData = await getExpiryData(serialNumber);
  return calcCalibrationStatus(expiryData);
};

const CheckCalibrationService = {
  calcCalibrationStatus,
  getExpiryData,
  checkIsCalibrated,
};

export default CheckCalibrationService;
