import React from "react";
import {
  Table,
  TableContainer,
  TableFooter,
  TableRow,
  TableCell,
  Paper,
} from "@mui/material";

import LibraryTableHeaders, { LibraryTableHeader } from "./LibraryTableHeaders";
import LibraryTableBody, { TableRowSet } from "./LibraryTableBody";
import LoadingIndicator from "../LoadingIndicator/LoadingIndicator";

export type LibraryTableHeaders<T> = Array<LibraryTableHeader<T>>;

interface LibraryTableProps<T> {
  headers: Array<LibraryTableHeader<Record<string, number>>>;
  rowSets: TableRowSet<T>[];
  isRowSelected?(row: T): boolean;
  id?: string;
  loading?: boolean;
}

const LibraryTable: <T>(props: LibraryTableProps<T>) => JSX.Element = ({
  headers,
  rowSets,
  id = "libraryTable",
  loading,
  isRowSelected,
}) => {
  return (
    <TableContainer component={Paper} elevation={0} square>
      <Table id={id}>
        <LibraryTableHeaders headers={headers} />
        <LibraryTableBody
          rowSets={rowSets}
          loading={loading ?? false}
          headers={headers}
          isRowSelected={isRowSelected}
        />
        <TableFooter>
          <TableRow>
            {loading && (
              <TableCell colSpan={100}>
                <LoadingIndicator />
              </TableCell>
            )}
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
};

export default LibraryTable;
