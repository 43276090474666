import React, { ReactNode } from "react";
import styled from "styled-components";
import PermissionService from "../../services/PermissionService";
import { PermissionKey } from "../../types/types-permission";

interface PermissionRequiredProps {
  children: ReactNode;
  hidden?: boolean;
  permissionKeys: PermissionKey[];
}

const Disabled = styled.div`
  pointer-events: none;
  opacity: 0.35;
`;

const PermissionRequired: (props: PermissionRequiredProps) => JSX.Element = ({
  children,
  hidden,
  permissionKeys,
}) => {
  const verified = PermissionService.verify(permissionKeys);

  if (!verified && hidden) {
    return <></>;
  }

  if (!verified) {
    return <Disabled>{children}</Disabled>;
  }

  return <>{children}</>;
};

export default PermissionRequired;
