import React from "react";
import { Link, useLocation } from "react-router-dom";
import { Tabs, Tab } from "@mui/material";
import { PermissionRequired } from "lib-core";
import appViews from "../../router/views";

const HeaderNavigation: () => JSX.Element = () => {
  const location = useLocation();

  return (
    <Tabs component="nav" value={0}>
      {appViews
        .filter((view) => !view.home.disabled)
        .filter((view) => !view.home.name.includes("Error"))
        .map((view) => {
          const { name, path, requiredKeys } = view.home;
          const selected = location.pathname === path;

          return (
            <PermissionRequired key={name} permissionKeys={requiredKeys}>
              <Tab
                label={name}
                component={Link}
                className={selected ? "Mui-selected" : ""}
                to={path}
              />
            </PermissionRequired>
          );
        })}
    </Tabs>
  );
};

export default HeaderNavigation;
