import React from "react";
import styled from "styled-components";
import { CircularProgress, TableCell as MuiTableCell } from "@mui/material";

interface DataTableLoadingProps {
  loading?: boolean;
  resultCount?: number;
}

const TableCell = styled(MuiTableCell)`
  color: ${({ theme }) => theme.palette.primary.main};
  border-bottom: 0;
`;

const LoadingMessage = styled.span`
  margin-left: 0.625rem;
`;

const EmptyMessage = styled.span``;

const DataTableLoading: (props: DataTableLoadingProps) => JSX.Element = ({
  loading = false,
  resultCount = 0,
}) => {
  const visible = loading || resultCount === 0;

  if (!visible) {
    return <></>;
  }

  return (
    <TableCell colSpan={2}>
      {loading && (
        <>
          <CircularProgress size={12} color="inherit" />
          <LoadingMessage>Loading...</LoadingMessage>
        </>
      )}
      {!loading && resultCount === 0 && (
        <EmptyMessage>No results found</EmptyMessage>
      )}
    </TableCell>
  );
};

export default DataTableLoading;
