export const headers = [
  {
    label: "Version",
    cellProps: {
      colSpan: 4,
      padding: "normal" as const,
      align: "left" as const,
    },
  },
  {
    label: "Created On",
    cellProps: {
      colSpan: 3,
      padding: "normal" as const,
      align: "left" as const,
    },
  },
  {
    label: "Measurements",
    cellProps: {
      colSpan: 2,
      padding: "normal" as const,
      align: "right" as const,
    },
  },
  {
    label: "CSV metadata",
    cellProps: {
      colSpan: 3,
      padding: "normal" as const,
      align: "right" as const,
    },
  },
] as const;

const [, measurementCol, metadataCol] = headers;

export const failedOrUpcomingLastCol = {
  cellProps: {
    colSpan: measurementCol.cellProps.colSpan + metadataCol.cellProps.colSpan,
    padding: "normal" as const,
    align: "right" as const,
  },
} as const;

export const failedSnapshotDetailCol = {
  cellProps: {
    colSpan: headers.reduce((sum, header) => sum + header.cellProps.colSpan, 0),
    padding: "normal" as const,
  },
};
