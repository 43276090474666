import { AxiosResponse } from "axios";

import axios from "../../AxiosInstance";
import { HttpConfig } from "../../types/types-api";
import UserContent from "../../types/contracts/User";
import Urls from "../Urls";

export const getUserContentById = async (
  userId: string
): Promise<UserContent> => {
  const httpConfig: HttpConfig = {
    method: "get",
    url: Urls.userContent(userId),
  };

  const httpResult: AxiosResponse<UserContent> = await axios(httpConfig);
  return httpResult.data;
};

export const getUserContentByToken = async (): Promise<UserContent> => {
  const httpConfig: HttpConfig = {
    method: "get",
    url: Urls.updateUserRoles(),
  };

  const httpResult: AxiosResponse<UserContent> = await axios(httpConfig);
  return httpResult.data;
};
