/* eslint no-bitwise: "off" */

import {
  ReadMeasurementResultPayload,
  SetInactivityTimerPayload,
  SetParametersPayload,
  WavelengthDataMode0,
  WavelengthDataMode1,
} from "../../types/types-bluetooth";
import {
  SET_INACTIVITY_TIMER_MAX_VALUE,
  SET_INACTIVITY_TIMER_MIN_VALUE,
} from "./constants";

const encodeWavelengthMode0DataPayload = (
  data: WavelengthDataMode0
): Uint8Array => {
  const bytes = new Uint8Array(
    Uint16Array.BYTES_PER_ELEMENT +
      Float32Array.BYTES_PER_ELEMENT * data.wavelengths.length
  );
  const length = new Uint16Array([data.wavelengths.length]);
  // Number of float values as an 2 byte unsigned int
  bytes.set(new Uint8Array(length.buffer));

  const floatValues = new Float32Array(data.wavelengths.length);
  data.wavelengths.forEach((wavelength, index) => {
    floatValues[index] = wavelength;
  });
  bytes.set(
    new Uint8Array(floatValues.buffer),
    Uint16Array.BYTES_PER_ELEMENT /* after the length */
  );

  return bytes;
};

const encodeWavelengthMode1DataPayload = (
  data: WavelengthDataMode1
): Uint8Array => {
  const values = new Uint32Array(3);
  values[0] = data.min;
  values[1] = data.max;
  values[2] = data.step;
  return new Uint8Array(values.buffer);
};

const encodeSetParametersPayload = (
  content: SetParametersPayload
): Uint8Array => {
  let wavelengthData: Uint8Array;
  if (content.wavelengthData.mode === 0) {
    wavelengthData = encodeWavelengthMode0DataPayload(content.wavelengthData);
  } else {
    wavelengthData = encodeWavelengthMode1DataPayload(content.wavelengthData);
  }

  const payload = new Uint8Array(1 + wavelengthData.length + 6);
  // 1 byte for mode before wavelengthData
  // 6 byte after (2 byte wavelengthAverage + 2 byte scanAverage + 1 byte lightSourceIntensity + 1 byte bitflags)
  let nextFreeElement = 0;

  payload[nextFreeElement] = content.wavelengthData.mode;
  nextFreeElement += Uint8Array.BYTES_PER_ELEMENT;

  payload.set(new Uint8Array(wavelengthData.buffer), nextFreeElement);
  nextFreeElement += wavelengthData.byteLength;

  const averageData = new Uint16Array(2);
  averageData[0] = content.wavelengthAverage;
  averageData[1] = content.scanAverage;
  payload.set(new Uint8Array(averageData.buffer), nextFreeElement);
  nextFreeElement += averageData.byteLength;

  payload[nextFreeElement] = content.lightSourceIntensity;
  nextFreeElement += Uint8Array.BYTES_PER_ELEMENT;

  let lastByte = 0;
  if (content.lightSourceAutomaticMode) {
    // set light source control mode (LSCM) flag to automatic
    lastByte |= 1;
  }
  if (content.automaticDark) {
    // set automatic dark (AD) flag
    lastByte |= 1 << 1;
  }
  if (content.subtractDark) {
    // set substract dark (SD) flag
    lastByte |= 1 << 2;
  }
  payload[nextFreeElement] = lastByte;

  return payload;
};

const encodeSetInactivityTimerPayload = (
  content: SetInactivityTimerPayload
): Uint8Array => {
  const timerValue = content.inactivityTimerValueInMinutes;
  if (
    timerValue < SET_INACTIVITY_TIMER_MIN_VALUE ||
    timerValue > SET_INACTIVITY_TIMER_MAX_VALUE
  ) {
    throw new Error(
      `Invalid inactivityTimerValueInMinutes of ${timerValue}.
      Must be between ${SET_INACTIVITY_TIMER_MIN_VALUE} and ${SET_INACTIVITY_TIMER_MAX_VALUE}.`
    );
  }

  return new Uint8Array(new Uint16Array([timerValue]).buffer);
};

const decodeReadMeasurementResultPayload = (
  payload: number[]
): ReadMeasurementResultPayload => {
  const bytes = new Uint8Array(payload);
  const floats = new Float32Array(bytes.buffer);
  return { values: Array.from(floats) };
};

export {
  encodeSetParametersPayload,
  encodeSetInactivityTimerPayload,
  decodeReadMeasurementResultPayload,
};
