import { AxiosRequestConfig, AxiosResponse } from "axios";
import {
  Camera,
  CameraResultType,
  CameraSource,
  Photo,
} from "@capacitor/camera";
import { decode } from "base64-arraybuffer";

import axios from "../../AxiosInstance";
import Urls from "../Urls";

interface GetPhotoParams {
  source: CameraSource;
  callback?(photo: Photo): void;
}

const base64ToBlob = (base64: string): Blob => {
  const blobPart = new Uint8Array(decode(base64 as string));
  return new Blob([blobPart]);
};

const blobToBase64 = (blob: Blob): Promise<string | ArrayBuffer | null> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const getMeasurementImage = async (
  username: string,
  measurementId: string,
  imageId: string
): Promise<string> => {
  const httpConfig: AxiosRequestConfig = {
    method: "get",
    responseType: "blob",
    url: Urls.downloadImage({ username, measurementId, imageId }),
  };
  const httpResult: AxiosResponse = await axios(httpConfig);
  // eslint-disable-next-line prettier/prettier
  return blobToBase64(httpResult.data) as unknown as string;
};

const uploadMeasurementImage = async (
  username: string,
  measurementId: string,
  data: FormData
): Promise<boolean> => {
  const httpConfig: AxiosRequestConfig = {
    method: "post",
    headers: { "Content-Type": "multipart/form-data" },
    url: Urls.uploadImage(username, measurementId),
    data,
  };
  await axios(httpConfig);
  return true;
};

const getMeasurementSpectra = async (
  measurementId: string
): Promise<string> => {
  const httpConfig: AxiosRequestConfig = {
    method: "get",
    responseType: "blob",
    url: Urls.drugAuthenticationSpectra(measurementId),
  };
  const httpResult: AxiosResponse = await axios(httpConfig);
  // eslint-disable-next-line prettier/prettier
  return blobToBase64(httpResult.data) as unknown as string;
};

const getPhoto = async (params: GetPhotoParams): Promise<Photo | null> => {
  const { source, callback } = params;

  const permissionResult = await Camera.requestPermissions({
    permissions: ["camera", "photos"],
  });

  if (
    permissionResult.camera === "denied" ||
    permissionResult.photos === "denied"
  ) {
    // eslint-disable-next-line no-alert
    window.confirm("Please grant access to both photo library and camera");
    return null;
  }

  const photo = await Camera.getPhoto({
    resultType: CameraResultType.Uri,
    source,
    quality: 10,
    width: 1024,
    // We limit the size of the image to make sure the size is not too much and backend will reject it
  });
  if (callback) {
    callback(photo);
  }
  return photo;
};

const ImageService = {
  base64ToBlob,
  blobToBase64,
  getMeasurementImage,
  getMeasurementSpectra,
  getPhoto,
  uploadMeasurementImage,
};

export default ImageService;
