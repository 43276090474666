import React from "react";
import format from "date-fns/format";
import { SelectChangeEvent } from "@mui/material";
import styled from "styled-components";
import {
  CalibrationData,
  CellTypes,
  DeviceService,
  Device,
  TableCellType,
  TableDataArray,
  DataTableHeaderType,
  LocationValue,
} from "lib-core";

type Calibration = {
  protocol: string;
  spectralReference: CalibrationData | undefined;
  whiteReference: CalibrationData | undefined;
};

const deviceStatusOptions = ["active", "inactive", "retired"].map((status) => ({
  label: status === "retired" ? "decommissioned" : status,
  value: status,
}));

const selectStatusMessage = (value: string) => {
  if (value === "inactive") {
    return "DEACTIVATED_BY_ADMIN";
  }
  return "OK";
};

const onDeviceStatusChange = async (
  event: SelectChangeEvent,
  device: Device
) => {
  const deviceStatus = event.target.value;
  const statusMessage = selectStatusMessage(deviceStatus);

  await DeviceService.updateDeviceStatus(device.serialNumber, {
    message: statusMessage,
    status: deviceStatus,
  });
};

const regionalDataIsAvailable = (value: LocationValue) => {
  let { municipality, region, country } = value;
  if (!municipality) municipality = "";
  if (!region) region = "";
  if (!country) country = "";

  return `${municipality} ${region} ${country}`;
};

const regionalDataIsNotAvailable = (value: LocationValue) => {
  const { north, west } = value;

  if (!north || !west) {
    return "";
  }

  return `N ${north.substring(0, 8)} W ${west.substring(0, 8)}`;
};

const calibrationValue = (
  calibrations: Calibration,
  field: "spectralReference" | "whiteReference"
) => {
  const validUntil = calibrations[field]?.validUntil;
  return validUntil ? format(new Date(validUntil), "yyyy-MM-dd") : "";
};

export const headers: DataTableHeaderType[] = [
  {
    key: "serialNumber",
    label: "Serial number",
  },
  {
    key: "status",
    label: "Status",
  },
  {
    key: "firmwareVersion",
    label: "Firmware version",
  },
  {
    key: "oqCalibration",
    label: "OQ Calibration",
  },
  {
    key: "pqCalibration",
    label: "PQ Calibration",
  },
  {
    key: "lastMeasuredBy",
    label: "Last measured by",
  },
  {
    key: "municipality",
    label: "Last measurement location",
  },
  {
    key: "lastUsedOn",
    label: "Last measurement timestamp",
  },
];

const Error = styled.span`
  font-weight: bold;
  font-size: 10px;
  color: ${({ theme }) => theme.palette.error.main};
`;

export const mapDevices = (
  deviceList: Device[],
  allowStatusUpdating?: boolean
): TableDataArray<Device> =>
  deviceList.map((device, index) => {
    const calibrations = device.calibrations
      ? Object.entries(device.calibrations).reduce((prev, curr) => {
          const [protocol, calibrations] = curr;
          return { ...prev, ...calibrations, protocol };
        }, {} as Calibration)
      : ({} as Calibration);
    const locationValue = {
      municipality: device.metadata?.municipality,
      region: device.metadata?.region,
      country: device.metadata?.country,
      north: device.metadata?.latitude
        ? device.metadata.latitude.toString()
        : "",
      west: device.metadata?.longitude
        ? device.metadata.longitude.toString()
        : "",
    };

    const humanReadableLocationString = device.metadata?.municipality
      ? regionalDataIsAvailable(locationValue)
      : regionalDataIsNotAvailable(locationValue);

    const isDeviceHavingTooManyCalibrations =
      device.metadata?.statusMessage === "TOO_MANY_FAILED_CALIBRATIONS";

    return {
      data: {
        ...device,
      },
      id: `${device.createdOn}-${device.serialNumber}-${index}`,
      row: [
        // Serial number
        {
          key: `col-serial-number`,
          type: CellTypes.STRING,
          value: device.serialNumber,
        } as TableCellType,
        // Status
        allowStatusUpdating
          ? ({
              key: `col-device-status`,
              type: CellTypes.SELECT,
              value: device.status,
              options: deviceStatusOptions,
              onChange: (e) => onDeviceStatusChange(e, device),
              extra: isDeviceHavingTooManyCalibrations ? (
                <Error>Calibration failed</Error>
              ) : undefined,
            } as TableCellType)
          : ({
              key: `col-device-status`,
              type: CellTypes.STRING,
              value:
                device.status === "retired" ? "decommissioned" : device.status,
              extra: isDeviceHavingTooManyCalibrations ? (
                <Error>Calibration failed</Error>
              ) : undefined,
            } as TableCellType),
        // Firmware version
        {
          key: `col-device-firmware-version`,
          type: CellTypes.STRING,
          value: device.firmwareVersion,
        } as TableCellType,
        // WhiteReference calibration
        {
          key: `col-device-oq-calibration`,
          type: CellTypes.EXPIRED,
          value: {
            isValid: calibrations.whiteReference
              ? calibrations.whiteReference.secondsLeft > -1
              : false,
            limit: 7,
            date: calibrations.whiteReference?.validUntil,
            statusMessage: device.metadata?.statusMessage,
            serialNumber: device.serialNumber,
          },
          stringValue: calibrationValue(calibrations, "whiteReference"),
        } as TableCellType,
        {
          key: `col-device-pq-calibration`,
          type: CellTypes.EXPIRED,
          value: {
            isValid: calibrations.spectralReference
              ? calibrations.spectralReference.secondsLeft > -1
              : false,
            limit: 7,
            date: calibrations.spectralReference?.validUntil,
            statusMessage: device.metadata?.statusMessage,
            serialNumber: device.serialNumber,
          },
          stringValue: calibrationValue(calibrations, "spectralReference"),
        } as TableCellType,
        // Last measured by
        {
          key: `col-device-measured-by`,
          type: CellTypes.STRING,
          value: device.metadata?.lastMeasuredBy,
        } as TableCellType,
        // Last measurement location
        {
          key: `col-device-location`,
          type: CellTypes.LOCATION,
          value: locationValue,
          stringValue: humanReadableLocationString,
        } as TableCellType,
        // Last measurement timestamp
        device.metadata?.lastUsedOn
          ? ({
              key: `col-device-last-used`,
              type: CellTypes.DATETIME,
              value: new Date(device.metadata?.lastUsedOn),
              format: "yyyy-MM-dd HH:mm:ss",
              stringValue: new Date(device.metadata?.lastUsedOn)
                .toISOString()
                .replace(".000", ""),
            } as TableCellType)
          : {
              key: `col-device-last-used`,
              type: CellTypes.STRING,
              value: "",
            },
      ],
    };
  });
