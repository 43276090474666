import { z } from "zod";
import { PermissionSchema } from "./permission";

export const UserSchema = z.object({
  createdOn: z.string(),
  modifiedOn: z.string(),
  expirationTime: z.nullable(z.string()),
  username: z.string(),
  preferredUsername: z.string().email(),
  permissions: z.nullable(z.array(PermissionSchema)),
});

export const UserWithTokenExpireTimeSchema = UserSchema.extend({
  expirationTime: z.string(),
});
