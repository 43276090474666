enum MeasurementAssessment {
  PENDING = "PENDING",
  TRUE_NEGATIVE = "TRUE_NEGATIVE",
  TRUE_POSITIVE = "TRUE_POSITIVE",
  FALSE_NEGATIVE = "FALSE_NEGATIVE",
  FALSE_POSITIVE = "FALSE_POSITIVE",
  WRONG_MODEL = "WRONG_MODEL",
  INVALID_SAMPLE = "INVALID_SAMPLE",
}

type AssessmentSubmissionParameter = {
  note: {
    message: string;
  };
  metadata: {
    assessment: MeasurementAssessment;
  };
  ver: string;
};

export { MeasurementAssessment, AssessmentSubmissionParameter };
