import {
  READ_DEVICE_READY_STATUS_REQUEST,
  READ_DEVICE_READY_STATUS_RESPONSE,
  READ_DEVICE_READY_STATUS_RESPONSE_VALUES,
  SET_PARAMETERS_REQUEST,
  SET_PARAMETERS_RESPONSE,
  SET_PARAMETERS_RESPONSE_VALUES,
} from "./constants";
import { createFlow } from "./flow";
import { buildMessageFIFO } from "./message";
import { SET_PARAMETERS_ERROR } from "./error_codes";
import LogService from "../../services/LogService";
import { SetParametersPayload } from "../../types/types-bluetooth";

/**
 * Setting measurement parameters of the devices. Resolve with true if parameters is set to devices.
 * @param address
 * @param parameters
 */
const setParameters = (
  address: string,
  parameters: SetParametersPayload
): Promise<boolean> => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    LogService.log("1 - open flow for setParameters");
    // TODO try catch
    const flow = await createFlow(address, (error) => reject(error));

    flow.subscribe(SET_PARAMETERS_RESPONSE, (message) => {
      if (message.payload[0] === SET_PARAMETERS_RESPONSE_VALUES.OK) {
        LogService.log("[BLE] setParameters, set parameter succeed.");
        LogService.log("[BLE] setParameters, check if device is ready.");
        const isDeviceReady = buildMessageFIFO(
          READ_DEVICE_READY_STATUS_REQUEST
        );
        flow.write(isDeviceReady);
      }

      if (message.payload[0] === SET_PARAMETERS_RESPONSE_VALUES.NOT_OK) {
        LogService.error(
          new Error(
            `Error - ${SET_PARAMETERS_ERROR} : set parameter failed. device returns message ID = 513, payload = 1: message: ${JSON.stringify(
              message
            )}`
          )
        );
        reject(
          new Error(
            `Error - ${SET_PARAMETERS_ERROR} : Please try again. Check troubleshooting materials`
          )
        );
      }
    });

    flow.subscribe(READ_DEVICE_READY_STATUS_RESPONSE, (message) => {
      flow.close().then(() => {
        LogService.log("3- closed flow for setParameters");
        if (
          message.payload[0] === READ_DEVICE_READY_STATUS_RESPONSE_VALUES.OK
        ) {
          LogService.log(
            "[BLE] setParameters, device is ready. ",
            message.payload[0]
          );
          resolve(true);
        } else {
          LogService.log("setParameters , resolve false ");
          resolve(false);
        }
      });
    });

    const setParametersFIFO = buildMessageFIFO(
      SET_PARAMETERS_REQUEST,
      parameters
    );

    LogService.log("write set parameters");
    flow.write(setParametersFIFO);
  });
};

export { setParameters };
export default setParameters;
