import React, { useEffect } from "react";
import useDeviceInfo from "../hooks/useDeviceInfo";
import BatteryIcon from "../svgs/BatteryIcon";

interface SimpleBatteryIndicatorProps {
  onError: () => void;
  className?: string;
}

const SimpleBatteryIndicator: (p: SimpleBatteryIndicatorProps) => JSX.Element =
  ({ onError, className = "" }) => {
    const { deviceInfo, errorMessage } = useDeviceInfo();

    useEffect(() => {
      if (errorMessage) {
        onError();
      }
    }, [onError, errorMessage]);

    const roundToQuarters = (number: number) => {
      const dividedBy100 = number / 100;
      const decimal = Math.round(dividedBy100 * 4) / 4;
      return 100 * decimal;
    };

    if (errorMessage.length > 0) {
      return <></>;
    }

    const batteryLevel = deviceInfo?.batteryLevel ?? 0;
    const batteryLevelQuarters = roundToQuarters(batteryLevel);

    return (
      <BatteryIcon percentage={batteryLevelQuarters} className={className} />
    );
  };

export default SimpleBatteryIndicator;
