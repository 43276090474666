import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import React, { KeyboardEvent } from "react";
import DateTimePicker from "@mui/lab/DateTimePicker";
import LogService from "../../services/LogService";
import { Filter } from "./datafilter-types";
import DateInput from "./DateInput";
import { checkIfStringEmpty } from "./datafilter-helpers";

interface DatetimeFilterProps {
  filter: Filter;
  onChangeDateTime: (name: string, value: string | null) => void;
}

const DatetimeFilter: (props: DatetimeFilterProps) => JSX.Element = ({
  filter,
  onChangeDateTime,
}) => {
  const { name, placeholder, value } = filter;

  const onKeyDown = (e: KeyboardEvent<HTMLImageElement>) => {
    e.preventDefault();
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} key={name}>
      <DateTimePicker
        ampm={false}
        inputFormat="yyyy-MM-dd HH:mm"
        mask="____-__-__ __:__"
        onAccept={(value) => onChangeDateTime(name, value)}
        onChange={() => LogService.log("Datetime value changed")}
        renderInput={(props) => (
          <DateInput
            onKeyDown={onKeyDown}
            {...props}
            placeholder={placeholder}
            name={name}
          />
        )}
        value={checkIfStringEmpty(value)}
      />
    </LocalizationProvider>
  );
};

export default DatetimeFilter;
