import LogService from "../../services/LogService";
import { bluetoothle, encodedStringToArrayBuffer } from "./bluetoothle";

/**
 * Promisify of [bluetoothle.write](https://github.com/don/cordova-plugin-ble-central#write)
 * @param address, the device id
 * @param service, service uuid
 * @param characteristic, characteristic uuid
 * @param value
 */
const write = (
  address: string,
  service: string,
  characteristic: string,
  value: string
): Promise<string> =>
  new Promise((resolve, reject) => {
    bluetoothle.write(
      address,
      service,
      characteristic,
      encodedStringToArrayBuffer(value),
      () => {
        resolve(value);
      },
      (error) => {
        LogService.error("[BLE] write error", error);
        reject(error);
      }
    );
  });

export default write;
