export type Filter = {
  name: string;
  placeholder: string;
  type: FilterTypes;
  value: string | null;
  filter?: (items: unknown, value: string) => unknown;
  options?: string[];
};

export type FilterState = {
  name: string;
  placeholder: string;
  type?: FilterTypes;
  value: string;
};

export type KeyValueParams = {
  [key: string]: string;
  value: string;
}[];

export type InputChange = React.ChangeEvent<
  HTMLTextAreaElement | HTMLInputElement
>;

export enum FilterTypes {
  Date = "Date",
  DateTime = "DateTime",
  String = "String",
  Autocomplete = "Autocomplete",
}
