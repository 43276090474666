/* eslint-disable @typescript-eslint/no-explicit-any */
import { Capacitor } from "@capacitor/core";
import LogService from "../../services/LogService";
import { bluetoothle } from "./bluetoothle";
import { DISCONNECT, CREATE_CONNECTION } from "./error_codes";

/**
 * Promisify of [bluetoothle.isConnected](https://github.com/don/cordova-plugin-ble-central#isconnected)
 * Check the device is connected or not. `Resolve(true)` if the device is connected.
 * @param address - The address of the device to be checked
 */
const checkIsConnected = (address: string): Promise<boolean> =>
  new Promise((resolve) => {
    // as any because typing is missing the connected parameter
    (bluetoothle as any).isConnected(
      address,
      (_connected: "OK") => {
        LogService.log(`[BLE] checkIsConnected ${_connected}`);
        // The parameter seems to be incorrectly documented:
        // https://github.com/don/cordova-plugin-ble-central/issues/649
        /* TODO: Set this true */
        resolve(true);
      },
      () => {
        resolve(false);
      }
    );
  });
// How many ms to wait after disconnect is resolved.
const disconnectDelay = 100;
/**
 * Promisify of [bluetoothle.disconnect](https://github.com/don/cordova-plugin-ble-central#disconnect)
 */
const disconnect = (address: string): Promise<boolean> =>
  new Promise((resolve, reject) => {
    LogService.log("disconnect");
    bluetoothle.disconnect(
      address,
      () => {
        // Wait a bit to workaround bluetooth library bug:
        // https://github.com/don/cordova-plugin-ble-central/issues/651
        setTimeout(() => {
          resolve(true);
        }, disconnectDelay);
      },
      (error) => {
        LogService.error(
          new Error(
            `Error - ${DISCONNECT} : disconnect failed: fail to disconnect ${address}, reason: ${JSON.stringify(
              error
            )}`
          )
        );
        reject(new Error(`${DISCONNECT} : Please try again`));
      }
    );
  });
/**
 * Create a connection by giving the address of the device. Return a Promise(true|false).
 * @param address
 */
const createConnection = async (
  address: string
): Promise<BLECentralPlugin.PeripheralDataExtended | null> => {
  try {
    const isConnected = await checkIsConnected(address);

    LogService.log(
      `[BLE] createConnection, checkIsConnected, address: ${address} isConnected: ${isConnected}`
    );

    if (isConnected === true) {
      LogService.log("[BLE] is connected already");

      // TODO: Return peripheralData
      return isConnected as any;
    }
    LogService.log("[BLE] is not connected, connecting..");

    const connectFn =
      Capacitor.getPlatform() === "ios"
        ? bluetoothle.connect
        : bluetoothle.autoConnect;

    return new Promise((resolve) => {
      connectFn(
        address,
        (peripheralData) => {
          // Note: services and characteristics are empty arrays if the connection is created
          // when the app is already connected.
          LogService.log(
            "[BLE] createConnection -> connected, result:",
            peripheralData
          );

          resolve(peripheralData);
        },
        () => {
          // Disconnected
          // TODO: this doesn't really work if the promise was resolved to true previously.
          // Note:
          // The disconnect callback is called if the connection fails, or later if the peripheral
          // disconnects. When possible, a peripheral object is passed to the failure callback.
          // The disconnect callback is only called when the peripheral initates the disconnection.
          // The disconnect callback is not called when the application calls ble.disconnect. The
          // disconnect callback is how your app knows the peripheral inintiated a disconnect.
          LogService.log("[BLE] Disconnected", address);
          resolve(null);
        }
      );
    });
  } catch (error) {
    LogService.error(`Error - ${CREATE_CONNECTION} : ${error}`);
    throw new Error(`${CREATE_CONNECTION} : Please try again`);
  }
};

export { checkIsConnected, disconnect, createConnection };

export default createConnection;
