/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useEffect, useState } from "react";

import Banner from "./Banner";
import Snackbar from "./Snackbar";

import {
  notifDismissEventName,
  notifEventName,
  NotificationList,
  NotificationTypes,
} from "./notification-types";

const Notifications: () => JSX.Element = () => {
  const [notifications, setNotifications] = useState<NotificationList>([]);

  const handler = useCallback(
    ({ detail }: CustomEvent<NotificationOptions>) => {
      setNotifications(
        (prevNotifs) => [...prevNotifs, detail] as NotificationList
      );

      setTimeout(() => {
        setNotifications((prevAlerts) =>
          prevAlerts
            .filter((alert) => !alert.persistent)
            .slice(1, prevAlerts.length)
        );
      }, 6000);
    },
    []
  );

  const dismissNotifHandler = useCallback(
    ({ detail: { key } }: CustomEvent<{ key: string }>) => {
      setNotifications(
        (prevNotifs) =>
          prevNotifs.filter((noti) => noti.key !== key) as NotificationList
      );
    },
    []
  );

  useEffect(() => {
    window.addEventListener(notifEventName, handler as any);
    window.addEventListener(notifDismissEventName, dismissNotifHandler as any);

    return () => {
      window.removeEventListener(notifEventName, handler as any);
      window.removeEventListener(
        notifDismissEventName,
        dismissNotifHandler as any
      );
    };
  }, [handler, dismissNotifHandler]);

  return (
    <>
      {notifications.map((item, idx) => (
        <React.Fragment key={idx + item.message}>
          {item.type === NotificationTypes.Banner && (
            <Banner
              action={item.action}
              message={item.message}
              showClose={item.showClose}
            />
          )}

          {item.type === NotificationTypes.Snackbar && (
            <Snackbar
              duration={item.persistent ? null : undefined}
              action={item.action}
              horizontal={item.horizontal}
              key={idx + item.message}
              message={item.message}
              severity={item.severity}
              showClose={item.showClose}
              vertical={item.vertical}
            />
          )}
        </React.Fragment>
      ))}
    </>
  );
};

export default Notifications;
