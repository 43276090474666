import React from "react";
import { Link as ReactRouterLink } from "react-router-dom";
import styled from "styled-components";
import { Typography } from "@mui/material";
import { PermissionRequired } from "lib-core";
import { AppRoute } from "../../router/router-types";

interface NavigationProps {
  appViews: AppRoute[];
}

interface ContentProps {
  disabled?: boolean;
}

const Nav = styled.nav`
  display: flex;
  gap: 30px;
`;

const ContentItem = styled.div<ContentProps>`
  border: 1px solid ${({ theme }) => theme.palette.brandGray.main};
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  height: 100%;
  img {
    display: block;
    width: 100%;
  }
  opacity: ${({ disabled }) => (disabled ? "0.35" : "1")};
`;

const Information = styled.div`
  padding: 22px 25px 25px;
  flex-grow: 1;

  p {
    margin: 0;
    font-size: 0.875rem;
  }

  h3 {
    margin: 0 0 8px;
  }
`;

const Link = styled(ReactRouterLink)`
  color: inherit;
  text-decoration: none;
  flex: 1 1 auto;
`;

const Navigation: (props: NavigationProps) => JSX.Element = ({ appViews }) => (
  <Nav>
    {appViews
      .filter((view) => view.home.attributes !== undefined)
      .map((view) => {
        const { attributes, path, requiredKeys } = view.home;
        const { altText, description, image, title } = attributes || {};
        return (
          <PermissionRequired key={path} permissionKeys={requiredKeys}>
            <Link to={path}>
              <ContentItem>
                <img alt={altText} src={image} />
                <Information>
                  <Typography variant="h3">{title}</Typography>
                  <p>{description}</p>
                </Information>
              </ContentItem>
            </Link>
          </PermissionRequired>
        );
      })}
  </Nav>
);

export default Navigation;
