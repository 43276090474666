import { Capacitor } from "@capacitor/core";
import { BluetoothStateMobile } from "../lib/ble/permission";
import BluetoothService from "../services/bluetooth/BluetoothService";

/**
 * For this mock to work, this needs to be put at the top level of the test file:
 * @example
 * jest.mock("@capacitor/core");
 * jest.mock("../../services/bluetooth/BluetoothService");
 */
export default function mockBluetoothToIosAndOn(): void {
  const capacitorSpy = jest.spyOn(Capacitor, "getPlatform");
  capacitorSpy.mockReturnValue("ios");

  const bluetoothSpy = jest.spyOn(
    BluetoothService,
    "startIosBluetoothStateUpdates"
  );
  bluetoothSpy.mockImplementation((callback) => {
    callback(BluetoothStateMobile.BluetoothOn);
  });
}
