import { Button as MuiButton } from "@mui/material";
import styled from "styled-components";

export const Container: ReturnType<typeof styled.main> = styled.main`
  display: flex;
  flex-direction: column;
  width: 430px;
  background: ${({ theme }) => theme.palette.brandGray.light};
  padding: 20px;
`;
export const TitleBox = styled.div`
  width: 100%;
`;
export const TextContainer = styled.div`
  display: block;
`;

export const ButtonBox = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
`;

export const Button = styled(MuiButton)`
  min-width: 110px;
  height: 40px;
  align-items: center;
  padding: 0 0;
  margin: 5px;
`;

export const WideButton = styled(MuiButton)`
  min-width: 200px;
  height: 40px;
  align-items: center;
  padding: 0 0;
  margin: 5px;
`;

export const InnerContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;
