import React from "react";
import Button from "@mui/material/Button";
import DownloadIcon from "@mui/icons-material/Download";
import ReactPDF from "@react-pdf/renderer";
import saveAs from "file-saver";
import {
  PermissionKeys,
  StoredMeasurementHistoryItem,
  usePermissionChecking,
} from "lib-core";
import { useTheme } from "@mui/material";
import PdfDocumentContainer from "./PdfDocumentContainer";
import { ImageState } from "./history-hook";

interface DownloadPdfButtonProps {
  content: StoredMeasurementHistoryItem;
  spectra: ImageState | undefined;
  disabled: boolean;
}

const { readMeasurementSpectra } = PermissionKeys;

const DownloadPdfButton = ({
  content,
  spectra,
  disabled,
}: DownloadPdfButtonProps): JSX.Element => {
  const requirePermission = usePermissionChecking();

  const { id: measurementId, measurerMasked } = content;

  const theme = useTheme();

  return (
    <Button
      startIcon={<DownloadIcon />}
      disabled={
        !spectra?.base64 ||
        !requirePermission([readMeasurementSpectra]) ||
        measurerMasked ||
        disabled
      }
      variant="outlined"
      onClick={(e) => {
        e.preventDefault();
        if (spectra?.base64) {
          ReactPDF.pdf(
            <PdfDocumentContainer
              theme={theme}
              measurementContent={content}
              spectraImageBase64={spectra.base64}
            />
          )
            .toBlob()
            .then((blob) => {
              saveAs(blob, `measurement-${measurementId}.pdf`);
            });
        }
      }}
    >
      Download PDF Report
    </Button>
  );
};

export default DownloadPdfButton;
