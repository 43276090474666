(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("react"), require("@mui/material"), require("styled-components"), require("react-dom"), require("react-router-dom"));
	else if(typeof define === 'function' && define.amd)
		define(["react", "@mui/material", "styled-components", "react-dom", "react-router-dom"], factory);
	else if(typeof exports === 'object')
		exports["lib-core"] = factory(require("react"), require("@mui/material"), require("styled-components"), require("react-dom"), require("react-router-dom"));
	else
		root["lib-core"] = factory(root["react"], root["@mui/material"], root["styled-components"], root["react-dom"], root["react-router-dom"]);
})((typeof self !== 'undefined' ? self : this), function(__WEBPACK_EXTERNAL_MODULE__8156__, __WEBPACK_EXTERNAL_MODULE__901__, __WEBPACK_EXTERNAL_MODULE__1608__, __WEBPACK_EXTERNAL_MODULE__7111__, __WEBPACK_EXTERNAL_MODULE__6128__) {
return 