// TODO Used for debug screen, remove if not needed anymore
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const logCollection: any = [];

function preserveLogForDebugging(
  message?: unknown,
  ...optionalParams: unknown[]
) {
  if (process.env.REACT_APP_ENABLE_DEVELOPMENT_MODE === "true") {
    logCollection.push(`${message} ${optionalParams}`);
  }
}

function debug(message?: unknown, ...optionalParams: unknown[]): void {
  // eslint-disable-next-line no-console
  console.debug(message, optionalParams);
  preserveLogForDebugging(message, optionalParams);
}

function error(message?: unknown, ...optionalParams: unknown[]): void {
  // eslint-disable-next-line no-console
  console.error(message, optionalParams);
  preserveLogForDebugging(message, optionalParams);
}

function info(message?: unknown, ...optionalParams: unknown[]): void {
  // eslint-disable-next-line no-console
  console.info(message, optionalParams);
  preserveLogForDebugging(message, optionalParams);
}

function log(message?: unknown, ...optionalParams: unknown[]): void {
  // eslint-disable-next-line no-console
  console.log(message, optionalParams);
  preserveLogForDebugging(message, optionalParams);
}

function warn(message?: unknown, ...optionalParams: unknown[]): void {
  // eslint-disable-next-line no-console
  console.warn(message, optionalParams);
  preserveLogForDebugging(message, optionalParams);
}

const LogService = {
  debug,
  error,
  info,
  log,
  warn,
  logCollection,
};

export default LogService;
