import React, { Fragment, ReactNode } from "react";
import styled from "styled-components";
import { default as MuiTableBody } from "@mui/material/TableBody";

import LibraryTableHeaders, { LibraryTableHeader } from "./LibraryTableHeaders";
import LibraryTableRows from "./LibraryTableRows";

export interface TableRowSet<T> {
  label?: Array<LibraryTableHeader<Record<string, number>>>;
  rows: Array<Record<keyof T, ReactNode>>;
}

interface LibraryTableRowsProps<T> {
  headers: LibraryTableHeader<Record<string, number>>[];
  rowSets: TableRowSet<T>[];
  loading: boolean;
  isRowSelected?(row: T): boolean;
}

const TableBody = styled(MuiTableBody)`
  transition: opacity 0.15s;

  &.loading {
    opacity: 0.35;
  }
`;

const LibraryTableBody: <T>(props: LibraryTableRowsProps<T>) => JSX.Element = ({
  rowSets,
  headers,
  loading,
  isRowSelected,
}) => {
  return (
    <>
      {rowSets.map((set, index) => (
        <Fragment key={index}>
          {set.label ? (
            <LibraryTableHeaders
              headers={set.label}
              subheader={Boolean(true)}
              loading={loading}
              empty={!loading && set.rows.length === 0}
            />
          ) : null}
          <TableBody className={loading ? "loading" : ""}>
            <LibraryTableRows
              rows={set.rows}
              headers={headers}
              isRowSelected={isRowSelected}
            />
          </TableBody>
        </Fragment>
      ))}
    </>
  );
};

export default LibraryTableBody;
