import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  Font,
} from "@react-pdf/renderer";
import {
  PredictionValue,
  StoredMeasurementHistoryItem,
  formatToDateTime,
  mapAssessmentToColor,
  mapAssessmentToUserString,
} from "lib-core";
import { Theme } from "@mui/material";
import MeasurementDetailsProvider from "./MeasurementDetailsProvider";

import PdfBrandPattern from "../components/svgs/PdfBrandPattern";
import PdfNovartisLogo from "../components/svgs/PdfNovartisLogo";

import ArialFont from "../components/fonts/arial.ttf";
import ArialBlackFont from "../components/fonts/arial_black.ttf";
import ArialBoldFont from "../components/fonts/arial_bold.ttf";

Font.register({
  family: "Arial",
  fonts: [
    { src: ArialFont }, // font-style: normal, font-weight: normal
    { src: ArialBlackFont, fontWeight: "heavy" },
    { src: ArialBoldFont, fontWeight: "bold" },
  ],
});

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: "row",
    backgroundColor: "#ffffff",
    padding: 20,
  },
  content: {
    flexDirection: "row",
    paddingVertical: 20,
    paddingRight: 20,
    paddingLeft: 30,
    flexGrow: 1,
  },
  leftPadding: {
    width: 50,
    height: "100%",
  },
  section: {
    flexGrow: 1,
    justifyContent: "space-between",
  },
});

type Props = {
  measurementContent: StoredMeasurementHistoryItem;
  spectraImageBase64: string;
  theme: Theme;
};

const ContentLabel = ({
  children,
  color,
}: {
  children: React.ReactNode;
  color: string;
}) => {
  return (
    <Text
      style={{ fontSize: 10, fontFamily: "Arial", marginVertical: 5, color }}
    >
      {children}
    </Text>
  );
};

const ValueTitle = ({
  children,
  width,
}: {
  children: React.ReactNode;
  width: number;
}) => {
  return (
    <View style={{ width, marginRight: 10 }}>
      <Text
        style={{
          fontSize: 10,
          fontFamily: "Arial",
          fontWeight: "bold",
          marginVertical: 5,
        }}
      >
        {children}
      </Text>
    </View>
  );
};

const KeyValueColumn = ({
  payload,
  titleWidth,
}: {
  payload: Record<string, React.ReactNode>;
  titleWidth: number;
}) => {
  return (
    <View style={{ flexDirection: "column", width: "100%" }}>
      {Object.entries(payload).map(([key, value]) => {
        return (
          <View style={{ flexDirection: "row", width: "100%" }} key={key}>
            <View style={{ width: "45%" }}>
              <ValueTitle width={titleWidth}>{key}</ValueTitle>
            </View>
            <View style={{ width: "55%" }}>
              {typeof value === "string" ? (
                <ContentLabel color="#000000">{value}</ContentLabel>
              ) : (
                <View>{value}</View>
              )}
            </View>
          </View>
        );
      })}
    </View>
  );
};

const mapPredictionColorForPdf = (
  predictionValue: PredictionValue,
  theme: Theme
): string => {
  switch (predictionValue) {
    case PredictionValue.Pass:
    case PredictionValue.ConfirmedPass:
      return theme.palette.success.main;

    case PredictionValue.Fail:
    case PredictionValue.ConfirmedFail:
      return theme.palette.error.main;
    case PredictionValue.Invalid:
      return theme.palette.brandGray.dark;
    case PredictionValue.Unknown:
    default:
      return theme.palette.warning.main;
  }
};

// Create Document Component
const PdfDocumentContainer = ({
  measurementContent: content,
  spectraImageBase64,
  theme,
}: Props): JSX.Element => {
  const notes = content.notes ?? [];
  const maybeInspectionNote = (() => {
    if (content.metadata.assessmentNoteId) {
      return notes.find((note) => note.id === content.metadata.assessmentNoteId)
        ?.message;
    }

    return notes.length < 1
      ? "No notes available"
      : notes[notes.length - 1]?.message;
  })();

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.leftPadding}>
          <PdfBrandPattern />
          {/* <Image source={novartisDigitalPattern} /> */}
        </View>
        <View style={styles.content}>
          <View style={styles.section}>
            <View>
              <Text
                style={{
                  fontWeight: "heavy",
                  fontSize: 32,
                  fontFamily: "Arial",
                }}
              >
                Measurement report
              </Text>
              <Text style={{ fontSize: 20, fontFamily: "Arial" }}>
                Authentifield by Novartis
              </Text>
              <MeasurementDetailsProvider content={content}>
                {({
                  measurementId,
                  product,
                  batchNumber,
                  caseNumber,
                  manufactured,
                  expires,
                  user,
                  sensor,
                  timestamp,
                  location,
                  prediction,
                  modelStatus,
                  assessment,
                  assessmentTime,
                  assessmentUser,
                }) => {
                  const predictionColor = mapPredictionColorForPdf(
                    prediction,
                    theme
                  );

                  return (
                    <View style={{ marginTop: 20 }}>
                      <View style={{ flexGrow: 1, flexDirection: "row" }}>
                        <ValueTitle width={105}>Measurement ID</ValueTitle>

                        <View style={{ flexGrow: 1, paddingLeft: "5px" }}>
                          <ContentLabel color="#000000">
                            {measurementId}
                          </ContentLabel>
                        </View>
                      </View>
                      <View style={{ flexDirection: "row" }}>
                        <View style={{ flexGrow: 1, width: "60%" }}>
                          <KeyValueColumn
                            titleWidth={100}
                            payload={{
                              Product: product,
                              "Manufacturing date": manufactured,
                              "Expiration date": expires,
                              "Batch Number": batchNumber,
                              "Case Number": caseNumber ?? "",
                              Model: modelStatus,
                              ...(assessment
                                ? {
                                    Assessment: (() => {
                                      const assessmentColor =
                                        mapAssessmentToColor(assessment, theme);
                                      return (
                                        <View style={{ flexGrow: 1 }}>
                                          <View
                                            style={{
                                              flexDirection: "row",
                                              alignItems: "center",
                                            }}
                                          >
                                            <View
                                              style={{
                                                backgroundColor:
                                                  assessmentColor,
                                                width: 12,
                                                height: 12,
                                                borderRadius: 6,
                                                marginRight: 5,
                                              }}
                                            />
                                            <ContentLabel
                                              color={assessmentColor}
                                            >
                                              {mapAssessmentToUserString(
                                                assessment
                                              )}
                                            </ContentLabel>
                                          </View>
                                        </View>
                                      );
                                    })(),
                                    "Analyst ID": assessmentUser,
                                  }
                                : {}),
                            }}
                          />
                        </View>
                        <View
                          style={{
                            flexGrow: 1,
                            marginLeft: 10,
                            width: "40%",
                          }}
                        >
                          <KeyValueColumn
                            titleWidth={60}
                            payload={{
                              User: user ?? "",
                              Sensor: sensor,
                              Location: location,
                              Timestamp: timestamp,
                              Result: (
                                <View style={{ flexGrow: 1 }}>
                                  <View
                                    style={{
                                      flexDirection: "row",
                                      alignItems: "center",
                                    }}
                                  >
                                    <View
                                      style={{
                                        backgroundColor: predictionColor,
                                        width: 12,
                                        height: 12,
                                        borderRadius: 6,
                                        marginRight: 5,
                                      }}
                                    />
                                    <ContentLabel color={predictionColor}>
                                      {prediction}
                                    </ContentLabel>
                                  </View>
                                </View>
                              ),
                              ...(assessment
                                ? {
                                    "Assessed On": assessmentTime,
                                  }
                                : {}),
                            }}
                          />
                        </View>
                      </View>
                    </View>
                  );
                }}
              </MeasurementDetailsProvider>

              <Image source={spectraImageBase64} />
              <View style={{ flexDirection: "row", marginTop: 20 }}>
                <View style={{ marginRight: 20 }}>
                  <ValueTitle width={80}>Inspection notes</ValueTitle>
                </View>
                <View style={{ flexGrow: 1 }}>
                  <ContentLabel color="#000000">
                    {maybeInspectionNote}
                  </ContentLabel>
                </View>
              </View>
            </View>
            <View
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "flex-end",
              }}
            >
              <PdfNovartisLogo />
              <Text style={{ fontFamily: "Arial", fontSize: 10 }}>
                Classification: Restricted
              </Text>
              <Text style={{ fontFamily: "Arial", fontSize: 10 }}>
                Generated {formatToDateTime(new Date().toISOString())}
              </Text>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default PdfDocumentContainer;
