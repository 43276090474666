import { useLayoutEffect } from "react";

const RedirectMobile: () => null = () => {
  useLayoutEffect(() => {
    window.location.href = "authentifield://localhost/logout/success";
  });

  return null;
};

export default RedirectMobile;
