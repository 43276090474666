import { DeviceInfo } from "../../types/types-bluetooth";
import BluetoothService from "./BluetoothService";

const getDeviceInfo = async (deviceAddress: string): Promise<DeviceInfo> => {
  try {
    await BluetoothService.createConnection(deviceAddress);
    const result = await BluetoothService.readDeviceInfo(deviceAddress);
    return result;
  } finally {
    await BluetoothService.disconnect(deviceAddress);
  }
};

export default getDeviceInfo;
