import React, { useCallback, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import WarningIcon from "@mui/icons-material/Warning";
import styled from "styled-components";
import { formatToDateOnly } from "lib-core";
import { useBlendedSnapshot } from "../../library/blendedSnapshot-hook";
import {
  Container,
  ButtonBox,
  WideButton,
  TitleBox,
  TextContainer,
  InnerContainer,
} from "./snapshotBoxes.styles";
import { BlendedSnapshotTable } from "./BlendedSnapshotTable";

const BlendedSnapshotContainer = styled(Container)`
  margin: 0 20px;
`;

const BSInnerContainer = styled(InnerContainer)`
  flex-direction: column;
`;

const GreyedTypography = styled(Typography)`
  color: ${({ theme }) => theme.palette.brandGray.dark};
`;

const ShowHistoryButton = styled(WideButton)`
  margin: 5px 0px 0px;
`;

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface SnapshotProps {}

const BlendedSnapshotBox: (props: SnapshotProps) => JSX.Element = () => {
  const {
    isFetchingBlendedSnapshots,
    isErrorFetchingBlendedSnapshots,
    blendedSnapshots,
    usableSnapshots,
    latestUsableSnapshot,
    isLastSnapshotFailed,
  } = useBlendedSnapshot();

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const versionString = (() => {
    if (isFetchingBlendedSnapshots) {
      return "Loading blended snapshot";
    }
    if (isErrorFetchingBlendedSnapshots) {
      return "There was an error loading blended snapshots";
    }
    if (usableSnapshots?.length === 0) {
      return "There are no Blended Snapshots for this protocol";
    }
    if (latestUsableSnapshot) {
      return `V${latestUsableSnapshot.version}.0 created on ${formatToDateOnly(
        latestUsableSnapshot.createdOn
      )}`;
    }
    return `No usable blended snapshots`;
  })();

  const toggleBlendedSnapshotHistoryDialog = useCallback(() => {
    setIsDialogOpen((prev) => !prev);
  }, []);

  return (
    <>
      <BlendedSnapshotContainer>
        <TitleBox>
          <GreyedTypography variant="h4">
            Blended snapshot{" "}
            {isLastSnapshotFailed && (
              <WarningIcon
                color="error"
                style={{ verticalAlign: "middle", marginLeft: 15 }}
              />
            )}
          </GreyedTypography>
        </TitleBox>
        <BSInnerContainer>
          <TextContainer>
            <GreyedTypography variant="body1">{versionString}</GreyedTypography>
          </TextContainer>
          {!!blendedSnapshots?.length && (
            <ButtonBox>
              <ShowHistoryButton
                variant="outlined"
                onClick={toggleBlendedSnapshotHistoryDialog}
              >
                <Typography variant="h5">Show history</Typography>
              </ShowHistoryButton>
            </ButtonBox>
          )}
        </BSInnerContainer>
      </BlendedSnapshotContainer>
      {!!blendedSnapshots && (
        <Dialog open={isDialogOpen} maxWidth="xl">
          <DialogTitle>Blended snapshot history</DialogTitle>
          <DialogContent>
            <BlendedSnapshotTable
              latestUsableSnapshot={latestUsableSnapshot}
              blendedSnapshots={blendedSnapshots}
            />
          </DialogContent>
          <br />
          <DialogActions>
            <Button
              onClick={toggleBlendedSnapshotHistoryDialog}
              variant="outlined"
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default BlendedSnapshotBox;
