import { bluetoothle, bytesToEncodedString } from "./bluetoothle";
import {
  serviceUUID,
  creditsUUID,
  fifoUUID,
  CREDITS_16_SIGNAL,
  CLOSE_FLOW_CONTROL,
} from "./constants";

import { Message, createParser } from "./message";
import wait from "../helper/wait";
import {
  BLE_SUBSCRIBE_FIFO,
  BLE_SUBSCRIBE_CREDITS,
  FLOW_WRITE,
  FLOW_CLOSE,
} from "./error_codes";
import isObject from "../helper/utils";
import writeToDevice from "./write";
import LogService from "../../services/LogService";
// import unsubscribe from "./unsubscribe";

interface Flow {
  write: (fifo: string[]) => Promise<boolean>;
  subscribe: (messageID: number, listener: (message: Message) => void) => void;
  close: () => Promise<boolean>;
}

const MIN_TX_CREDITS = 2;
const MIN_RX_CREDITS = 2;

/**
 * Create a `flow control` by subscribe to credits and fifo characteristics.
 * Returns a [flow](#flow) object which can be used
 * to manage the "flow" ([write](#writetodevice)/[subscribe](#subscribeflow)/[close](#closeflow)).
 * @param {string} address
 * @param {flowErrorHandler} [errorHandler=(e) =>  {console.error(e);}]
 * @returns {flow}
 */
/**
 * Handler of error happend during flow connection with devices.
 * @callback flowErrorHandler
 * @param  {object|string} error - errors occur in the flow.
 */
const isBLEError = (
  error: string | BLECentralPlugin.BLEError
): error is BLECentralPlugin.BLEError & Error => isObject(error);

/**
 * The `flowControl` object created by [createFlow](#createflow),
 * has two methods to manage the opening "flow" ([write](#writetodevice)/[subscribe](#subscribeflow)/[close](#closeflow)).
 * @typedef {Object} flow
 * @property {function(Array.<string>):Promise<boolean>} write
 * @property {function(number,function)} subscribe
 * @property {function():Promise<boolean>} close
 */
const createFlow = (
  address: string,
  errorHandler = (error: Error) => {
    // eslint-disable-next-line no-console
    LogService.error(error);
  }
): Promise<Flow> => {
  return new Promise((resolve, reject) => {
    // If the credits you gave to device go to zero, you need to write more to the credits characteristic.
    // It means that you're telling the device it can freely send 16 messages to app.
    // When apps writes 0x10 to device = app gave 0x10 credits to device
    // If you don't write any more credits after the beginning exchange,
    // you will notice that the device stops sending notifications to FIFO at some point.
    let creditsAppGaveToDevice = 0;
    //  When you send a (FIFO) message (to device), you subtract one from those that device gave to you.
    //  If the credits device gave to you go to zero, you need to wait until the deivce sends a notification
    // from the credits characteristic
    let creditsDeviceGaveToApp = 0;
    let flowControlEnabled = true;
    let listeners: {
      messageID: number;
      listener: (message: Message) => void;
    }[] = [];
    const { handler: messageHandler } = createParser();
    let creditsGrantRequestOngoing = false;

    /**
     * @name write
     * @description Write message to device.
     * Before writing, it will make sure the central (mobile app) has enough credits given by the device by calling [hasEnoughCredits](#hasenoughCredits), and
     * write to device and return Promise resolve with true if the fifo has been written.
     * @param {Array.<string>} fifo
     * @returns {Promise<boolean>}
     */
    const write = async (fifo: string[]): Promise<boolean> => {
      for (const data of fifo) {
        // TODO: this can cause a memory leak / infinite asynchronous loop, if the flow is closed but
        // credits are never given.
        // Easy fix would be to exit the loop if the flow is closed.
        while (creditsDeviceGaveToApp < MIN_RX_CREDITS) {
          // eslint-disable-next-line no-await-in-loop
          await wait(100);

          if (!flowControlEnabled) {
            return false;
          }
        }

        try {
          // eslint-disable-next-line no-await-in-loop
          const written = await writeToDevice(
            address,
            serviceUUID,
            fifoUUID,
            data
          );

          if (written) {
            creditsDeviceGaveToApp -= 1;
          }
        } catch (error) {
          LogService.error(error);
          LogService.error(
            `Error - ${FLOW_WRITE} : can't subscribe to FIFO characteristic, reason: ${JSON.stringify(
              error
            )}`
          );
          errorHandler(
            new Error(`Error - ${FLOW_WRITE} : can't connect to device`)
          );

          return false;
        }
      }

      return true;
    };
    /**
     * @name subscribeFlow
     * @description Subscribe (add the subscriber to the list of listeners) by the given messageID.
     * @param {number} messageID
     * @param {function} listener
     */
    const subscribe = (
      messageID: number,
      listener: (message: Message) => void
    ) => {
      listeners = [...listeners, { messageID, listener }];
    };
    /**
     * @name closeFlow
     * @description Close the flow by writing CLOSE_FLOW_CONTROL to device.
     * Will also unsubscribe to credits and fifo characteristics
     * @returns {Promise<boolean>}
     */
    const close = async (): Promise<boolean> => {
      try {
        await writeToDevice(
          address,
          serviceUUID,
          creditsUUID,
          CLOSE_FLOW_CONTROL
        );

        while (flowControlEnabled) {
          // eslint-disable-next-line no-await-in-loop
          await wait(100);
        }

        creditsGrantRequestOngoing = false;

        // try {
        //   creditCharacteristicSubscribed = !(await unsubscribe(
        //     address,
        //     serviceUUID,
        //     creditsUUID
        //   ));
        //   FIFOCharacteristicSubscribed = !(await unsubscribe(
        //     address,
        //     serviceUUID,
        //     fifoUUID
        //   ));
        // } catch (e) {
        //   LogService.error(
        //     "Unsubscribe failed ",
        //     e,
        //     "this can be ignored and unsubscribe will automatically happen with the next disconnect"
        //   );
        //   // && creditCharacteristicSubscribed === false &&  FIFOCharacteristicSubscribed === false
        // }

        if (flowControlEnabled === false) {
          return true;
        }
      } catch (e) {
        LogService.error(`Error - ${FLOW_CLOSE}: ${JSON.stringify(e)}`);
        errorHandler(
          new Error(`Error - ${FLOW_CLOSE} : can't connect to device`)
        );
      }

      return false;
    };
    /**
     * Trigger the listeners (subscribers).
     * @param {object} message
     */
    const triggerSubscriptions = (message: Message) => {
      listeners.forEach(({ messageID, listener }) => {
        if (typeof listener !== "function")
          throw new Error("listener should be a func");

        if (message.messageID === messageID) {
          listener(message);
        }
      });
    };

    const checkTXCredits = async () => {
      if (
        creditsAppGaveToDevice > MIN_TX_CREDITS ||
        creditsGrantRequestOngoing
      ) {
        return;
      }
      creditsGrantRequestOngoing = true;
      LogService.log(
        "[BLE] createFlow, credits app gave 16 running low, write to device to give more"
      );
      try {
        const written = await writeToDevice(
          address,
          serviceUUID,
          creditsUUID,
          CREDITS_16_SIGNAL
        );
        if (written) {
          LogService.log(
            "[BLE] createFlow, app gave 16 credits to device",
            written
          );
          creditsAppGaveToDevice += 16;
          creditsGrantRequestOngoing = false;
        }
      } catch (e) {
        LogService.error(
          `[BLE] createFlow, app gave 16 credits to device : ${JSON.stringify(
            e
          )} address: ${address}. Wrote value ${CREDITS_16_SIGNAL}`
        );

        errorHandler(
          new Error("[BLE] createFlow, error while checking credits")
        );
      }
    };

    try {
      // subscribe to credits
      bluetoothle.startNotification(
        address,
        serviceUUID,
        creditsUUID,
        (res) => {
          const responseIntArray = new Uint8Array(res);
          const newCredits = responseIntArray[0];

          if (newCredits === 255) {
            // 255 is -1 in two's complement
            flowControlEnabled = false;
            creditsGrantRequestOngoing = false;
            LogService.log(
              "[BLE] createFlow, flow control closed confirmed by device."
            );

            return;
          }

          // @ts-expect-error: TODO fix unchecked indexed access
          if (newCredits > 0) {
            flowControlEnabled = true;
            // @ts-expect-error: TODO fix unchecked indexed access
            creditsDeviceGaveToApp += newCredits;
            LogService.log(
              `[BLE] createFlow, subscription of credits got new credits ${newCredits}, current credits deviceGaveToApp = ${creditsDeviceGaveToApp}`
            );
            resolve({
              write,
              subscribe,
              close,
            });
          }
        },
        (error) => {
          LogService.log("In error of start notification ", error);
          if (isBLEError(error)) {
            if (error.message === "Device is disconnected") {
              errorHandler(
                new Error(
                  `Error - ${BLE_SUBSCRIBE_CREDITS} : can't connect to device`
                )
              );

              return;
            }

            if (error.message === "Already subscribed") {
              return;
            }
          }

          LogService.error(
            `Error - ${BLE_SUBSCRIBE_CREDITS} : can't subscribe to credits characteristic, reason: ${JSON.stringify(
              error
            )}, if this keeps happening please follow the instruction in my devices page to reset the device`
          );
          errorHandler(
            new Error(
              `Error - ${BLE_SUBSCRIBE_CREDITS} : can't connect to device`
            )
          );
        }
      );
    } catch (e) {
      LogService.log("catch credits sub", e);
    }

    try {
      // subscribe to FIFO
      bluetoothle.startNotification(
        address,
        serviceUUID,
        fifoUUID,
        async (res) => {
          const responseIntArray = new Uint8Array(res);
          creditsAppGaveToDevice -= 1;

          // Note: it appears the promise is never resolved if done is not true
          messageHandler(
            bytesToEncodedString(Array.from(responseIntArray))
          ).then(
            (message) => {
              triggerSubscriptions(message);
            },
            (e) => {
              errorHandler(e);
            }
          );
          await checkTXCredits();
        },
        (error) => {
          LogService.log("Error is startNotification");
          if (isBLEError(error)) {
            if (error.message === "Device is disconnected") {
              errorHandler(
                new Error(
                  `Error - ${BLE_SUBSCRIBE_FIFO} : can't connect to device`
                )
              );

              return;
            }

            if (error.message === "Already subscribed") {
              return;
            }
          }

          LogService.error(
            `Error - ${BLE_SUBSCRIBE_FIFO} : can't subscribe to FIFO characteristic, reason: ${JSON.stringify(
              error
            )}`
          );
          errorHandler(
            new Error(
              `Error - ${BLE_SUBSCRIBE_FIFO} : can't connect to device, if this keeps happening please follow the instruction in my devices page to reset the device`
            )
          );
        }
      );
    } catch (e) {
      LogService.log("catch fifo sub", e);
    }

    writeToDevice(address, serviceUUID, creditsUUID, CREDITS_16_SIGNAL)
      .then((written) => {
        if (written) {
          creditsAppGaveToDevice += 16;
        }
      })
      .catch((error) => {
        LogService.error(
          `[BLE] createFlow : ${JSON.stringify(
            error
          )} : RXC:${creditsAppGaveToDevice}: address: ${address}. Wrote ${CREDITS_16_SIGNAL}`
        );
        errorHandler(
          new Error(
            `[BLE] createFlow : RXC:${creditsAppGaveToDevice}, if this keeps happening please follow the instruction in my devices page to reset the device`
          )
        );
        reject();
      });
  });
};

export { createFlow };
export default createFlow;
