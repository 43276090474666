import { Geolocation, Position } from "@capacitor/geolocation";

const getCurrent = async (): Promise<Position> => {
  const permissionState = await Geolocation.requestPermissions({
    permissions: ["coarseLocation"],
  });
  if (permissionState.coarseLocation === "denied") {
    throw new Error("Location permission denied");
  }
  const position = await Geolocation.getCurrentPosition().catch((e) => {
    throw Error(e.message);
  });

  return position;
};

const LocationService = {
  getCurrent,
};

export default LocationService;
