import React from "react";
import { Button, Link, Typography } from "@mui/material";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import { Constants } from "lib-core";
import NovartisLogo from "./components/NovartisLogo";

const Container = styled.div`
  display: flex;
  height: 100vh;
  width: 100vw;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 2.2rem;
`;

const loginUrl = process.env.REACT_APP_LOGIN_URL;
const clientId = process.env.REACT_APP_AD_CLIENTID;
const responseType = process.env.REACT_APP_AD_TYPE;
const scope = process.env.REACT_APP_AD_SCOPE;
const redirectHost = process.env.REACT_APP_AD_REDIRECT;

const externalLoginUrl = `${loginUrl}?
    client_id=${clientId}
    &response_type=${responseType}
    &scope=${scope}
    &redirect_uri=${redirectHost}
`.replace(/\s/g, "");

const Login: () => JSX.Element = () => {
  const { search } = useLocation();
  const logout = new URLSearchParams(search).get("logout");
  const tokens =
    localStorage.getItem(Constants.accessToken) &&
    localStorage.getItem(Constants.refreshToken);

  if (logout === "success" && localStorage.getItem(Constants.accessToken)) {
    localStorage.removeItem(Constants.accessToken);
    localStorage.removeItem(Constants.refreshToken);
    localStorage.removeItem(Constants.userInfo);
    localStorage.removeItem(Constants.userPermissions);
  }

  return (
    <Container>
      <NovartisLogo width="250" />
      <Button
        data-testid="login-user-button"
        href={externalLoginUrl}
        underline="none"
        component={Link}
        variant="outlined"
      >
        Login
      </Button>
      {logout === "success" && !tokens ? (
        <Typography variant="h4">Logout successful!</Typography>
      ) : null}
    </Container>
  );
};

export default Login;
