import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { Button, Typography } from "@mui/material";

import {
  Filter,
  FilterTypes,
  InputChange,
  KeyValueParams,
} from "./datafilter-types";
import { createKeyValueParams } from "./datafilter-helpers";
import { useDebounce } from "../../lib/helper/debounce";
import DateFilter from "./DateFilter";
import DatetimeFilter from "./DatetimeFilter";
import AutocompleteFilter from "./AutocompleteFilter";
import DefaultFilter from "./DefaultFilter";
import useNotification from "../notifications/hook";
import { NotificationTypes } from "../notifications/notification-types";

interface DataFilterProps {
  delay: number;
  filters: Filter[];
  onChange: (payload: KeyValueParams) => void;
  title?: string;
  hideClearButton?: boolean;
  className?: string;
}

const Container = styled.aside`
  display: flex;
  flex-direction: column;

  > div {
    margin-bottom: 14px;
  }
`;

const Headline = styled(Typography)`
  margin: 14px 0 16px;
`;

const DataFilter: (props: DataFilterProps) => JSX.Element = ({
  delay,
  filters,
  onChange,
  title = "Filter by column",
  hideClearButton = false,
  className = "",
}) => {
  const [filterList, setFilterList] = useState<Filter[]>(filters);
  const { debounce } = useDebounce<KeyValueParams>(onChange, delay);
  const deviceSerialNumberRegexp = new RegExp("^[A-Za-z0-9]{0,11}$");
  const notification = useNotification();

  const allAvailableOptions = filters
    .map((filter) => filter.options?.join(","))
    .join();

  // We prevent filters option to reset unnecessary so that the list of option only changes
  // when unique values available for filtering changes. We do this while trying to also
  // keep the existing selection that user have made
  useEffect(() => {
    setFilterList((prevFilters) => {
      return filters.map((filter) => {
        return {
          ...filter,
          // Merge previous selected value to new options of filter, for UX consistency
          value:
            prevFilters.find((prevFilter) => prevFilter.name === filter.name)
              ?.value ?? null,
        };
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allAvailableOptions]);

  const clearState = () => {
    const state = filterList.map((filter: Filter) => {
      return { ...filter, value: "" };
    });

    const payload = createKeyValueParams(state);

    setFilterList(state);
    debounce(payload);
  };

  const updateFilterList = (name: string, value: string) => {
    const state = filterList.map((filter: Filter) => {
      if (filter.name !== name) {
        return filter;
      }

      return { ...filter, value };
    });

    const payload = createKeyValueParams(state);

    setFilterList(state);
    debounce(payload);
  };

  const testDeviceSerialNumber = (value: string) => {
    if (!deviceSerialNumberRegexp.test(value)) {
      notification.createNotification({
        type: NotificationTypes.Snackbar,
        message: "Invalid character in device serial number filter",
        severity: "error",
      });
      return false;
    }
    return true;
  };

  const onChangeString = (name: string, ev: InputChange) => {
    const { value } = ev.target;
    if (
      name === "deviceSerialNumber" &&
      testDeviceSerialNumber(value) === false
    ) {
      return;
    }
    updateFilterList(name, value);
  };

  const onChangeDateTime = (name: string, value: string | null) => {
    if (value !== null) {
      updateFilterList(name, new Date(value).toISOString());
    }
  };

  const mapFiltersForRendering = (filter: Filter) => {
    const { type } = filter;
    switch (type) {
      case FilterTypes.Autocomplete:
        return (
          <AutocompleteFilter
            filter={filter}
            key={filter.name}
            updateFilterList={updateFilterList}
          />
        );
      case FilterTypes.DateTime:
        return (
          <DatetimeFilter
            filter={filter}
            key={filter.name}
            onChangeDateTime={onChangeDateTime}
          />
        );
      case FilterTypes.Date:
        return (
          <DateFilter
            filter={filter}
            key={filter.name}
            onChangeDateTime={onChangeDateTime}
          />
        );
      default:
        return (
          <DefaultFilter
            filter={filter}
            key={filter.name}
            onChangeString={onChangeString}
          />
        );
    }
  };

  return (
    <Container className={`DataFilter ${className}`}>
      {title && <Headline variant="h4">{title}</Headline>}
      {filterList.map(mapFiltersForRendering)}
      {!hideClearButton && (
        <div>
          <Button
            onClick={clearState}
            variant="text"
            size="small"
            data-testid="clear-filters-button"
          >
            Clear Filters
          </Button>
        </div>
      )}
    </Container>
  );
};

export default DataFilter;
