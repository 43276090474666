import Constants from "../constants";

export const mockProtocols = [
  {
    description: "DS2.0 default protocol",
    wavelengths: [
      1950, 1948, 1946, 1944, 1942, 1940, 1938, 1936, 1934, 1932, 1930, 1928,
      1926, 1924, 1922, 1920, 1918, 1916, 1914, 1912, 1910, 1908, 1906, 1904,
      1902, 1900, 1898, 1896, 1894, 1892, 1890, 1888, 1886, 1884, 1882, 1880,
      1878, 1876, 1874, 1872, 1870, 1868, 1866, 1864, 1862, 1860, 1858, 1856,
      1854, 1852, 1850, 1848, 1846, 1844, 1842, 1840, 1838, 1836, 1834, 1832,
      1830, 1828, 1826, 1824, 1822, 1820, 1818, 1816, 1814, 1812, 1810, 1808,
      1806, 1804, 1802, 1800, 1798, 1796, 1794, 1792, 1790, 1788, 1786, 1784,
      1782, 1780, 1778, 1776, 1774, 1772, 1770, 1768, 1766, 1764, 1762, 1760,
      1758, 1756, 1754, 1752, 1750, 1748, 1746, 1744, 1742, 1740, 1738, 1736,
      1734, 1732, 1730, 1728, 1726, 1724, 1722, 1720, 1718, 1716, 1714, 1712,
      1710, 1708, 1706, 1704, 1702, 1700, 1698, 1696, 1694, 1692, 1690, 1688,
      1686, 1684, 1682, 1680, 1678, 1676, 1674, 1672, 1670, 1668, 1666, 1664,
      1662, 1660, 1658, 1656, 1654, 1652, 1650, 1648, 1646, 1644, 1642, 1640,
      1638, 1636, 1634, 1632, 1630, 1628, 1626, 1624, 1622, 1620, 1618, 1616,
      1614, 1612, 1610, 1608, 1606, 1604, 1602, 1600, 1598, 1596, 1594, 1592,
      1590, 1588, 1586, 1584, 1582, 1580, 1578, 1576, 1574, 1572, 1570, 1568,
      1566, 1564, 1562, 1560, 1558, 1556, 1554, 1552, 1550,
    ],
    wavelengthAverage: 300,
    scanAverage: 1,
    lightSourceIntensity: 100,
    lightSourceAutomaticMode: true,
    subtractDark: true,
    whiteReferenceValidationModelName: "white_reference_validation",
    name: Constants.defaultProtocol,
    createdOn: "2022-09-06T13:18:06Z",
    modifiedOn: "2022-09-06T13:18:06Z",
  },
];
