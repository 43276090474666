import styled from "styled-components";

const Th = styled.th`
  text-align: left;
  vertical-align: top;
  padding: 7px 10px;
  &:first-child {
    padding-left: 0;
  }
`;

const Td = styled.td`
  padding: 7px 10px;
  &:last-child {
    padding-right: 0;
  }
`;

export { Th, Td };
