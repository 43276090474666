import React from "react";
import { TableCell, TableRow } from "@mui/material";
import { BlendedSnapshotUpcoming, formatToDateOnly } from "lib-core";
import styled from "styled-components";
import { failedOrUpcomingLastCol, headers } from "./blendedSnapshot.headers";

const GreyedTableRow = styled(TableRow)`
  cursor: pointer;
  background-color: ${({ theme }) => theme.palette.brandGray.light};
  & > * {
    color: ${({ theme }) => theme.palette.brandGray.dark};
  }
`;

type Props = {
  snapshot: BlendedSnapshotUpcoming;
};

export const UpcomingBlendedSnapshotRow = ({
  snapshot,
}: Props): JSX.Element => {
  return (
    <>
      <GreyedTableRow>
        <TableCell {...headers[0].cellProps}>
          <b>V{snapshot.version}.0 — Planned</b>
        </TableCell>
        <TableCell {...headers[1].cellProps}>
          {formatToDateOnly(snapshot.timestamp)}
        </TableCell>

        <TableCell {...failedOrUpcomingLastCol.cellProps}>
          Next scheduled generation
        </TableCell>
      </GreyedTableRow>
    </>
  );
};
