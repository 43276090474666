export enum NotificationTypes {
  Banner = "banner",
  Snackbar = "snackbar",
}

export const notifEventName = "notifAlert";
export const notifDismissEventName = "notifDismiss";

export type HorizontalPositions = "left" | "right";

export type VerticalPositions = "bottom" | "top";

export type NotificationList = NotificationOptions[];

export type NotificationOptions = {
  action?: JSX.Element;
  horizontal?: HorizontalPositions;
  key?: string;
  message: string;
  persistent?: boolean;
  severity?: "error" | "info" | "success" | "warning";
  showClose?: boolean;
  type: NotificationTypes;
  vertical?: VerticalPositions;
};

export type NotificationValues = {
  createNotification: (detail: NotificationOptions) => void;
  dismissPersistentNotification: (key: string) => void;
};
