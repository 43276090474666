enum PredictionValue {
  Pass = "Pass",
  Fail = "Fail",
  Unknown = "Unknown",
  Invalid = "Invalid",
  ConfirmedPass = "ConfirmedPass",
  ConfirmedFail = "ConfirmedFail",
}

export default PredictionValue;
