import React from "react";
import styled from "styled-components";
import { ImageState } from "./history-hook";

interface SpectraImageProps {
  measurementId: string;
  spectraData: { spectra?: ImageState; error?: unknown };
}

const SpectraImage = styled.img`
  display: block;
  width: 100%;
  mix-blend-mode: multiply;
`;

const SpectraImageLoading = styled.div`
  color: ${({ theme }) => theme.palette.brandGray.dark};
  text-align: center;
`;

const DetailsViewSpectraImage: (props: SpectraImageProps) => JSX.Element = ({
  measurementId,
  spectraData,
}) => {
  if (spectraData.error) {
    return (
      <SpectraImageLoading>
        Error loading measurement spectra image
      </SpectraImageLoading>
    );
  }

  return spectraData.spectra ? (
    <SpectraImage
      src={spectraData.spectra?.base64}
      alt={`Spectra of measurement id ${measurementId}`}
    />
  ) : (
    <SpectraImageLoading>Loading measurement spectra image</SpectraImageLoading>
  );
};

export default DetailsViewSpectraImage;
