import React, { useState } from "react";
import TextField from "@mui/material/TextField";

interface ProductDetailsInputProps {
  inputValue: string;
  id: string;
  label: string;
  onChange: (newValue: string) => void;
}

const ProductDetailsInput: (props: ProductDetailsInputProps) => JSX.Element = ({
  inputValue,
  id,
  label,
  onChange,
  ...props
}) => {
  const [errorText, setErrorText] = useState("");

  const validateInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const alphanumeric = /^[a-z0-9]+$/i;

    const invalid =
      (value.length > 32 || !alphanumeric.test(value)) && value.length > 0;
    setErrorText(invalid ? "Invalid format" : "");
    onChange(value);
  };

  return (
    <TextField
      fullWidth
      data-testid={id}
      error={!!errorText}
      helperText={errorText}
      id={id}
      label={label}
      onChange={validateInput}
      type="text"
      value={inputValue}
      {...props}
    />
  );
};

export default ProductDetailsInput;
