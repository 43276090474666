/* eslint-disable import/no-extraneous-dependencies */
import React, { useLayoutEffect } from "react";
import { useHistory } from "react-router-dom";
import { AxiosError } from "axios";
import axios from "../AxiosInstance";
import { clearTokens, refreshToken } from "./helpers";
import useNotification from "../components/notifications/hook";
import { NotificationTypes } from "../components/notifications/notification-types";
import AccessControlService from "../services/domain/AccessControlService";
import LogService from "../services/LogService";

const clientId = process.env.REACT_APP_AD_CLIENTID;
const TOKEN_EXPIRED_ERROR_CODE = 401;
const ACCESS_DENIED_ERROR_CODE = 403;

interface InterceptorProps {
  // eslint-disable-next-line react/require-default-props
  onAfterReceivedErrorResponse?: (error: AxiosError) => void;
  getAccessToken: () => string;
  startLoginProcess: () => void;
  onTokenRefreshSuccessful: () => void;
  onAccessDeniedError: () => void;
  onConcurrentLimitReached: () => void;
}

function Interceptors({
  onAfterReceivedErrorResponse,
  getAccessToken,
  startLoginProcess,
  onTokenRefreshSuccessful,
  onAccessDeniedError,
  onConcurrentLimitReached,
}: InterceptorProps): JSX.Element {
  const history = useHistory();
  const notification = useNotification();

  useLayoutEffect(() => {
    const clearTokensIfNotRefreshTokenEnvSet = () => {
      LogService.log("Spawning login process");
      clearTokens();
      startLoginProcess();
    };

    /**
     * Attempt to refresh the access token.
     * On success, saves the new token to
     * localStorage and redirects user to their destination.
     *
     * Error suggests the refresh token has expired.
     * In that case, clear all tokens from localStorage
     * and redirect user to login page.
     */
    const tokenRefresh = async (): Promise<void> => {
      const token = refreshToken();
      if (token !== "" && clientId) {
        try {
          const result = await AccessControlService.acquireAccessToken(
            clientId,
            token
          );
          if (result.accessToken) {
            onTokenRefreshSuccessful();
          }
        } catch (err) {
          LogService.log("Cannot refresh access token using refresh token");
          clearTokensIfNotRefreshTokenEnvSet();
        }
      }

      if (token === "") {
        // If there is no refresh token, it makes sense to start a new login process
        startLoginProcess();
      }
    };

    /**
     * If token is found, return the token in a Bearer string
     * Otherwise return placeholder string
     */
    const bearerToken = () => `Bearer ${getAccessToken()}`;

    /**
     * Set default Content-Type and Authorization headers
     */
    const requestInterceptorId = axios.interceptors.request.use((req) => {
      req.headers["Content-Type"] = "application/json";
      req.headers.credentials = "include";
      req.headers.authorization = bearerToken();

      return req;
    });

    /**
     * React to status code TOKEN_EXPIRED_ERROR_CODE, when access token has expired,
     * and trigger attempt to refresh it
     */
    const responseInterceptorId = axios.interceptors.response.use(
      (res) => res,
      (error) => {
        if (!error.response) {
          return Promise.reject(error);
        }

        // If there is no token, no need to proceed further
        if (bearerToken() === "") {
          return Promise.reject(error);
        }

        onAfterReceivedErrorResponse?.(error);

        const { status } = error.response;

        // Case when web user token has expired.
        if (status === TOKEN_EXPIRED_ERROR_CODE) {
          LogService.log(
            `Received error ${TOKEN_EXPIRED_ERROR_CODE}, attempting refresh token`
          );
          notification.createNotification({
            type: NotificationTypes.Snackbar,
            message: "Session expired. Attempting to refresh the session.",
            severity: "warning",
          });
          tokenRefresh();
        }

        if (
          status === ACCESS_DENIED_ERROR_CODE &&
          error.response &&
          error.response.data.detail ===
            "Maximum concurrent login session limit exceeded"
        ) {
          LogService.log(
            `Received error ${ACCESS_DENIED_ERROR_CODE} concurrent login limit reached, attempting to show access denied`
          );
          onConcurrentLimitReached();
        } else if (status === ACCESS_DENIED_ERROR_CODE) {
          LogService.log(
            `Received error ${ACCESS_DENIED_ERROR_CODE}, attempting to show access denied`
          );
          onAccessDeniedError();
        }

        return Promise.reject(error);
      }
    );
    return () => {
      axios.interceptors.request.eject(requestInterceptorId);
      axios.interceptors.response.eject(responseInterceptorId);
    };
  }, [
    getAccessToken,
    history,
    notification,
    onAfterReceivedErrorResponse,
    onTokenRefreshSuccessful,
    startLoginProcess,
    onAccessDeniedError,
    onConcurrentLimitReached,
  ]);

  return <></>;
}

export default Interceptors;
