import { Dispatch, useReducer } from "react";
import { GlobalState, initialGlobalState } from "./types-state";

const useValue: () => [GlobalState, Dispatch<Partial<GlobalState>>] = () =>
  useReducer(
    (state: GlobalState, newValue: Partial<GlobalState>) => ({
      ...state,
      ...newValue,
    }),
    initialGlobalState
  );

export default useValue;
