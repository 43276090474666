import {
  List,
  ListItemButton,
  TableFooter,
  TablePagination,
  TableRow,
} from "@mui/material";
import React from "react";
import { ProductVariant } from "../../types/types-product";
import ProductNameFormatted from "./ProductNameFormatted";

interface ProductFilterProps {
  productCallback: (product: ProductVariant) => void;
  productvariants: ProductVariant[];
  className?: string;
  count: number;
  page: number;
  onPageChange: (page: number) => void;
}

type ChangePageEvent = React.MouseEvent<HTMLButtonElement> | null;

const ProductFlatlist: (props: ProductFilterProps) => JSX.Element = ({
  productvariants,
  productCallback,
  className,
  count,
  page,
  onPageChange,
}) => {
  const [selectedProduct, setSelectedProduct] =
    React.useState<ProductVariant>();

  const changePage = (_: ChangePageEvent, number: number) => {
    onPageChange(number);
  };

  return (
    <List className={className}>
      {productvariants.length > 0 &&
        productvariants.map((product, i) => (
          <ListItemButton
            className="item"
            component="li"
            onClick={() => {
              setSelectedProduct(product);
              productCallback(product);
            }}
            selected={product === selectedProduct}
            key={i}
          >
            <ProductNameFormatted product={product} />
          </ListItemButton>
        ))}

      {productvariants.length > 0 && (
        <TableFooter>
          <TableRow>
            {/* <DataTableLoading loading={loading} resultCount={count} /> */}
            <TablePagination
              count={count}
              onPageChange={changePage}
              onRowsPerPageChange={() => undefined}
              rowsPerPage={100}
              page={page}
              rowsPerPageOptions={[100]}
              SelectProps={{
                inputProps: {
                  "aria-label": "rows per page",
                },
              }}
            />
          </TableRow>
        </TableFooter>
      )}

      {productvariants.length === 0 && (
        <ListItemButton className="item" component="li" disabled>
          No products found.
        </ListItemButton>
      )}
    </List>
  );
};

export default ProductFlatlist;
