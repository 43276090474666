import * as React from "react";

const NovartisLogo = (props: React.SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 295.44 48.31"
      {...props}
    >
      <defs>
        <linearGradient
          id="a"
          x1={56.72}
          y1={64.98}
          x2={99.51}
          y2={64.98}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.3} stopColor="#e74a21" />
          <stop offset={0.7} stopColor="#ec9a1e" />
        </linearGradient>
      </defs>
      <title>NovartisLogo</title>
      <g>
        <path
          d="m86.27 60.09-.12.31c2 .88 6.49 4.37 6.49 11.4 0 6.62-5.58 13.29-14.35 13.29-10.91 0-14.67-6.38-14.67-12.24 0-7.51 5.25-12.51 5.25-19.35a10.69 10.69 0 0 0-8.42-10.32l-.18.31a8.86 8.86 0 0 1 2.69 6.41c0 7.39-6.23 12.64-6.23 21.12 0 7.81 5.46 15.77 21.27 15.77 14.09 0 21.52-6.9 21.52-14.24-.01-6.3-4.52-11.63-13.25-12.46Z"
          transform="translate(-56.72 -38.48)"
          style={{
            fill: "url(#a)",
          }}
        />
        <path
          d="m148.17 85.53-18.73-24.15v21.77l3.82.91v1h-8.71v-1l3.12-.91V59.43a6.66 6.66 0 0 0-2.67-1.52c-.38-.08-.89-.17-.89-.17v-1h7.39l16 20.8V58.67l-3.82-.92v-1h8.71v1l-3.12.92v21.59c0 2 .14 4.91.17 5.28Zm39.16-15.38c0 10-7 15.8-14.65 15.8S158 80.07 158 70.93s7-15.08 14.61-15.08a14.25 14.25 0 0 1 14.72 14.31Zm-4.87 1.5c0-7.35-4.4-14.48-10.38-14.48-6.36 0-9.23 6.15-9.23 12.73s3.63 14.74 10.46 14.74c6.42 0 9.15-6.5 9.15-12.99Zm10.92-13-3-.92v-1h11.49v1l-3.6.92 7.93 21.62 7.93-21.62-3.6-.92v-1h8.5v1l-3 .92-9.93 26.95h-2.54Zm67.9 11.95h-1.82V58.22c6 0 9.57 1.38 9.57 6.28-.03 3.64-3.15 6.1-7.75 6.1Zm51.41 13.46 4-.91V58.66l-4-.92v-1h12.47v1l-4 .92v24.49l4 .91v1h-12.47Zm20.11-6.78h.79l2.65 6.1a11.87 11.87 0 0 0 5.07 1.26c3.44 0 6.58-2 6.58-5.58 0-2.72-1.76-4.34-4.61-5.83l-2.82-1.46c-3.37-1.75-6.74-4.23-6.74-8.22 0-4.34 3-7.69 9.42-7.69a22.14 22.14 0 0 1 6.64 1.09l.67 6.56h-.85l-2.45-5.26a8.51 8.51 0 0 0-4.15-1.08c-3.25 0-5.46 2-5.46 4.77 0 2.51 1.67 4.09 4.53 5.57l3.34 1.74c4.56 2.36 6.72 4.55 6.72 8.6 0 5-4.69 8.11-10.52 8.11a25.1 25.1 0 0 1-8.09-1.21Zm-115.6 6.78 3.21-.91 9.91-26.43 3.31-.85 10.23 27.27 3.21.92v1h-12.14v-1l4.11-.92-2.9-7.84h-10.9l-2.88 7.84 4.11.91v1h-9.27Zm8.8-10.85h9.43l-4.68-12.64Zm41-2c1-.25 6.61-1.68 6.61-7 0-4.27-3.27-7.58-11.33-7.58-5 0-10 .11-10.63.13v1l3.29.92v24.46l-3.29.92v1h11.64v-1l-3.82-.92V72.08h3.29l8.41 13h7.45v-1l-3.77-1Zm24.58-13v24.94l-4 .91v1h12.47v-1l-4-.91V58.22h6.73L305 63h.86l-.64-6.23h-22.81l-.64 6.23h.86l2.2-4.73Zm-213.39 18 4-37.79h-8.3l4 37.79Z"
          transform="translate(-56.72 -38.48)"
          style={{
            fill: "#0460a9",
          }}
        />
      </g>
    </svg>
  );
};

export default NovartisLogo;
