import React from "react";
import { Switch, BrowserRouter as Router, Route } from "react-router-dom";
import { Notifications, Logout } from "lib-core";
import appViews from "./views";
import View from "./View";
import AppHeader from "../Header";

const SecureRouter: () => JSX.Element = () => (
  <Router>
    <AppHeader headerText="Authentifield Web Portal" />
    <Notifications />
    <Switch>
      {appViews.map((route) => (
        <Route
          key={route.home.name}
          path={route.home.path}
          render={({ match: { url } }) => <View path={url} route={route} />}
        />
      ))}
      <Route path="/logout" component={Logout} />
    </Switch>
  </Router>
);

export default SecureRouter;
