import { Capacitor } from "@capacitor/core";
import { Typography } from "@mui/material";
import React, { useState } from "react";
import LogService from "../../services/LogService";

import BluetoothCheckMobile from "./BluetoothCheckMobile";

interface BluetoothCheckProps {
  children?: React.ReactNode;
  className?: string;
}

const BluetoothCheck: (props: BluetoothCheckProps) => JSX.Element = ({
  children,
  className = "",
}) => {
  const [bluetoothReady, setBluetoothReady] = useState(false);

  if (Capacitor.getPlatform() === "web") {
    return (
      <Typography className={`BluetoothCheck-error ${className}`}>
        Unsupported platform web
      </Typography>
    );
  }

  if (!bluetoothReady) {
    return (
      <BluetoothCheckMobile
        onBluetoothOk={() => {
          LogService.log("Bluetooth is ready!!");
          setBluetoothReady(true);
        }}
        className={className}
      />
    );
  }

  return <>{children}</>;
};

export default BluetoothCheck;
