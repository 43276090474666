import React from "react";
import { TextField, TextFieldProps } from "@mui/material";

interface DateFilterProps {
  placeholder: string;
}

type Props = DateFilterProps & TextFieldProps;

const DateInput: (props: Props) => JSX.Element = ({
  placeholder,
  ...props
}) => {
  const { inputProps, name } = props;

  return (
    <TextField
      {...props}
      inputProps={{
        ...inputProps,
        "data-testid": `filter-dateinput-input-${name}`,
      }}
      label={placeholder}
      data-testid={`filter-dateinput-${name}`}
    />
  );
};

export default DateInput;
