import Protocol from "../../types/contracts/Protocol";
import { mockProtocols } from "../mocks-protocol";
import wait from "../../lib/helper/wait";
import ProtocolService from "../../services/domain/ProtocolService";

const getProtocols = async (): Promise<Protocol[]> => {
  await wait(1000);

  return mockProtocols;
};
getProtocols.query = ProtocolService.getProtocols.query;

export const MockProtocolService = {
  getProtocols,
};
