import React from "react";
import { redirectToLogoutURL } from "./helpers";

const Logout: () => JSX.Element = () => {
  redirectToLogoutURL();

  return <></>;
};

export default Logout;
