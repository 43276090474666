import React, { useState } from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

const DialogFailure: (props: DialogProps) => JSX.Element = ({
  open,
  title,
  description,
  className = "",
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(open);

  const onClose = () => {
    setIsOpen(false);
  };

  return (
    <Dialog
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className={`DialogFailure ${className}`}
      onClose={onClose}
      open={isOpen}
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {description}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          size="medium"
          fullWidth={false}
          onClick={onClose}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogFailure;
