import React from "react";
import styled from "styled-components";

interface DetailsViewTabPanelProps {
  activeTab: number;
  children?: JSX.Element[] | JSX.Element;
  index: number;
}

const Container = styled.div`
  padding-top: 1rem;
  width: 100%;
`;

const DetailsViewTabPanel: (props: DetailsViewTabPanelProps) => JSX.Element = ({
  activeTab,
  children,
  index,
}) => {
  const hideTabPanel = () => index !== activeTab;

  if (hideTabPanel()) {
    return <></>;
  }

  return <Container>{children}</Container>;
};

export default DetailsViewTabPanel;
