import { AxiosResponse } from "axios";
import axios from "../../AxiosInstance";
import View from "../../types/contracts/View";
import { HttpConfig, PagedResult, PagingData } from "../../types/types-api";
import {
  ProductData,
  ProductVariant,
  ProductVariantVersions,
} from "../../types/types-product";
import {
  PlanningResponse,
  ResultsResponse,
  ModelsResponse,
  ModelApprovalData,
  ProductVariantSnapshot,
  BlendedSnapshot,
  BlendedSnapshotUpcoming,
  ModelLogsDownloadRequest,
} from "../../types/types-models";
import Urls from "../Urls";
import ViewService from "./ViewService";
import {
  ProductVariantDetail,
  ProductVariantMeasurementInput,
  ProductVariantMeasurementSessionResponse,
} from "../../types/contracts/Product";
import { KeyValueParams } from "../../components/filter/datafilter-types";
import Constants from "../Constants";

/* ------------------------------- Data mapper ------------------------------ */
const mapViewToProductData = (view: View): ProductData | undefined => {
  if (
    view.metadata &&
    view.id &&
    view.metadata.globalBrand &&
    view.metadata.dosageForm &&
    view.metadata.dosageStrength &&
    view.metadata.measureMode
  ) {
    return {
      viewId: view.id,
      productName: view.metadata.globalBrand,
      localBrands: view.metadata.localBrands ?? [],
      dosageForm: view.metadata.dosageForm,
      dosageStrength: `${
        view.metadata.dosageStrength
      }${view.metadata.dosageUnit?.toLowerCase()}`,
      measureMode: view.metadata.measureMode,
    };
  }
  return undefined;
};
/* --------------------------------- Product -------------------------------- */

const getProduct = async (viewId: number): Promise<ProductData> => {
  const view = await ViewService.getView(viewId);
  const product = mapViewToProductData(view);
  if (product) {
    return product;
  }
  return Promise.reject(new Error("Could not construct product from view"));
};

const getProducts = async (): Promise<ProductData[]> => {
  const views = await ViewService.listViews();
  return views.flatMap((view) => {
    const product = mapViewToProductData(view);
    if (product) {
      return [product];
    }
    return [];
  });
};
getProducts.query = "product/get";

/* ----------------------------- Product variant ---------------------------- */

const getProductVariants = async (
  paging: PagingData,
  metadata?: KeyValueParams
): Promise<PagedResult<ProductVariant>> => {
  const httpConfig: HttpConfig<unknown> = {
    method: "get",
    params: { ...paging, ...metadata },
    url: Urls.extProductVariant(),
  };

  const httpResult = await axios(httpConfig);
  const { page, perPage, total, entities } = httpResult.data;
  return {
    paging: { page, perPage, total },
    entities,
  };
};
getProductVariants.query = "product-variants/get";

const getProductVariant = async (
  variantId: number
): Promise<ProductVariant> => {
  const httpConfig: HttpConfig<unknown> = {
    method: "get",
    url: Urls.extProductVariantDetail(variantId),
  };

  const httpResult = await axios(httpConfig);
  return httpResult.data;
};
getProductVariant.query = "product-variant/get-by-id";

const getProductVariantVersions = async (
  variantId: number
): Promise<ProductVariantVersions> => {
  const httpConfig: HttpConfig<unknown> = {
    method: "get",
    url: Urls.extProductVariantVersions(variantId),
  };

  const httpResult = await axios(httpConfig);
  return httpResult.data;
};
getProductVariant.query = "product-variant/get-versions";

const getProductVariantDetail = async (
  variantId: number
): Promise<ProductVariantDetail> => {
  const httpConfig: HttpConfig<unknown> = {
    method: "get",
    url: Urls.extProductVariantDetailById(variantId),
  };
  const httpResult = await axios(httpConfig);
  return httpResult.data;
};
getProductVariantDetail.query = "product-variant/get-by-id-detail";

const getProductVariantPlanning = async (
  variantId: number
): Promise<PlanningResponse> => {
  const httpConfig: HttpConfig<unknown> = {
    method: "get",
    url: Urls.extProductVariantPlanning(variantId),
  };

  const httpResult = await axios(httpConfig);
  return httpResult.data;
};

const getProductVariantResults = async (
  variantId: number
): Promise<ResultsResponse> => {
  const httpConfig: HttpConfig<unknown> = {
    method: "get",
    url: Urls.extProductVariantResults(variantId),
  };

  const httpResult = await axios(httpConfig);
  return httpResult.data;
};

const getProductVariantSnapshots = async (
  variantId: number
): Promise<ProductVariantSnapshot[]> => {
  const httpConfig: HttpConfig<unknown> = {
    method: "get",
    url: Urls.extProductVariantSnapshots(variantId),
  };

  const httpResult = await axios(httpConfig);
  return httpResult.data;
};

const createProductVariantSnapshot = async (
  productVariantId: number
): Promise<ProductVariantSnapshot> => {
  const httpConfig: HttpConfig<unknown[]> = {
    method: "post",
    url: Urls.extProductVariantSnapshots(productVariantId),
  };

  const httpResult: AxiosResponse<ProductVariantSnapshot> = await axios(
    httpConfig
  );
  return httpResult.data;
};

const sendProductVariantPlanningChangeRequest = async (
  variantId: number,
  payload: unknown
): Promise<PlanningResponse> => {
  const httpConfig: HttpConfig<unknown> = {
    method: "patch",
    url: Urls.extProductVariantDetail(variantId),
    data: payload,
  };

  const httpResult = await axios(httpConfig);
  return httpResult.data;
};

const getProductVariantTrainings = async (
  variantId: number
): Promise<ModelsResponse> => {
  const httpConfig: HttpConfig<unknown> = {
    method: "get",
    url: Urls.extProductVariantTrainings(variantId),
  };

  const httpResult = await axios(httpConfig);
  return httpResult.data;
};

const startProductVariantMeasurementSession = async (
  variantId: number,
  dataSetId: number,
  totalNumberOfIntendedMeasurements: number
): Promise<ProductVariantMeasurementSessionResponse> => {
  const httpConfig: HttpConfig<unknown> = {
    method: "post",
    url: Urls.startMeasurementSession(
      variantId,
      dataSetId,
      totalNumberOfIntendedMeasurements
    ),
  };

  const httpResult = await axios(httpConfig);
  return httpResult.data;
};
startProductVariantMeasurementSession.query =
  "product-variant/start-measurement-session";
const uploadProductVariantMeasurement = async (
  variantId: number,
  dataSetId: number,
  closeOnSave: boolean,
  payload: ProductVariantMeasurementInput
): Promise<ProductVariantMeasurementSessionResponse> => {
  const httpConfig: HttpConfig<unknown> = {
    method: "post",
    url: Urls.saveMeasurement(variantId, dataSetId, closeOnSave),
    data: payload,
  };

  const httpResult = await axios(httpConfig);
  return httpResult.data;
};
uploadProductVariantMeasurement.query = "product-variant/measurements";

/* ---------------------------------- Misc ---------------------------------- */
const sendTrainingRequest = async (
  productVariantSnapshotId: string,
  modelPackage: File,
  selectedPlatform: string,
  noWhiteReference: boolean,
  blendedSnapshotId?: string
): Promise<string> => {
  const formData = new FormData();

  formData.append("file", modelPackage as Blob, modelPackage?.name);

  const httpConfig: HttpConfig<unknown> = {
    method: "post",
    url: Urls.extModelRun(
      productVariantSnapshotId,
      selectedPlatform,
      noWhiteReference,
      blendedSnapshotId
    ),
    data: formData,
  };

  await axios(httpConfig);
  // This request doesn't return anything
  return "Ok";
};

const getExportCSV = async (): Promise<string> => {
  const httpConfig: HttpConfig<unknown> = {
    method: "get",
    url: Urls.exportLibraryCsv(),
  };

  const httpResult: AxiosResponse<string> = await axios(httpConfig);
  return httpResult.data;
};

const getManufacturingMetadataCsv = async (
  metadataUrl: string
): Promise<string> => {
  const httpConfig: HttpConfig<unknown> = {
    method: "get",
    url: Urls.getManufacturingMetadataCsv(metadataUrl),
  };

  const httpResult: AxiosResponse<string> = await axios(httpConfig);
  return httpResult.data;
};

const importLibraryCSV = async (formData: FormData): Promise<unknown> => {
  const httpConfig: HttpConfig<unknown> = {
    method: "post",
    url: Urls.libraryImportCsv(),
    data: formData,
  };

  const httpResult = await axios(httpConfig);
  return httpResult;
};

const cancelModelTrainingRun = async (viewId: number): Promise<string> => {
  const httpConfig: HttpConfig<unknown> = {
    method: "post",
    url: Urls.cancelModelTrainingRun(viewId),
  };

  const httpResult: AxiosResponse<string> = await axios(httpConfig);
  return httpResult.data;
};

const getProductVariantModels = async (
  variantId: number
): Promise<ModelsResponse> => {
  const httpConfig: HttpConfig<unknown> = {
    method: "get",
    url: Urls.productVariantModels(variantId),
  };

  const httpResult = await axios(httpConfig);
  return httpResult.data;
};

const approveCandidateModel = async (
  modelApprovalData: ModelApprovalData
): Promise<string> => {
  const httpConfig: HttpConfig<unknown> = {
    method: "put",
    url: Urls.approveCandidateModel(modelApprovalData),
  };

  const httpResult: AxiosResponse<string> = await axios(httpConfig);
  return httpResult.data;
};

const approveModel = async (
  modelApprovalData: ModelApprovalData
): Promise<string> => {
  const httpConfig: HttpConfig<unknown> = {
    method: "put",
    url: Urls.approveModel(modelApprovalData),
  };

  const httpResult: AxiosResponse<string> = await axios(httpConfig);
  return httpResult.data;
};

const rejectModel = async (
  modelApprovalData: ModelApprovalData
): Promise<string> => {
  const httpConfig: HttpConfig<unknown> = {
    method: "put",
    url: Urls.rejectModel(modelApprovalData),
  };

  const httpResult: AxiosResponse<string> = await axios(httpConfig);
  return httpResult.data;
};

const decommissionModel = async (
  modelApprovalData: ModelApprovalData
): Promise<string> => {
  const httpConfig: HttpConfig<unknown> = {
    method: "put",
    url: Urls.decommissionModel(modelApprovalData),
  };

  const httpResult: AxiosResponse<string> = await axios(httpConfig);
  return httpResult.data;
};

const downloadModelTrainingLogs = async ({
  runId,
  modelName,
}: ModelLogsDownloadRequest): Promise<string> => {
  const httpConfig: HttpConfig<string> = {
    method: "get",
    url: Urls.downloadModelTrainingLogs(modelName, runId),
  };
  return axios(httpConfig).then((res) => res.data);
};

const getModelPlatforms = async (): Promise<string[]> => {
  const httpConfig: HttpConfig<unknown> = {
    method: "get",
    url: Urls.getModelPlatforms(),
  };

  const httpResult = await axios(httpConfig);
  return httpResult.data;
};

const listBlendedSnapshots = async (): Promise<BlendedSnapshot[]> => {
  const protocolName = Constants.defaultProtocol;
  const httpConfig: HttpConfig<BlendedSnapshot[]> = {
    method: "get",
    url: Urls.extProductVariantBlendedSnapshotListing(protocolName),
  };

  try {
    const response = await axios(httpConfig);
    return response.data;
  } catch (error) {
     return []; 
  }
};

const fetchBlendedSnapshotUpcomingTime =
  async (): Promise<BlendedSnapshotUpcoming> => {
    const protocolName = Constants.defaultProtocol;
    const httpConfig: HttpConfig<BlendedSnapshotUpcoming> = {
      method: "get",
      url: Urls.extProductVariantBlendedSnapshotUpcoming(protocolName),
    };
    return axios(httpConfig).then((res) => res.data);
  };

const downloadBlendedSnapshotMetadata = async (id: string): Promise<string> => {
  const httpConfig: HttpConfig<string> = {
    method: "get",
    url: Urls.extBlendedSnapshotMetadataDownload(id),
  };
  return axios(httpConfig).then((res) => res.data);
};

const updateProductVariantSnapshot = async (
  productVariantId: number,
  action: string,
  uuid: string,
  ver: string
): Promise<ProductVariantSnapshot> => {
  const httpConfig: HttpConfig<unknown> = {
    method: "patch",
    data: {
      action,
      snapshotUpdates: {
        [uuid]: {
          status: "released",
        },
      },
      ver,
    },
    url: Urls.extProductVariantSnapshots(productVariantId),
  };

  const httpResult = await axios(httpConfig);
  return httpResult.data;
};

const ProductsService = {
  getProducts,
  getProductVariants,
  getProductVariant,
  getProductVariantVersions,
  getProductVariantDetail,
  getProductVariantPlanning,
  getProductVariantSnapshots,
  listBlendedSnapshots,
  fetchBlendedSnapshotUpcomingTime,
  downloadBlendedSnapshotMetadata,
  sendProductVariantPlanningChangeRequest,
  getProductVariantTrainings,
  getProduct,
  getExportCSV,
  importLibraryCSV,
  getProductVariantResults,
  getManufacturingMetadataCsv,
  createProductVariantSnapshot,
  sendTrainingRequest,
  cancelModelTrainingRun,
  getProductVariantModels,
  startProductVariantMeasurementSession,
  uploadProductVariantMeasurement,
  approveModel,
  approveCandidateModel,
  updateProductVariantSnapshot,
  downloadModelTrainingLogs,
  rejectModel,
  decommissionModel,
  getModelPlatforms,
};

export default ProductsService;
