import {
  CellTypes,
  LocationValue,
  mapAssessmentToColor,
  mapAssessmentToUserString,
  mapMeasureModeToShortUserString,
  mapModelStatusToUserString,
  MeasurementAssessment,
  PredictionValue,
  StoredMeasurementHistoryItem,
  TableCellType,
  TableDataArray,
} from "lib-core";
import format from "date-fns/format";
import { Theme } from "@mui/material";

type ModelStatus = StoredMeasurementHistoryItem["metadata"]["modelStatus"];

const mapModelStatusToColor = (
  modelStatus: ModelStatus,
  theme: Theme
): string => {
  return (
    {
      APPROVED_CANDIDATE: theme.palette.warning.main,
      APPROVED_PRODUCTION: theme.palette.text.primary,
      NOT_APPROVED: theme.palette.text.primary,
      DECOMMISSIONED: theme.palette.text.primary,
    } as Record<ModelStatus, string>
  )[modelStatus];
};

export const historyTableMapper = (
  history: StoredMeasurementHistoryItem[],
  theme: Theme
): TableDataArray<StoredMeasurementHistoryItem> => {
  return history.map((e: StoredMeasurementHistoryItem) => {
    const { municipality, region, country, latitude, longitude } = e.metadata;

    return {
      data: e,
      id: e.id,
      row: [
        {
          key: "product",
          type: CellTypes.STRING,
          value: `${e.metadata.globalBrand}, ${
            e.metadata.dosageStrength
          }${e.metadata.dosageUnit.toLowerCase()}, ${mapMeasureModeToShortUserString(
            e.metadata.measureMode
          )} `,
        } as TableCellType,
        {
          key: "batchNumber",
          type: CellTypes.STRING,
          value: e.metadata.batchNumber,
        } as TableCellType,
        {
          key: "caseNumber",
          type: CellTypes.STRING,
          value: e.metadata.caseNumber,
        } as TableCellType,
        {
          key: "model",
          type: CellTypes.RESULT,
          value: mapModelStatusToUserString(e.metadata.modelStatus),
          selectColor: () => {
            return mapModelStatusToColor(e.metadata.modelStatus, theme);
          },
        } as TableCellType,
        {
          key: "authResult",
          type: CellTypes.RESULT,
          value: e.metadata?.authResult || PredictionValue.Unknown,
        } as TableCellType,
        {
          key: "assessment",
          type: CellTypes.RESULT,
          value: (() => {
            if (e.metadata.modelStatus !== "APPROVED_PRODUCTION") {
              return "";
            }
            const isDisplayInShortForm = true;
            return mapAssessmentToUserString(
              e.metadata.assessment ?? MeasurementAssessment.PENDING,
              isDisplayInShortForm
            );
          })(),
          selectColor: () => {
            return mapAssessmentToColor(
              e.metadata.assessment ?? MeasurementAssessment.PENDING,
              theme
            );
          },
        } as TableCellType,
        {
          key: "measurer",
          type: CellTypes.STRING,
          value: e.measurerPreferredUsername,
        } as TableCellType,
        {
          key: "serialNumber",
          type: CellTypes.STRING,
          value: e.deviceSerialNumber,
        } as TableCellType,
        {
          key: "location",
          type: CellTypes.LOCATION,
          value: {
            municipality,
            region,
            country,
            north: latitude.toString(),
            west: longitude.toString(),
          } as unknown as LocationValue,
        } as TableCellType,
        {
          key: "createdOn",
          type: CellTypes.STRING,
          value: format(new Date(e.createdOn), "yyyy-MM-dd HH:mm"),
        } as TableCellType,
      ],
    };
  });
};
