import React from "react";
import { Chip as MuiChip, List, ListItem } from "@mui/material";
import styled from "styled-components";

interface IndexedListProps {
  values: string[];
}

const IndexLetter = styled.span`
  width: 3rem;
  text-align: center;
  font-size: 24px;
  font-weight: 700;
  color: ${({ theme }) => theme.palette.primary.main};
  margin-right: 12px;
`;

const Chip: typeof MuiChip = styled(MuiChip)`
  margin-right: 12px;
`;

const NoProducts = styled.span`
  margin-left: 28px;
`;

const IndexedList: (props: IndexedListProps) => JSX.Element = ({ values }) => {
  const indexedValues: Record<string, string[]> = {};
  values
    .sort((a, b) => {
      return a.toLowerCase() > b.toLowerCase() ? 1 : -1;
    })
    .forEach((value: string) => {
      const currentLetter = value[0]?.toUpperCase() ?? "";
      if (!currentLetter) return;
      if (!indexedValues[currentLetter]) {
        indexedValues[currentLetter] = [];
      }
      // Create unique list
      if (!indexedValues[currentLetter]?.includes(value)) {
        indexedValues[currentLetter]?.push(value);
      }
    });

  return (
    <List>
      {values.length === 0 && <NoProducts>No products found.</NoProducts>}
      {Object.entries(indexedValues).map(([key, _values]) => (
        <ListItem key={key}>
          <IndexLetter>{`${key}\t`}</IndexLetter>
          {_values.sort().map((value: string) => (
            <Chip label={value} key={value} />
          ))}
        </ListItem>
      ))}
    </List>
  );
};

export default IndexedList;
