import React from "react";
import styled from "styled-components";
import { TableCell } from "@mui/material";

import { LocationValue } from "./datatable-types";

interface TableCellLocationProps {
  value: LocationValue;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Location = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TableCellLocation: (props: TableCellLocationProps) => JSX.Element =
  ({ value }) => {
    const regionalDataIsAvailable = (value: LocationValue) => {
      let { municipality, region, country } = value;
      if (!municipality) municipality = "";
      if (!region) region = "";
      if (!country) country = "";

      return `${municipality} ${region} ${country}`;
    };

    const regionalDataIsNotAvailable = (value: LocationValue) => {
      const { north, west } = value;

      if (!north || !west) {
        return "";
      }

      return `N ${north.substring(0, 8)} W ${west.substring(0, 8)}`;
    };

    const humanReadableLocationString = value.municipality
      ? regionalDataIsAvailable(value)
      : regionalDataIsNotAvailable(value);

    return (
      <TableCell>
        <Container>
          <Location>{humanReadableLocationString}</Location>
        </Container>
      </TableCell>
    );
  };

export default TableCellLocation;
