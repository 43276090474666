import styled from "styled-components";

const defaultPadding = "18px 30px 50px";
const sidebarPadding = "18px 0 50px 30px";

const PageContent = styled.div`
  flex: 1 0 0;
  padding: ${defaultPadding};
  min-width: 0;
`;

const PageSidebar = styled.div`
  width: 320px;
  padding: ${sidebarPadding};
`;

const PageSidebarContained = styled.div`
  width: 350px;
  padding: ${defaultPadding};
  background: ${({ theme }) => theme.palette.brandGray.light};
`;

export { PageContent, PageSidebar, PageSidebarContained };
