import { Button, CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import getDeviceInfo from "../../services/bluetooth/DeviceInfoService";
import LogService from "../../services/LogService";
import { DeviceInfo } from "../../types/types-bluetooth";
import { useGlobalState } from "../globalState/GlobalState";

const InfoContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  text-align: left;
  justify-content: left;
  margin: 0px 15px;
`;

const LogContainer = styled.div`
  overflow: scroll;
  height: 500px;
  background-color: #f0f0ff;
  margin: 15px 0px;
  padding: 0px 5px;
  text-align: left;
  line-height: 1;
`;

const SimpleButton = styled(Button)`
  margin: 10px 0px;
`;

const Wrapper = styled.div`
  margin-bottom: 65px;
  overflow: hidden;
  width: 100vw;
`;

const LogEntry = styled.div`
  margin-bottom: 5px;
`;

const DebugScreen: () => JSX.Element = () => {
  const [state] = useGlobalState();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [info, setInfo] = useState<DeviceInfo | undefined>(undefined);

  useEffect(() => {
    const loadDeviceInfo = async () => {
      if (!state?.device?.address) {
        return;
      }
      try {
        setIsLoading(true);
        setInfo(await getDeviceInfo(state.device.address));
      } catch (e) {
        LogService.warn("Could not load  deviceInfo for DebugView");
      } finally {
        setIsLoading(false);
      }
    };
    if (!info) {
      loadDeviceInfo();
    }
  }, [state.device, info]);

  return (
    <Wrapper>
      <h2>Debug View</h2>
      {isLoading && (
        <>
          <CircularProgress size={20} /> <span>loading deviceInfo</span>
        </>
      )}
      {info !== undefined ? (
        <InfoContainer>
          <div>Serial </div>
          <div>{info.serialNumber}</div>
          <div>SWVersion</div>
          <div>{info.softwareVersion}</div>
          <div>nMfirmwareVersion</div>
          <div>{info.nmFirmwareVersion}</div>
          <div>ubloxFw</div>
          <div>{info.ubloxFirmwareVersion}</div>
          <div>batteryLevel</div>
          <div>{info.batteryLevel}</div>
          <div>inactivityDetectionTimer</div>
          <div>{info.inactivityDetectionTimer}</div>
          <div>minWl</div>
          <div>{info.minWavelength}</div>
          <div>maxWl</div>
          <div>{info.maxWavelength}</div>
        </InfoContainer>
      ) : (
        <p>No device info available</p>
      )}
      <SimpleButton
        onClick={() => localStorage.removeItem("selectedDevice:persisted")}
      >
        Remove device
      </SimpleButton>
      <SimpleButton
        onClick={() =>
          LogService.logCollection.splice(0, LogService.logCollection.length)
        }
      >
        Clear logs
      </SimpleButton>

      <LogContainer>
        {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
        {LogService.logCollection.map((message: any) => {
          return (
            <LogEntry>
              {">"} {message}
            </LogEntry>
          );
        })}
      </LogContainer>
    </Wrapper>
  );
};

export default DebugScreen;
