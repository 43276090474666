import React from "react";
import { Typography, styled } from "@mui/material";
import { ProductData } from "../types/types-product";
import { mapMeasureModeToShortUserString } from "../lib/helper/measure-mode";
import ViewMetadata from "../types/contracts/ViewMetadata";

type PartialBy<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>;

type SelectedProductProps = {
  metadata:
    | PartialBy<ProductData, "viewId" | "measureMode">
    | PartialBy<ViewMetadata, "measureMode" | "dosageUnit" | "localBrands">
    | undefined;
  className?: string;
};

const Container = styled("div")``;
const GlobalBrand = styled(Typography)``;
const LocalBrands = styled(Typography)``;

const SelectedProduct: (props: SelectedProductProps) => JSX.Element = ({
  metadata,
  className = "",
}) => {
  if (!metadata) {
    return <></>;
  }

  const { dosageForm, dosageStrength, localBrands } = metadata;
  const dosageUnit = "dosageUnit" in metadata ? metadata.dosageUnit : null;
  const globalBrand = "globalBrand" in metadata ? metadata.globalBrand : null;
  const productName = "productName" in metadata ? metadata.productName : null;
  const dosage = `${dosageForm}, ${dosageStrength}${(
    dosageUnit || ""
  ).toLowerCase()}`;

  const measureMode = metadata.measureMode
    ? mapMeasureModeToShortUserString(metadata.measureMode)
    : null;

  const localBrandsList = localBrands?.length ? localBrands.join(", ") : null;

  return (
    <Container className={`SelectedProduct ${className}`}>
      <GlobalBrand className="SelectedProduct-global-brand">
        <strong>{globalBrand || productName}</strong>
        {`, ${dosage}`}
        {measureMode && `, ${measureMode}`}
      </GlobalBrand>
      <LocalBrands variant="body2" className="SelectedProduct-local-brands">
        {localBrandsList && `(AKA ${localBrandsList})`}
      </LocalBrands>
    </Container>
  );
};

export default SelectedProduct;
