import React, { useCallback, useState } from "react";
import { Switch, BrowserRouter as Router, Route } from "react-router-dom";
import {
  Authorize,
  Constants,
  Interceptors,
  SecureRoute,
  redirectToLogoutURL,
} from "lib-core";
import SecureRouter from "./SecureRouter";
import LoggedOut from "../LoggedOut";
import Login from "../Login";
import RedirectMobile from "../RedirectMobile";
import { TokenRefreshContext } from "./TokenRefreshContext";

const AUTHORIZATION_PATH = process.env.REACT_APP_AUTHORIZATION_PATH;

const RootRouter: () => JSX.Element = () => {
  const [isAccessDenied, setIsAccessDenied] = useState(false);
  const [isConcurrentLimitReached, setIsConcurrentLimitReached] =
    useState(false);
  const [tokenRefresh, setTokenRefresh] = useState(false);

  const onAccessDeniedError = useCallback(() => {
    setIsAccessDenied(true);
  }, []);

  const onConcurrentLimitReached = useCallback(() => {
    setIsConcurrentLimitReached(true);
  }, []);

  const returnToLogin = useCallback(async () => {
    setIsAccessDenied(false);
    setIsConcurrentLimitReached(false);

    localStorage.clear();
    redirectToLogoutURL();
  }, []);

  const redirectToLogout = async () => {
    redirectToLogoutURL();
  };

  const accessToken: () => string = () =>
    localStorage.getItem(Constants.accessToken) || "";

  const onTokenRefreshSuccessful = () => {
    setTokenRefresh(true);
  };

  const startLoginProcess = () => {
    window.location.href = "/login";
  };

  return (
    <TokenRefreshContext.Provider value={{ tokenRefresh, setTokenRefresh }}>
      <Router>
        <Interceptors
          getAccessToken={accessToken}
          startLoginProcess={startLoginProcess}
          onTokenRefreshSuccessful={onTokenRefreshSuccessful}
          onAccessDeniedError={onAccessDeniedError}
          onConcurrentLimitReached={onConcurrentLimitReached}
        />
        <Switch>
          <Route path={AUTHORIZATION_PATH}>
            <Authorize homePath="/home" redirectToLogout={redirectToLogout} />
          </Route>

          <Route exact path="/" component={Login} />
          <Route path="/redirect/mobile" component={RedirectMobile} />
          <Route path="/logout/success" component={LoggedOut} />
          <SecureRoute
            path="*"
            redirectToLogout={returnToLogin}
            component={SecureRouter}
            isAccessDenied={isAccessDenied}
            isConcurrentLimitReached={isConcurrentLimitReached}
            notLoggedInScreen={<Login />}
          />
        </Switch>
      </Router>
    </TokenRefreshContext.Provider>
  );
};

export default RootRouter;
