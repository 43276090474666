import React from "react";
import styled from "styled-components";
import { TableCell as MuiTableCell } from "@mui/material";
import PredictionValue from "../../types/contracts/PredictionValue";

const defaultSelectStateColor = (state: string, theme: AppTheme) => {
  switch (state) {
    case PredictionValue.Fail:
    case PredictionValue.ConfirmedFail:
      return theme.palette.error.main;
    case PredictionValue.Invalid:
      return theme.palette.brandGray.dark;
    case PredictionValue.Unknown:
      return theme.palette.warning.main;
    default:
      return theme.palette.success.main;
  }
};
interface TableCellResultProps {
  value: string;
  selectColor?: typeof defaultSelectStateColor;
}

interface AppTheme {
  palette: {
    error: { main: string };
    success: { main: string };
    warning: { main: string };
    brandGray: { dark: string };
  };
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const TableCell = styled(MuiTableCell)<
  TableCellResultProps & { $selectColor: typeof defaultSelectStateColor }
>`
  color: ${({ value, theme, $selectColor }) =>
    `${$selectColor(value, theme)} !important`};
`;

export const TableCellResult: (props: TableCellResultProps) => JSX.Element = ({
  value,
  selectColor = defaultSelectStateColor,
}) => (
  <TableCell value={value} $selectColor={selectColor}>
    <Container>{value}</Container>
  </TableCell>
);

export default TableCellResult;
