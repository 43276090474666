import React from "react";
import styled from "styled-components";
import { default as MuiTextField } from "@mui/material/TextField";
import {
  MeasurementAssessment,
  PermissionKeys,
  mapAssessmentToColor,
  usePermissionChecking,
} from "lib-core";
import { Typography } from "@mui/material";

interface DetailsViewAssessmentNoteProps {
  updateNote: (message: string) => void;
  header: string;
  assessment: MeasurementAssessment;
}

const TextField = styled(MuiTextField)`
  width: 100%;
`;

const Headline = styled(Typography)<{ assessment: MeasurementAssessment }>`
  margin-bottom: 14px;
  color: ${({ assessment, theme }) => mapAssessmentToColor(assessment, theme)};
`;

const AssessmentTextField = styled(TextField)<{
  assessment: MeasurementAssessment;
}>`
  & label.Mui-focused {
    color: ${({ assessment, theme }) =>
      mapAssessmentToColor(assessment, theme)};
  }
  & .MuiOutlinedInput-root {
    & fieldset {
      border-color: ${({ assessment, theme }) =>
        mapAssessmentToColor(assessment, theme)};
    }
    &.Mui-focused fieldset {
      border-color: ${({ assessment, theme }) =>
        mapAssessmentToColor(assessment, theme)};
    }
  }
`;

const { writeMeasurementNote } = PermissionKeys;

const DetailsViewAssessmentNote: (
  props: DetailsViewAssessmentNoteProps
) => JSX.Element = ({ updateNote, header, assessment }) => {
  const requirePermission = usePermissionChecking();

  const disableItNote = () => {
    return !requirePermission([writeMeasurementNote]);
  };

  const updateMessage = (ev: React.ChangeEvent<HTMLInputElement>) => {
    updateNote(ev.target.value);
  };

  return (
    <>
      <Headline variant="h3" assessment={assessment}>
        {header}
      </Headline>
      <AssessmentTextField
        assessment={assessment}
        disabled={disableItNote()}
        id="outlined-multiline-static"
        multiline
        onChange={updateMessage}
        rows={7}
        placeholder="Enter a description for your assessment"
      />
    </>
  );
};

export default DetailsViewAssessmentNote;
