export class TimeoutError implements Error {
  name: string;

  message: string;

  stack?: string | undefined;

  code: string | undefined;

  constructor(error: Error, code?: string) {
    this.name = error.name;
    this.message = error.message;
    this.stack = error.stack;
    this.code = code;
  }
}

/**
 * Rejects after a certain time
 * @param ms timeout time
 */
const timeout = (ms: number): Promise<Error> =>
  new Promise((reject) =>
    setTimeout(() => {
      reject(new TimeoutError(new Error(`timeout of ${ms}`)));
    }, ms)
  );

export default timeout;
