import React from "react";
import { TextField } from "@mui/material";
import { Filter, InputChange } from "./datafilter-types";

interface DateFilterProps {
  filter: Filter;
  onChangeString: (name: string, ev: InputChange) => void;
}

const DateFilter: (props: DateFilterProps) => JSX.Element = ({
  filter,
  onChangeString,
}) => {
  const { name, placeholder, value } = filter;
  return (
    <TextField
      aria-label={placeholder}
      inputProps={{ "data-testid": `${name}-input` }}
      onChange={(ev) => onChangeString(name, ev)}
      label={placeholder}
      value={value}
    />
  );
};

export default DateFilter;
