import axios from "../../AxiosInstance";
import Protocol from "../../types/contracts/Protocol";
import { HttpConfig } from "../../types/types-api";
import Urls from "../Urls";

const getProtocols = async (): Promise<Protocol[]> => {
  const httpConfig: HttpConfig<unknown> = {
    method: "get",
    url: Urls.deviceExtProtocols(),
  };

  const httpResult = await axios(httpConfig);
  return httpResult.data;
};
getProtocols.query = "protocols/get";

const ProtocolService = {
  getProtocols,
};

export default ProtocolService;
