import {
  notifDismissEventName,
  notifEventName,
  NotificationOptions,
  NotificationValues,
} from "./notification-types";

const createNotification = (detail: NotificationOptions) => {
  window.dispatchEvent(new CustomEvent(notifEventName, { detail }));
};

const dismissPersistentNotification = (key: string) => {
  window.dispatchEvent(
    new CustomEvent(notifDismissEventName, { detail: { key } })
  );
};

const notificationMethods = {
  createNotification,
  dismissPersistentNotification,
};

const useNotification = (): NotificationValues => {
  return notificationMethods;
};

export default useNotification;
