import React, { useState } from "react";
import {
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel as MuiFormControlLabel,
  FormGroup as MuiFormGroup,
  FormLabel,
  Typography,
  TextField,
  Stack,
  CircularProgress,
} from "@mui/material";
import WarningAmberOutlinedIcon from "@mui/icons-material/WarningAmberOutlined";
import DoneIcon from "@mui/icons-material/Done";
import {
  AuditTrailDatabaseService,
  AuditTrailFileSizeUnit,
  AuditTrailService,
  useError,
  useHttpErrorReader,
} from "lib-core";
import { AxiosError } from "axios";
import styled from "styled-components";

const HelpText = styled(Typography)`
  margin-bottom: 1.25rem;
`;

const FormGroup = styled(MuiFormGroup)`
  flex-direction: row;
  margin-top: 0.2rem;
`;

const FormControlLabel = styled(MuiFormControlLabel)`
  width: 30%;
  height: 2rem;
`;

const AuditForm = styled.form`
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: 1fr 1fr;

  > div,
  > fieldset {
    grid-column: 1 / -1;
  }

  > div:has(#timeMin) {
    grid-column: 1;
  }

  > div:has(#timeMax) {
    grid-column: 2;
  }

  legend {
    font-weight: bold;
    color: ${({ theme }) => theme.palette.text.primary};
  }
`;

const servicesOptions = [
  { label: "Access Control", name: AuditTrailDatabaseService.AccessControl },
  { label: "Device", name: AuditTrailDatabaseService.Device },
  { label: "Experiment", name: AuditTrailDatabaseService.Experiment },
  { label: "History", name: AuditTrailDatabaseService.History },
  { label: "Product", name: AuditTrailDatabaseService.Product },
];

const initialFormValues = {
  username: "",
  timeMin: "",
  timeMax: "",
};

enum Status {
  Done,
  Progressing,
  Initial,
  Error,
}

const SIZE_LIMITATION = 512;

interface Props {
  open: boolean;
  onClose: () => void;
}

interface FormErrors {
  services?: string;
  username?: string;
  timeMin?: string;
  timeMax?: string;
}

interface ValidatedFormField {
  username: string;
  timeMin: string;
  timeMax: string;
}

const Container = styled(Stack).attrs({
  sx: {
    mt: 4,
    p: 2,
  },
  spacing: 2,
})(({ theme }) => ({
  background: theme.palette.brandGray.light,
}));

const CheckSizeContainer = styled(Stack).attrs({
  direction: "row",
  spacing: 3,
})({});

const SuccessContainer = styled(Stack).attrs({
  alignItems: "center",
  direction: "row",
})(({ theme }) => ({
  color: theme.palette.success.main,
}));

const LoadingMessage = styled.span`
  margin-left: 0.625em;
`;

const ExportTooLargeMessage = styled(Typography).attrs({
  variant: "body2",
})({});

const FilterDownMessage = styled(Typography).attrs({
  variant: "caption",
})({});

const ErrorContainer = styled(Stack).attrs({
  direction: "row",
  alignItems: "center",
  justifyContent: "center",
})(({ theme }) => ({
  color: theme.palette.error.main,
}));

const DialogFooter = styled(Stack).attrs({
  flexDirection: "row",
  mt: 4,
})({});

const DownloadingContainer = styled(Stack).attrs({
  flexDirection: "row",
  alignItems: "center",
})(({ theme }) => ({
  color: theme.palette.primary.main,
}));

const getSizeInMb = (value: number, unit: AuditTrailFileSizeUnit): number => {
  switch (unit) {
    case AuditTrailFileSizeUnit.KB:
      return value / 1000;
    case AuditTrailFileSizeUnit.MB:
      return value;
    case AuditTrailFileSizeUnit.GB:
      return value * 1000;
    case AuditTrailFileSizeUnit.TB:
      return value * 1000000;
    default:
      return -1;
  }
};

const convertDate = (date: string) => new Date(date).toISOString();

const CreateReportModal: (props: Props) => JSX.Element = ({
  open,
  onClose,
}) => {
  const { handleErrors } = useError();
  const errorReader = useHttpErrorReader();

  // Form local states
  const [formValues, setFormValues] = useState(initialFormValues);
  const [services, setServices] = useState<AuditTrailDatabaseService[]>([
    AuditTrailDatabaseService.AccessControl,
    AuditTrailDatabaseService.Device,
    AuditTrailDatabaseService.Experiment,
  ]);
  const [errors, setErrors] = useState<FormErrors>({});

  // Check file size states
  const [checkStatus, setCheckStatus] = useState(Status.Initial);
  const [size, setSize] = useState(0);
  const isChecking = checkStatus === Status.Progressing;
  const isChecked = checkStatus === Status.Done;
  const isCheckedError = checkStatus === Status.Error;

  // Download files
  const [downloadStatus, setDownloadStatus] = useState(Status.Initial);
  const isDownloadingReport = downloadStatus === Status.Progressing;

  // Form actions
  const validate = () => {
    const errors: FormErrors = {};
    const { timeMin, timeMax } = formValues;

    if (services.length === 0) {
      errors.services = "At least one service must be selected";
    }

    if (!timeMin) {
      errors.timeMin = "Start time is required";
    }

    if (!timeMax) {
      errors.timeMax = "End time is required";
    }

    if (timeMin && timeMax) {
      const start = new Date(timeMin);
      const end = new Date(timeMax);
      const today = new Date();

      if (start >= end) {
        errors.timeMin = "Start time must be before end time";
      }

      if (start >= today) {
        errors.timeMin = "Start time can not be in the future";
      }

      if (end >= today) {
        errors.timeMax = "End time can not be in the future";
      }
    }

    return errors;
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormValues({
      ...formValues,
      [event.target.name]: event.target.value,
    });
  };

  const onServiceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setServices([
        ...services,
        event.target.name as AuditTrailDatabaseService,
      ]);
    } else {
      const filteredServices = services.filter(
        (name) => name !== event.target.name
      );

      setServices(filteredServices);
    }
  };

  // Modal actions
  const handleClose = () => {
    setErrors({});
    onClose();
  };

  // File download actions
  const startDownload = async (validatedFormFields: ValidatedFormField) => {
    setDownloadStatus(Status.Progressing);

    try {
      const response = await AuditTrailService.exportToCsv({
        ...validatedFormFields,
        services,
      });

      const url = window.URL.createObjectURL(response);
      const link = document.createElement("a");
      link.href = url;
      const fileName = `${+new Date()}.zip`; //
      link.setAttribute("download", fileName);
      link.click();
      link.remove();

      setDownloadStatus(Status.Done);
    } catch (err) {
      const error = err as AxiosError;

      if (error.response) {
        handleErrors({
          error,
          customHandling: errorReader(error.response),
        });
      }
      setDownloadStatus(Status.Error);
    }
  };

  // Size estimation actions
  const checkSize = async (validatedFormFields: ValidatedFormField) => {
    setCheckStatus(Status.Progressing);

    try {
      const timeMin = convertDate(validatedFormFields.timeMin);
      const timeMax = convertDate(validatedFormFields.timeMax);
      const payload = {
        timeMin,
        timeMax,
        services,
        username: validatedFormFields.username,
      };

      const response = await AuditTrailService.exportToCsvPrediction(payload);

      if (response.diskSpace.value) {
        const size = getSizeInMb(
          response.diskSpace.value,
          response.diskSpace.unit
        );
        if (size > 0) {
          setSize(size);

          if (size < SIZE_LIMITATION) {
            startDownload(payload);
          }
        }
      }
      setCheckStatus(Status.Done);
    } catch (err) {
      const error = err as AxiosError;

      if (error.response) {
        handleErrors({
          error,
          customHandling: errorReader(error.response),
        });
      }
      setCheckStatus(Status.Error);
    }
  };

  const onSubmit = () => {
    const errorValues = validate();
    if (Object.values(errorValues).length) {
      setErrors(errorValues);
    } else {
      setErrors({});
      checkSize(formValues);
    }
  };

  return (
    <Dialog open={open}>
      <DialogTitle>Export audit trail data</DialogTitle>
      <DialogContent>
        <HelpText>
          Use filters below to find activiy in the audit trail and export it for
          inspection. The export is a Zip archive containing CSV data and its
          SHA2 hash
        </HelpText>

        <AuditForm noValidate>
          <FormControl
            required
            error={services.length === 0}
            component="fieldset"
            variant="standard"
            id="service"
            disabled={isChecking}
          >
            <FormLabel component="legend">Service</FormLabel>
            <FormGroup>
              {servicesOptions.map((service) => (
                <FormControlLabel
                  key={service.name}
                  control={
                    <Checkbox
                      checked={services.includes(service.name)}
                      onChange={onServiceChange}
                      name={service.name}
                    />
                  }
                  label={service.label}
                />
              ))}
            </FormGroup>
          </FormControl>

          <TextField
            id="username"
            name="username"
            label="Username"
            disabled={isChecking}
            onChange={handleChange}
            error={!!errors.username}
            helperText={errors.username}
            value={formValues.username}
          />
          <TextField
            InputLabelProps={{ shrink: true }}
            id="timeMin"
            label="Start time"
            name="timeMin"
            type="datetime-local"
            disabled={isChecking}
            error={!!errors.timeMin}
            onChange={handleChange}
            helperText={errors.timeMin}
            value={formValues.timeMin}
            required
          />

          <TextField
            required
            InputLabelProps={{ shrink: true }}
            disabled={isChecking}
            id="timeMax"
            name="timeMax"
            label="End time"
            type="datetime-local"
            error={!!errors.timeMax}
            onChange={handleChange}
            helperText={errors.timeMax}
            value={formValues.timeMax}
          />
        </AuditForm>
        <Container>
          <Typography>
            Due to technical limitations, the export size can not exceed
            {` ${SIZE_LIMITATION} MB`}. Before you can download the archive, you
            have to check whether your current filter settings produce too much
            data.
          </Typography>
          <CheckSizeContainer>
            <Button
              disabled={isChecking}
              variant="contained"
              onClick={onSubmit}
            >
              Check size
            </Button>
            {isChecking && (
              <>
                <CircularProgress size={12} color="inherit" />
                <LoadingMessage>
                  Estimate export size, please wait...
                </LoadingMessage>
              </>
            )}
            {isChecked && (
              <>
                {size < SIZE_LIMITATION ? (
                  <SuccessContainer>
                    <DoneIcon sx={{ mr: 1 }} />
                    <Typography>Export size is within limit!</Typography>
                  </SuccessContainer>
                ) : (
                  <ErrorContainer>
                    <WarningAmberOutlinedIcon sx={{ mr: 1 }} />
                    <Stack>
                      <ExportTooLargeMessage>
                        Export is too large at {size} MB!
                      </ExportTooLargeMessage>
                      <FilterDownMessage>
                        Please filter down results
                      </FilterDownMessage>
                    </Stack>
                  </ErrorContainer>
                )}
              </>
            )}
            {isCheckedError && (
              <ErrorContainer>
                <WarningAmberOutlinedIcon sx={{ mr: 1 }} />
                <Stack>
                  <ExportTooLargeMessage>
                    Export size estimation is offline!
                  </ExportTooLargeMessage>
                  <FilterDownMessage>Unable to export data.</FilterDownMessage>
                </Stack>
              </ErrorContainer>
            )}
          </CheckSizeContainer>
        </Container>

        <DialogFooter
          sx={{
            justifyContent: isDownloadingReport ? "space-between" : "flex-end",
          }}
        >
          {downloadStatus === Status.Progressing && (
            <DownloadingContainer>
              <CircularProgress size={12} color="inherit" />
              <LoadingMessage>
                Estimate export size, please wait...
              </LoadingMessage>
            </DownloadingContainer>
          )}
          <Button variant="outlined" onClick={handleClose}>
            Cancel
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default CreateReportModal;
